import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule as QuillEditorModule } from 'primeng/editor';

import { EditorComponent } from './editor.component';

@NgModule({
  declarations: [EditorComponent],
  imports: [CommonModule, QuillEditorModule],
  exports: [EditorComponent],
})
export class EditorModule {}
