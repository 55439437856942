import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Activity } from '~/activity/models';

@Component({
  selector: 'app-activity-data-dialog-header',
  template: ` <div class="p-dialog-title">
    @if (activity) {
      {{ 'activity.header.editActivity' | translate }}
      <div class="text-xs text-500 mt-1">{{ activity.id }}</div>
    } @else {
      {{ 'activity.header.addActivity' | translate }}
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDataDialogHeaderComponent {
  protected activity?: Activity;

  constructor(config: DynamicDialogConfig) {
    this.activity = config.data?.activity;
  }
}
