import { NgModule } from '@angular/core';
import { RowDeleteButtonComponent } from './row-delete-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [RowDeleteButtonComponent],
  imports: [SharedExtModule],
  exports: [RowDeleteButtonComponent],
})
export class RowDeleteButtonModule {}
