import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  template: `
    <span
      class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0"
    >
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        [placeholder]="'widgets.table.search' | translate"
        class="w-full"
        (input)="emitFilter($event)"
      />
    </span>
  `,
})
export class TableFilterComponent {
  @Output() filter = new EventEmitter<string>();

  public emitFilter(event: Event) {
    const search = (event.target as HTMLInputElement).value;
    this.filter.emit(search);
  }
}
