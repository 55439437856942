import { ComponentStore } from '@ngrx/component-store';
import {
  exhaustMap,
  filter,
  Observable,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IContactAddress } from '../../models';
import { ContactAddressHttpService } from '../../services/http';
import { AddressDialogService } from '~/contact/modules/address/services/dialogs';
import { tapResponse } from '@ngrx/operators';

interface IAddressesListState {
  loading: boolean;
  items: IContactAddress[];
  contactId?: string;
}

const initialState = (): IAddressesListState => ({
  loading: false,
  items: [],
});

@Injectable()
export class AddressesListStore extends ComponentStore<IAddressesListState> {
  public readonly items$: Observable<IContactAddress[]> = this.select(
    (state) => state.items,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  constructor(
    private readonly addressService: ContactAddressHttpService,
    private readonly addressDialogService: AddressDialogService,
  ) {
    super(initialState());
  }

  public readonly load = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap((contactId) => this.patchState({ contactId, loading: true })),
      switchMap((id) =>
        this.addressService.getAll(id).pipe(
          tapResponse(
            (items) => this.patchState({ items: items }),
            (e: HttpErrorResponse) => this.logError(e),
          ),
          tap(() => this.patchState({ loading: false })),
        ),
      ),
    );
  });

  public showCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(
        this.select((state) => state.contactId),
        (_, contactId) => contactId,
      ),
      exhaustMap((contactId) =>
        this.addressDialogService
          .open({
            contactId: contactId as string,
          })
          .pipe(take(1)),
      ),
      tapResponse(
        (result?: IContactAddress) => result && this.addItem(result),
        this.logError,
      ),
    );
  });

  public deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([, contactId]) => !!contactId),
      switchMap(([addressId, contactId]) =>
        this.addressService
          .delete(contactId as string, addressId)
          .pipe(tapResponse(() => this.remove(addressId), this.logError)),
      ),
    );
  });

  public showUpdateDialog = this.effect<IContactAddress>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([_, contactId]) => !!contactId),
      exhaustMap(([address, contactId]) =>
        this.addressDialogService
          .open({
            contactId: contactId as string,
            address: address,
          })
          .pipe(take(1)),
      ),
      tapResponse(
        (result?: IContactAddress) => result && this.setItem(result),
        this.logError,
      ),
    );
  });

  private addItem = this.updater((state, item: IContactAddress) => ({
    ...state,
    items: [...state.items, item],
  }));

  private setItem = this.updater((state, item: IContactAddress) => {
    const idx = state.items.findIndex((i) => i.id === item.id);
    if (idx === -1) {
      return state;
    }
    const items = [...state.items];
    items[idx] = item;
    return {
      ...state,
      items,
    };
  });

  private remove = this.updater(
    (state: IAddressesListState, addressId: string): IAddressesListState => {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== addressId),
      };
    },
  );

  private logError(e: Error) {}
}
