import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICollection, IPagination } from '~/@core/models';
import { ContactLinkTypeFilter, LinkType } from '~/contact/models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';

export type ILinkTypeData = Omit<LinkType, 'id' | 'createdAt' | 'updatedAt'>;

@Injectable({
  providedIn: 'root',
})
export class LinkTypeHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ContactLinkTypeFilter,
    LinkType
  >(this.getList.bind(this));

  constructor(private readonly http: HttpClient) {}

  public create(linkType: ILinkTypeData) {
    return this.http.post<LinkType>('/api/contact-link-types', linkType);
  }

  public getList(filter?: ContactLinkTypeFilter, pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<LinkType>>('/api/contact-link-types', {
      params: {
        ...filter,
        ...paginationQuery,
      },
    });
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(id: string, linkType: ILinkTypeData) {
    return this.http.put<LinkType>(`/api/contact-link-types/${id}`, linkType);
  }

  public delete(id: string) {
    return this.http.delete<void>(`/api/contact-link-types/${id}`);
  }
}
