import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ContactFilter } from '~/contact/models';

@Component({
  selector: 'app-persons-filter',
  templateUrl: './persons-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonsFilterComponent implements OnInit, OnDestroy {
  @Output()
  public filter = new EventEmitter<ContactFilter>();

  protected form = new FormGroup<{ [key: string]: AbstractControl }>({
    ident: new FormControl<string | undefined>(undefined),
    email: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(undefined),
  });

  private subs = new Subscription();

  constructor() {}

  public ngOnInit() {
    this.subs.add(
      this.form.valueChanges.subscribe(() => {
        this.filter.emit(this.mapFilter());
      }),
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private mapFilter(): ContactFilter {
    return {
      ident: this.form.get('ident')?.value || undefined,
      email: this.form.get('email')?.value || undefined,
      phone: this.form.get('phone')?.value || undefined,
    };
  }
}
