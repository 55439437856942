<ng-content></ng-content>
<app-info-row
  *ngIf="contact.identifier"
  [label]="'contact.label.identifier' | translate"
  [value]="contact.identifier"
></app-info-row>

<ng-container *ngFor="let dVal of contact.dictionaryValues">
  <ng-container
    *ngIf="getDictionary$(dVal.dictionaryId) | async as contactDictionary"
  >
    <app-info-row
      [label]="contactDictionary.name"
      [value]="valueLabels(dVal.values)"
    ></app-info-row>
  </ng-container>
</ng-container>
