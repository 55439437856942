import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-save-button',
  template: `
    <button
      pButton
      class="p-button-primary"
      icon="pi pi-check"
      [disabled]="disabled"
      [label]="'widgets.buttons.save' | translate"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveButtonComponent {
  @Input() public disabled = false;
}
