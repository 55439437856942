import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/components/layout/app.layout.component';
import { AuthenticatedGuard } from './auth/guards';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/pages/forgot-password').then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('~/auth/pages/reset-password').then(
        (m) => m.ResetPasswordComponent,
      ),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./homepage').then((m) => m.HomepageComponent),
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'dictionaries',
        loadChildren: () =>
          import('./dictionary/dictionary.module').then(
            (m) => m.DictionaryModule,
          ),
      },
      {
        path: 'activities',
        loadChildren: () => import('./activity').then((m) => m.ActivityModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./subscription').then((m) => m.SubscriptionModule),
      },
    ],
  },
  {
    path: 'auth',
    data: { breadcrumb: 'Auth' },
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./@core/pages/notfound/notfound.module').then(
        (m) => m.NotfoundModule,
      ),
  },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
