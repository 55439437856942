import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, first, mergeMap, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectAuthToken } from '../store/auth.selectors';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../http/auth.service';
import { logout, setAuthToken } from '../store/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private authHttp: AuthService,
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthToken).pipe(
      first(),
      mergeMap((authToken) => {
        const authReq = !!authToken ? this.addAuthHeader(req, authToken) : req;

        return next.handle(authReq).pipe(
          catchError((originalError: HttpErrorResponse) => {
            if (authToken && this.isExpiredError(originalError)) {
              return this.authHttp.refresh().pipe(
                switchMap((res) => {
                  this.store.dispatch(setAuthToken({ token: res.accessToken }));
                  return next.handle(this.addAuthHeader(req, res.accessToken));
                }),
                catchError((refreshError) => {
                  this.store.dispatch(logout());
                  return throwError(() => refreshError);
                }),
              );
            } else {
              return throwError(() => originalError);
            }
          }),
        );
      }),
    );
  }

  private isExpiredError(error: HttpErrorResponse) {
    return error.status === 401 && error.error?.message === 'Token expired';
  }

  private addAuthHeader(request: HttpRequest<any>, authToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}
