import { Router } from '@angular/router';
import { selectIsLoggedIn } from '../store/auth.selectors';
import { Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticatedGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.checkIfLoggedIn();
  }

  public canActivateChild(): Observable<boolean> {
    return this.checkIfLoggedIn();
  }

  private checkIfLoggedIn(): Observable<boolean> {
    return this.store.select(selectIsLoggedIn).pipe(
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          void this.router.navigate(['/login']);
        }
      }),
    );
  }
}
