import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

import { SubscriptionPickModalComponent } from '../../components/subscription-pick-modal';
import { ISubscription } from '../../models';

@Injectable()
export class PickSubscriptionService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(
    contacts?: ISubscription[],
    message?: string,
    translate = true,
  ): Observable<ISubscription | undefined> {
    const ref = this.dialogService.open(SubscriptionPickModalComponent, {
      header: this.translateService.instant('contact.header.pickContact'),
      resizable: false,
      draggable: false,
      closable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data: {
        contacts,
        message:
          message && translate
            ? this.translateService.instant(message)
            : message,
      },
    });
    return ref.onClose;
  }
}
