import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared.module';

import { DictionarySearchComponent } from './components/dictionary-search';
import { DictionaryItemSelectComponent } from '~/dictionary/@feature/components/dictionary-item-select';
import { SharedExtModule } from '~/shared-ext.module';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  imports: [SharedModule, SharedExtModule, MultiSelectModule],
  declarations: [DictionarySearchComponent, DictionaryItemSelectComponent],
  exports: [DictionarySearchComponent, DictionaryItemSelectComponent],
})
export class DictionaryFeatureModule {}
