import { EMPTY, expand, Observable, reduce } from 'rxjs';
import { ICollection, IPagination } from '~/@core/models';

export const getAllItemsFactory = <T>(
  listFunc: (pagination?: IPagination) => Observable<ICollection<T>>,
): (() => Observable<ICollection<T>>) => {
  return () => {
    const pagination = {
      page: 1,
      perPage: 50,
    };
    return listFunc(pagination).pipe(
      expand((response) => {
        if (response.total <= pagination.page * pagination.perPage) {
          return EMPTY;
        }
        pagination.page++;
        return listFunc(pagination);
      }),
      reduce<ICollection<T>>(
        (acc: ICollection<T>, current: ICollection<T>) => ({
          items: acc.items.concat(current.items),
          total: current.total,
        }),
      ),
    );
  };
};

export const getAllItemsWithFilterFactory = <TFilter, T>(
  listFunc: (
    filter?: TFilter,
    pagination?: IPagination,
  ) => Observable<ICollection<T>>,
): ((filter?: TFilter) => Observable<ICollection<T>>) => {
  return (filter?: TFilter) => {
    const pagination = {
      page: 1,
      perPage: 50,
    };
    return listFunc(filter, pagination).pipe(
      expand((response) => {
        if (response.total <= pagination.page * pagination.perPage) {
          return EMPTY;
        }
        pagination.page++;
        return listFunc(filter, pagination);
      }),
      reduce<ICollection<T>>(
        (acc: ICollection<T>, current: ICollection<T>) => ({
          items: acc.items.concat(current.items),
          total: current.total,
        }),
      ),
    );
  };
};
