import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISubscriptionDictionaryValue } from '~/subscription/models/subscription-dictionary-value';

@Component({
  selector: 'app-dictionary-info-row',
  templateUrl: './dictionary-info-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionaryInfoRowComponent {
  @Input() public dictionaryValue!: ISubscriptionDictionaryValue;

  protected dictionaryValueLabels(val: { label: string }[]) {
    return val.map((v) => v.label);
  }
}
