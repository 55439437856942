import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, Observable, of, tap, withLatestFrom } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { switchMap } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ISubscription } from '../../../models';
import {
  IPeriodPayload,
  ISubscriptionPayload,
  PeriodHttpService,
  SubscriptionHttpService,
} from '../../../services/http';

export interface ICreateState {
  subscription?: ISubscription;
  busy: boolean;
}

const initialState = (subscription?: ISubscription): ICreateState => ({
  subscription: subscription,
  busy: false,
});

@Injectable()
export class SubscriptionEditStore extends ComponentStore<ICreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  private subscription = this.select((state) => state.subscription);

  constructor(
    private readonly subscriptionHttpService: SubscriptionHttpService,
    private readonly periodService: PeriodHttpService,
    private readonly ref: DynamicDialogRef,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data?.subscription));
  }

  public readonly submitSubscription = this.effect<{
    subscriptionPayload: ISubscriptionPayload;
    periodPayload?: Omit<IPeriodPayload, 'subscriptionId'>;
  }>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.subscription),
      switchMap(([data, subscription]) =>
        subscription
          ? this.subscriptionHttpService
              .update(subscription.id, data.subscriptionPayload)
              .pipe(
                tapResponse(
                  (dictionary) => this.ref.close(dictionary),
                  (e: HttpErrorResponse) => this.logError(e),
                ),
              )
          : this.subscriptionHttpService.create(data.subscriptionPayload).pipe(
              switchMap((createdSubscription) => {
                return this.periodService
                  .create({
                    ...(data.periodPayload as IPeriodPayload),
                    subscriptionId: createdSubscription.id,
                  })
                  .pipe(
                    switchMap(() =>
                      this.subscriptionHttpService.getOne(
                        createdSubscription.id,
                      ),
                    ),
                    catchError((e: HttpErrorResponse) => {
                      return of(createdSubscription);
                    }),
                  );
              }),
              tapResponse(
                (dictionary) => this.ref.close(dictionary),
                (e: HttpErrorResponse) => this.logError(e),
              ),
            ),
      ),
      tap(() => this.patchState({ busy: false })),
    );
  });

  public cancel(): void {
    this.ref.close();
  }

  private logError(e: Error) {}
}
