<form [formGroup]="form" (ngSubmit)="save()">
  <div class="grid p-fluid formgrid">
    <div class="col-12 field">
      <label for="number" class="text-900 font-semibold">{{
        "contact.label.phoneNumber" | translate
      }}</label>
      <p-inputMask
        mask="+99 (999) 999-99-99"
        id="number"
        formControlName="number"
        placeholder="+99 (999) 999-99-99"
      />
      <app-validation-errors formControlName="number">
        <app-validation-error formControlName="number" error="required" />
        <app-validation-error
          formControlName="number"
          error="phoneExist"
          [message]="'contact.validation.phoneExists' | translate"
        />
        <app-validation-error
          formControlName="number"
          error="pattern"
          [message]="'contact.validation.phoneNotValid' | translate"
        />
      </app-validation-errors>
    </div>

    <div class="col-12 field">
      <label for="number" class="text-900 font-semibold">{{
        "contact.label.note" | translate
      }}</label>
      <input pInputText formControlName="note" />
      <app-validation-errors formControlName="note">
        <app-validation-error formControlName="note" error="maxLength" />
      </app-validation-errors>
    </div>
  </div>

  <div class="flex gap-2 justify-content-end">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="form.invalid"></app-save-button>
  </div>
</form>
