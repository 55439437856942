import { Component, Input } from '@angular/core';
import {
  ImportColumnConfiguration,
  ImportResult,
} from '~/@core/modules/importer/models';
import { IDataColumn } from '~/@core/modules/importer/components/importer/importer.store';

@Component({
  selector: 'app-data-preview',
  templateUrl: './data-preview.component.html',
})
export class DataPreviewComponent {
  @Input() public columnConfigurations?: ImportColumnConfiguration[];
  @Input() public dataColumns?: IDataColumn[];
  @Input() public data?: any[];
  @Input() public result?: ImportResult[];
  @Input() public mapping?: Map<number, string>;

  public getColumnConfigName(key: string) {
    return this.columnConfigurations?.find((c) => c.key === key)
      ?.name as string;
  }

  public getColumnDataName(key: number) {
    return this.dataColumns?.find((c) => c.key === key)?.name;
  }
}
