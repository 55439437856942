import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActivityStatus } from '../../../models';
import {
  addActivityStatus,
  deleteActivityStatus,
  fetchActivityStatuses,
  fetchActivityStatusesFail,
  fetchActivityStatusesSuccess,
  updateActivityStatus,
} from './activity-status.actions';

export const taskStatusEntityAdapter = createEntityAdapter<ActivityStatus>();

export interface ActivityStatusState extends EntityState<ActivityStatus> {
  loading: boolean;
  lastLoadedTime?: Date;
}

const createDictionaryInitState = (): ActivityStatusState =>
  taskStatusEntityAdapter.getInitialState({
    loading: false,
  });

export const activityStatusReducer = createReducer<ActivityStatusState>(
  createDictionaryInitState(),
  on(
    fetchActivityStatuses,
    (state): ActivityStatusState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    fetchActivityStatusesSuccess,
    (state, { items }): ActivityStatusState =>
      taskStatusEntityAdapter.setAll(items, {
        ...state,
        loading: false,
        lastLoadedTime: new Date(),
      }),
  ),
  on(
    fetchActivityStatusesFail,
    (state): ActivityStatusState => ({
      ...state,
      loading: false,
      lastLoadedTime: undefined,
    }),
  ),
  on(
    addActivityStatus,
    (state, { item }): ActivityStatusState =>
      taskStatusEntityAdapter.addOne(item, state),
  ),
  on(
    updateActivityStatus,
    (state, { item }): ActivityStatusState =>
      taskStatusEntityAdapter.updateOne(
        {
          id: item.id,
          changes: item,
        },
        state,
      ),
  ),
  on(
    deleteActivityStatus,
    (state, { id }): ActivityStatusState =>
      taskStatusEntityAdapter.removeOne(id, state),
  ),
);
