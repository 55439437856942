import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { provideComponentStore } from '@ngrx/component-store';

import { ImporterStore } from './importer.store';
import {
  ImportColumnConfiguration,
  ImportResult,
} from '~/@core/modules/importer/models';

export type ImportFunction = (
  data: Record<string, any>,
) => Observable<ImportResult>;

@Component({
  providers: [provideComponentStore(ImporterStore)],
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImporterComponent implements OnInit {
  @Input() public columnConfigurations: ImportColumnConfiguration[] = [];
  // TODO: Refactor with injectable service
  @Input() public importFunction?: ImportFunction;

  constructor(public readonly cs: ImporterStore) {}

  public ngOnInit(): void {
    if (!this.importFunction) {
      throw new Error('Import function is required');
    }
    this.cs.importFunction = this.importFunction;
  }
}
