import { createReducer, on } from '@ngrx/store';
import { logout, setAuthToken } from './auth.actions';
export interface IAuthState {
  authToken: string | null;
}

export const createAuthInitialState = (): IAuthState => ({
  authToken: null,
});

export const authReducer = createReducer<IAuthState>(
  createAuthInitialState(),
  on(
    setAuthToken,
    (state, { token }): IAuthState => ({
      ...state,
      authToken: token,
    }),
  ),
  on(
    logout,
    (state): IAuthState => ({
      ...state,
      authToken: null,
    }),
  ),
);

export const authFeatureKey = 'authorisation';
