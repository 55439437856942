@if (contact) {
  <div class="relative flex no-wrap">
    <a
      pButton
      pRipple
      [pTooltip]="noTooltip ? undefined : tooltipContent"
      [routerLink]="link"
      severity="info"
      [label]="buttonText"
      tooltipPosition="top"
      class="contact-link h-2rem"
    ></a>
    <button
      pButton
      size="small"
      severity="info"
      [icon]="phonesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
      class="h-2rem w-2rem dropdown-button"
      (click)="openAdditionalBlock($event)"
      [disabled]="phonesLoading"
    ></button>

    <p-overlayPanel [style]="{ width: '320px' }">
      @if (phones.length > 0) {
        @for (phone of phones; track $index) {
          <div
            class="flex justify-content-between align-items-center gap-4 py-2 w-full"
          >
            <div
              class="flex-grow-1 text-700 flex gap-1 align-items-center phone-link font-semibold"
            >
              <span>{{ phone.number }}</span>
              <a
                pButton
                pRipple
                icon="pi pi-phone"
                class="p-button-success h-2rem w-2rem"
                href="tel:{{ phone.number }}"
              ></a>
              <a
                pButton
                pRipple
                icon="pi pi-link"
                class="p-button-primary h-2rem w-2rem"
                [routerLink]="['/contacts', 'phone-call']"
                [queryParams]="{ phone: phone.number }"
              ></a>
            </div>
            <div class="text-xs text-right">{{ phone.note }}</div>
          </div>
        }
      } @else {
        <div class="p-2 surface-0 border-1 border-300 border-round text-center">
          {{ "contact.label.noPhones" | translate }}
        </div>
      }
    </p-overlayPanel>
  </div>

  <ng-template #tooltipContent>
    @if (contact.identifier) {
      <div>{{ contact.identifier }}</div>
    }
    @if (normalizedName.length > limit) {
      <div>{{ contact.name }}</div>
    }
  </ng-template>
}
