import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-row',
  template: `
    <div class="flex justify-content-between align-items-center mb-1">
      <span class="text-600 font-semibold">{{ label }}</span>
      @if (Array.isArray(value)) {
        <div class="flex gap-1">
          @for (i of value; track value) {
            <p-chip [label]="i"></p-chip>
          }
        </div>
      } @else {
        <span>{{ value }}</span>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoRowComponent {
  @Input() public label?: string;
  @Input() public value?: string | string[];
  protected readonly Array = Array;
}
