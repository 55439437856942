import { NgModule } from '@angular/core';

import { SharedExtModule } from '~/shared-ext.module';

import { ConfirmDialogService } from './confirm-dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [ConfirmDialogComponent],
  providers: [ConfirmDialogService, DialogService],
  imports: [SharedExtModule],
})
export class ConfirmModule {}
