@if (user) {
  <p-avatar
    [label]="initials"
    [size]="size"
    shape="circle"
    [pTooltip]="user.name"
    tooltipPosition="top"
    showDelay="300"
  ></p-avatar>
}
