import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  fetchContactConfigurationValues,
  fetchContactConfigurationValuesFail,
  fetchContactConfigurationValuesSuccess,
} from './configuration.actions';
import { ConfigValue } from '~/@core/models';

export const configValueAdapter = createEntityAdapter<ConfigValue>();

export interface ContactConfigurationState {
  loaded: boolean;
  loading: boolean;
  error?: any;
  entities: EntityState<ConfigValue>;
}

const createInitState = (): ContactConfigurationState => ({
  loaded: false,
  loading: false,
  entities: configValueAdapter.getInitialState(),
});

export const configurationReducer = createReducer<ContactConfigurationState>(
  createInitState(),
  on(
    fetchContactConfigurationValues,
    (state): ContactConfigurationState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    fetchContactConfigurationValuesSuccess,
    (state, { list }): ContactConfigurationState => ({
      ...state,
      loading: false,
      loaded: true,
      entities: configValueAdapter.setAll(list, state.entities),
    }),
  ),
  on(
    fetchContactConfigurationValuesFail,
    (state): ContactConfigurationState => ({
      ...state,
      loading: false,
    }),
  ),
);
