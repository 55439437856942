import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CreateStore } from './create.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dictionary-create',
  templateUrl: './create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreateStore],
})
export class CreateComponent {
  public form: FormGroup;
  constructor(private readonly store: CreateStore) {
    this.form = new FormGroup<{
      name: FormControl;
    }>({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });
  }

  public createDictionary() {
    this.store.createDictionary(this.form.value);
  }

  public cancel() {
    this.store.returnToList();
  }
}
