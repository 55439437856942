import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { EmailsListStore } from './emails-list.store';

@Component({
  selector: 'app-emails-list',
  templateUrl: './emails-list.component.html',
  providers: [provideComponentStore(EmailsListStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsListComponent implements OnInit {
  @Input() public contactId?: string | undefined;
  @Input() public edit: boolean = true;

  constructor(public readonly cs: EmailsListStore) {}

  public ngOnInit() {
    if (this.contactId) {
      this.cs.loadEmails(this.contactId);
    }
  }
}
