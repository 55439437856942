<p-avatarGroup>
  <app-user-avatar
    *ngFor="let user of users | slice: 0 : showMax"
    [user]="user"
    [size]="size"
  ></app-user-avatar>
  <p-avatar
    *ngIf="users.length > showMax"
    label="+ {{ users.length - showMax }}"
    shape="circle"
    [size]="size"
    [style]="{
      'background-color': '#ffffff',
      color: '#212121',
      border: '2px solid var(--surface-border)'
    }"
  ></p-avatar>
</p-avatarGroup>
