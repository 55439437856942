import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { IPeriod, ISubscription } from '../../models';
import { AddPeriodComponent } from '../../components/add-period';

@Injectable()
export class PeriodDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(subscription: ISubscription): Observable<IPeriod | undefined> {
    const ref = this.dialogService.open(AddPeriodComponent, {
      header: this.translateService.instant('subscription.header.addPeriod'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data: { subscription },
    });
    return ref.onClose;
  }
}
