import { Component, Input } from '@angular/core';
import { User } from '~/user/models';

@Component({
  selector: 'app-users-group',
  templateUrl: './users-group.component.html',
  styleUrls: ['./users-group.component.scss'],
})
export class UsersGroupComponent {
  @Input() public users: User[] = [];
  @Input() public showMax: number = 4;
  @Input() public size: 'normal' | 'large' | 'xlarge' = 'normal';
}
