import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { catchError, EMPTY, of, withLatestFrom } from 'rxjs';

import { ActivityStatusHttpService } from 'src/app/activity/services/http';
import {
  fetchActivityStatuses,
  fetchActivityStatusesFail,
  fetchActivityStatusesSuccess,
} from './activity-status.actions';
import { checkActivityTypes } from '~/activity/@root/store/activity-type/activity-type.actions';
import {
  selectActivityStatusesLoadedTime,
  selectActivityStatusesLoading,
} from '~/activity/@root/store/activity-status/activity-status.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ActivityStatusEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ActivityStatusHttpService,
    private readonly store: Store,
  ) {}

  public fetchIfEmptyOrTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkActivityTypes),
      withLatestFrom(
        this.store.select(selectActivityStatusesLoadedTime),
        this.store.select(selectActivityStatusesLoading),
        (_, loaded, loading) => ({
          loaded,
          loading,
        }),
      ),
      switchMap(({ loading, loaded }) => {
        if (loading) {
          return EMPTY;
        }
        if (!loaded || this.isLoadTimeExceed(loaded)) {
          return of(fetchActivityStatuses());
        }
        return EMPTY;
      }),
    );
  });

  public fetchStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchActivityStatuses),
      switchMap(() =>
        this.service.getAll().pipe(
          map((col) => fetchActivityStatusesSuccess({ items: col.items })),
          catchError((error) => of(fetchActivityStatusesFail({ error }))),
        ),
      ),
    );
  });

  private isLoadTimeExceed = (lastLoadedTime: Date): boolean => {
    return new Date().getTime() - lastLoadedTime.getTime() > 30000;
  };
}
