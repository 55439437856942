import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserSearchStore } from './user-search.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  providers: [
    provideComponentStore(UserSearchStore),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserDropdownComponent),
      multi: true,
    },
  ],
})
export class UserDropdownComponent implements ControlValueAccessor {
  @Input()
  public placeholder = '';

  @Input() showClear = true;

  @ViewChild(Dropdown, { static: true })
  protected dropdown!: Dropdown;

  constructor(protected readonly cs: UserSearchStore) {}

  public writeValue(val: any): void {
    this.dropdown.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
  }
}
