import { HttpClient } from '@angular/common/http';
import { EMPTY, expand, Observable, reduce } from 'rxjs';
import { ICollection, IPagination } from '../../@core/models';
import { IDictionary, IDictionaryPayload, IDictionaryFilter } from '../models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DictionaryHttpService {
  constructor(private http: HttpClient) {}

  public create(data: IDictionaryPayload): Observable<IDictionary> {
    return this.http.post<IDictionary>('/api/dictionaries', data);
  }

  public getList(
    filter?: IDictionaryFilter,
    pagination?: IPagination,
  ): Observable<ICollection<IDictionary>> {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<IDictionary>>('/api/dictionaries', {
      params: {
        ...filter,
        ...paginationQuery,
      },
    });
  }

  public getAll(
    filter?: IDictionaryFilter,
  ): Observable<ICollection<IDictionary>> {
    const pagination = {
      page: 1,
      perPage: 50,
    };
    return this.getList(filter, pagination).pipe(
      expand((response) => {
        if (response.total <= pagination.page * pagination.perPage) {
          return EMPTY;
        }
        pagination.page++;
        return this.getList(filter, pagination);
      }),
      reduce(
        (acc, current) => ({
          items: acc.items.concat(current.items),
          total: current.total,
        }),
        {
          items: [],
          total: 0,
        } as ICollection<IDictionary>,
      ),
    );
  }

  public getItem(id: string): Observable<IDictionary> {
    return this.http.get<IDictionary>(`/api/dictionaries/${id}`);
  }

  public update(id: string, data: IDictionaryPayload): Observable<IDictionary> {
    return this.http.put<IDictionary>(`/api/dictionaries/${id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/dictionaries/${id}`);
  }
}
