import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ICollection, IPagination, ISearchFilter } from '~/@core/models';

import { IContactDictionaryData } from './contact-dictionary.payloads';
import { ContactDictionary } from '../../models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';

@Injectable({
  providedIn: 'root',
})
export class ContactDictionaryHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ISearchFilter,
    ContactDictionary
  >(this.getList.bind(this));

  constructor(protected http: HttpClient) {}

  public create(data: IContactDictionaryData): Observable<ContactDictionary> {
    return this.http.post<ContactDictionary>(`/api/contact-dictionaries`, {
      ...data,
    });
  }

  public getList(
    filter?: ISearchFilter,
    pagination?: IPagination,
  ): Observable<ICollection<ContactDictionary>> {
    return this.http.get<ICollection<ContactDictionary>>(
      `/api/contact-dictionaries`,
      {
        params: {
          ...filter,
          ...pagination,
        },
      },
    );
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(
    dictionaryId: string,
    data: IContactDictionaryData,
  ): Observable<ContactDictionary> {
    return this.http.put<ContactDictionary>(
      `/api/contact-dictionaries/${dictionaryId}`,
      {
        ...data,
      },
    );
  }

  public delete(dictionaryId: string): Observable<void> {
    return this.http.delete<void>(`/api/contact-dictionaries/${dictionaryId}`);
  }
}
