import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ICollection, IPagination, ISearchFilter } from '~/@core/models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';

import { ISubscriptionDictionaryData } from './subscription-dictionary.payloads';
import { SubscriptionDictionary } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDictionaryHttpService {
  private static readonly RESOURCE = '/api/subscription-dictionaries';

  private readonly getAllItems = getAllItemsWithFilterFactory<
    ISearchFilter,
    SubscriptionDictionary
  >(this.getList.bind(this));

  constructor(protected http: HttpClient) {}

  public create(
    data: ISubscriptionDictionaryData,
  ): Observable<SubscriptionDictionary> {
    return this.http.post<SubscriptionDictionary>(
      SubscriptionDictionaryHttpService.RESOURCE,
      {
        ...data,
      },
    );
  }

  public getList(
    filter?: ISearchFilter,
    pagination?: IPagination,
  ): Observable<ICollection<SubscriptionDictionary>> {
    return this.http.get<ICollection<SubscriptionDictionary>>(
      SubscriptionDictionaryHttpService.RESOURCE,
      {
        params: {
          ...filter,
          ...pagination,
        },
      },
    );
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(
    dictionaryId: string,
    data: ISubscriptionDictionaryData,
  ): Observable<SubscriptionDictionary> {
    return this.http.put<SubscriptionDictionary>(
      `${SubscriptionDictionaryHttpService.RESOURCE}/${dictionaryId}`,
      {
        ...data,
      },
    );
  }

  public delete(dictionaryId: string): Observable<void> {
    return this.http.delete<void>(
      `${SubscriptionDictionaryHttpService.RESOURCE}/${dictionaryId}`,
    );
  }
}
