@if (cs.activity$ | async; as activity) {
  <div class="card">
    <div class="card-header pb-3 border-bottom-1 border-gray-300">
      <div class="flex justify-content-between align-items-center py-1">
        <div>
          <app-activity-resolution-date [activity]="activity" />
        </div>
        <div>
          <app-user-avatar
            size="normal"
            [user]="activity.creator"
          ></app-user-avatar>
        </div>
      </div>
      <div class="flex align-items-center gap-2">
        <app-activity-type-badge
          [type]="activity.type"
        ></app-activity-type-badge>
        <p-badge [value]="activity.status.name"></p-badge>
      </div>
    </div>
    <div class="card-body py-2">
      @if (activity.description) {
        <div
          class="border-2 border-dashed surface-border p-2 mt-2"
          [innerHTML]="activity.description"
        ></div>
      }

      @if (activity.actors.length > 0) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.actors" | translate }}</span>
          <app-users-group [users]="activity.actors"></app-users-group>
        </div>
      }

      @if (activity.supervisors.length > 0) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.supervisors" | translate }}</span>
          <app-users-group [users]="activity.supervisors"></app-users-group>
        </div>
      }

      @if (activity.resolvedAt) {
        <div class="flex justify-content-between align-items-center py-1">
          <span>{{ "activity.label.finishedAt" | translate }}</span>
          <div>{{ activity.resolvedAt | date: "medium" }}</div>
        </div>
      }
    </div>
  </div>
}
