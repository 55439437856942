import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserHttpService } from 'src/app/user/services/http';
import { User } from '~/user/models';
import { tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserId } from '~/auth/store/auth.selectors';

export interface IUserSearchState {
  users: User[];
}

const initialState = (): IUserSearchState => ({
  users: [],
});

@Injectable()
export class UserSearchStore
  extends ComponentStore<IUserSearchState>
  implements OnStoreInit
{
  private currentUserId$ = this.store.select(selectUserId);

  public users$ = this.select((state) => state.users).pipe(
    withLatestFrom(this.currentUserId$),
    map(([users, currentUserId]) => {
      const currentUser = users.find((user) => user.id === currentUserId);
      const usersToSort = users.filter((user) => user.id !== currentUserId);
      usersToSort.sort((a, b) => {
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;

        return a.name.localeCompare(b.name);
      });

      return [currentUser, ...usersToSort];
    }),
  );

  constructor(
    private readonly userService: UserHttpService,
    private readonly store: Store,
  ) {
    super(initialState());
  }

  public ngrxOnStoreInit() {
    this.fetchUsers();
  }

  protected fetchUsers = this.effect((trigger$) => {
    return trigger$.pipe(
      switchMap(() => this.userService.getAll()),
      tap((list) => this.patchState({ users: list.items })),
    );
  });
}
