import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { UsersListStore } from './users-list.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  providers: [provideComponentStore(UsersListStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent {
  protected canManageUsers$ = this.store.select(selectUserHasRole('admin'));

  constructor(
    protected cs: UsersListStore,
    private store: Store,
  ) {}

  public load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        search: event.globalFilter ? (event.globalFilter as string) : undefined,
      },
    });
  }
}
