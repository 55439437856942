import { createSelector } from '@ngrx/store';
import { selectContactFeature } from '../feature.selector';
import { dictionaryItemsAdapter } from './contact-dictionary.reducer';

const { selectAll } = dictionaryItemsAdapter.getSelectors();

const selectItemsSlice = createSelector(
  selectContactFeature,
  (state) => state.contactDictionaries,
);

export const selectContactDictionaryLoading = createSelector(
  selectItemsSlice,
  (state) => state?.loading || false,
);

export const selectContactDictionaryLoaded = createSelector(
  selectItemsSlice,
  (state) => state?.loading || false,
);

export const selectContactDictionaryList = createSelector(
  selectItemsSlice,
  (state) => (state?.entities ? selectAll(state.entities) : []),
);

export const selectContactDictionaryId = (id: string) =>
  createSelector(
    selectItemsSlice,
    (state) => state.entities.entities[id] || null,
  );
