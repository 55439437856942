<form [formGroup]="form" (ngSubmit)="save()">
  <div class="grid p-fluid formgrid">
    <div class="col-12 md:col-6 field">
      <label for="identifier" class="text-900 font-semibold">{{
        "contact.label.identifier" | translate
      }}</label>
      <ng-container *ngIf="form.get('identifier') as control">
        <input
          id="identifier"
          type="text"
          [placeholder]="'contact.label.identifier' | translate"
          pInputText
          [formControl]="$any(control)"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="pattern"
            [message]="'contact.validation.nonValidIdentifier' | translate"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="uniqueIdent"
            [message]="'contact.validation.identifierAlreadyExist' | translate"
          />
        </app-validation-errors>
      </ng-container>
    </div>
    <div class="col-12 md:col-6 field">
      <label for="shortName" class="text-900 font-semibold">{{
        "contact.label.shortName" | translate
      }}</label>
      <ng-container *ngIf="form.get('shortName') as control">
        <input
          id="shortName"
          type="text"
          [placeholder]="'contact.label.shortName' | translate"
          pInputText
          [formControl]="$any(control)"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </ng-container>
    </div>
    <div class="col-12 field">
      <label for="longName" class="text-900 font-semibold">{{
        "contact.label.longName" | translate
      }}</label>
      <input
        id="longName"
        type="text"
        [placeholder]="'contact.label.longName' | translate"
        pInputText
        formControlName="longName"
      />
    </div>
    <div class="col-12 field">
      <label for="description" class="text-900 font-semibold">{{
        "contact.label.note" | translate
      }}</label>
      <app-editor
        id="description"
        formControlName="note"
        [style]="{ height: '150px' }"
      ></app-editor>
    </div>

    @for (dictionary of cs.dictionaries$ | async; track dictionary.id) {
      <div class="col-12 field">
        <label class="text-900 font-semibold">{{ dictionary.name }}</label>
        @if (dictionary.multiple) {
          <p-multiSelect
            [formControlName]="dictionaryFormKey(dictionary.id)"
            [options]="
              (cs.dictionaryItems$(dictionary.dictionary.id) | async) || []
            "
            [showClear]="true"
            optionLabel="value"
            optionValue="id"
            appendTo="body"
          ></p-multiSelect>
        } @else {
          <p-dropdown
            [formControlName]="dictionaryFormKey(dictionary.id)"
            [showClear]="true"
            [options]="
              (cs.dictionaryItems$(dictionary.dictionary.id) | async) || []
            "
            optionLabel="value"
            optionValue="id"
            appendTo="body"
          ></p-dropdown>
        }
      </div>
    }
  </div>
  <div class="flex gap-2 justify-content-end">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="form.invalid"></app-save-button>
  </div>
</form>
