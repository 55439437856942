import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelect } from 'primeng/multiselect';
import { UserSearchStore } from './user-search.store';
import { provideComponentStore } from '@ngrx/component-store';

@Component({
  selector: 'app-multiple-user-dropdown',
  templateUrl: './multiple-user-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideComponentStore(UserSearchStore),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleUserDropdownComponent),
      multi: true,
    },
  ],
})
export class MultipleUserDropdownComponent implements ControlValueAccessor {
  @Input()
  public placeholder = '';

  @ViewChild(MultiSelect, { static: true })
  public multiSelect!: MultiSelect;

  constructor(protected readonly cs: UserSearchStore) {}

  public writeValue(val: any): void {
    this.multiSelect.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.multiSelect.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.multiSelect.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.multiSelect.setDisabledState(isDisabled);
  }
}
