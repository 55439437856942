import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { User } from '../../models';
import { UserEditComponent } from '../../components/user-edit';

@Injectable()
export class UserDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(data?: { user?: User }): Observable<User | undefined> {
    const ref = this.dialogService.open(UserEditComponent, {
      header: data?.user
        ? this.translateService.instant('user.header.editUser')
        : this.translateService.instant('user.header.addUser'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data,
    });
    return ref.onClose;
  }
}
