import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  public toApiDateTime(date: Date): string {
    const apiDate = DateTime.fromJSDate(date).toRFC2822();
    if (!apiDate) {
      throw new Error('Invalid date');
    }

    return apiDate;
  }
}
