<div
  class="flex gap-3 flex-row align-items-center p-2 border-bottom-1 surface-border"
>
  <div class="flex-grow-1">
    <a class="text-700 font-semibold" href="mailto:{{ item?.address }}">{{
      item?.address
    }}</a>
  </div>
  <div class="w-3">{{ item?.note }}</div>
  @if (edit) {
    <div class="flex-grow-0 flex align-items-end gap-1">
      <app-row-edit-button (edit)="update.emit(item)" />
      <app-row-delete-button (delete)="delete.emit(item)" />
    </div>
  }
</div>
