import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { User } from '../../models';
import { PatchUserPayload, UserHttpService } from '../../services/http';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface ICreateState {
  user?: User;
  busy: boolean;
}

const initialState = (user?: User): ICreateState => ({
  user: user,
  busy: false,
});

@Injectable()
export class UserPasswordStore extends ComponentStore<ICreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  private user$ = this.select((state) => state.user);

  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly ref: DynamicDialogRef,
    private readonly errorDialogService: ErrorDialogService,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data?.user));
  }

  public readonly patchUser = this.effect<PatchUserPayload>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.user$),
      switchMap(([data, user]) =>
        this.userHttpService.patch(user?.id as string, data),
      ),
      tap(() => this.patchState({ busy: false })),
      tap((user) => this.ref.close(user)),
      this.errorDialogService.handleHttpError(),
    );
  });

  public cancel(): void {
    this.ref.close();
  }
}
