export enum SubscriptionTypeStatus {
  Active = 'active',
  Archived = 'archived',
  Disabled = 'disabled',
}

export type SubscriptionType = {
  id: string;
  name: string;
  status: SubscriptionTypeStatus;
};
