import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ImportResult } from '~/@core/modules/importer/models';

@Component({
  selector: 'app-result-errors',
  templateUrl: './result-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultErrorsComponent {
  @Input() public result!: ImportResult;

  protected visible = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  public setVisible(visible: boolean): void {
    this.visible = visible;
    this.cdRef.markForCheck();
  }
}
