import { Component, ViewChild } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { DeletedContactsStore } from './deleted-contacts.store';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ContactFilter } from '~/contact/models';
import { FilterMetadata } from 'primeng/api/filtermetadata';

@Component({
  selector: 'app-companies-list',
  templateUrl: './deleted-contacts-list.component.html',
  providers: [provideComponentStore(DeletedContactsStore)],
})
export class DeletedContactsComponent {
  @ViewChild('dt', { static: false })
  protected dt!: Table;

  constructor(public readonly cs: DeletedContactsStore) {}

  public load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    const filter = event.filters?.['filter']
      ? ((event.filters?.['filter'] as FilterMetadata).value as ContactFilter)
      : {};

    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        search: event?.globalFilter
          ? (event?.globalFilter as string)
          : undefined,
        ...filter,
      },
    });
  }
}
