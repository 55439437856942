import { NgModule } from '@angular/core';
import { RowCancelButtonComponent } from './row-cancel-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [RowCancelButtonComponent],
  imports: [SharedExtModule],
  exports: [RowCancelButtonComponent],
})
export class RowCancelButtonModule {}
