import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Contact } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ContactHttpService<TContact extends Contact = Contact> {
  protected constructor(protected http: HttpClient) {}

  public getOne(id: string): Observable<TContact> {
    return this.http.get<TContact>(`/api/contacts/${id}`);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/contacts/${id}`);
  }
}
