import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-row-edit-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-pencil"
      class="p-button-success h-2rem w-2rem"
      [pTooltip]="'common.button.edit' | translate"
      (click)="!disabled && edit.emit($event)"
      [disabled]="disabled"
      tooltipPosition="top"
      type="button"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowEditButtonComponent {
  @Output() public edit = new EventEmitter<MouseEvent>();
  @Input() public disabled = false;
}
