import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '~/user/models';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() public user!: User;
  @Input() public size: 'normal' | 'large' | 'xlarge' = 'normal';

  protected get initials() {
    return this.user?.name
      .split(' ')
      .map((part) => part.charAt(0))
      .join('');
  }
}
