@if (contactWidgets && contactWidgets.length > 0) {
  <p-tabView>
    @for (widget of contactWidgets; track widget.widgetId) {
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex gap-1">
            @if (widget?.widgetName; as name) {
              <span>{{ name | translate }}</span>
            }
          </div>
        </ng-template>
        <app-dynamic-widget
          [widget]="widget"
          [data]="{ contact: contact }"
        ></app-dynamic-widget>
      </p-tabPanel>
    }
  </p-tabView>
}
