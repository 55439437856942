import * as commonEn from './en.json';
import * as commonUk from './uk.json';
import * as authEn from './auth/en.json';
import * as authUk from './auth/uk.json';
import * as contactEn from './contact/en.json';
import * as contactUk from './contact/uk.json';
import * as widgetsEn from './widgets/en.json';
import * as widgetsUk from './widgets/uk.json';
import * as activityEn from './activity/en.json';
import * as activityUk from './activity/uk.json';
import * as subscriptionEn from './subscription/en.json';
import * as subscriptionUk from './subscription/uk.json';
import * as dictionaryEn from './dictionary/en.json';
import * as dictionaryUk from './dictionary/uk.json';
import * as userEn from './user/en.json';
import * as userUk from './user/uk.json';
import * as primengEn from './primeng/en.json';
import * as primengUk from './primeng/uk.json';

export const getEnTranslation = (): any => {
  return {
    auth: authEn,
    common: commonEn,
    contact: contactEn,
    widgets: widgetsEn,
    activity: activityEn,
    subscription: subscriptionEn,
    dictionary: dictionaryEn,
    user: userEn,
    primeng: primengEn,
  };
};

export const getUkTranslation = (): any => {
  return {
    auth: authUk,
    common: commonUk,
    contact: contactUk,
    widgets: widgetsUk,
    activity: activityUk,
    subscription: subscriptionUk,
    dictionary: dictionaryUk,
    user: userUk,
    primeng: primengUk,
  };
};
