<div class="flex flex-column gap-3">
  @if (activity.description) {
    <div>
      <div class="text-600 font-bold mb-2">
        {{ "activity.label.description" | translate }}:
      </div>
      <div class="border-2 border-dashed surface-border p-2 flex gap-4">
        <div>
          <app-user-avatar [user]="activity.creator"></app-user-avatar>
        </div>
        <div [innerHtml]="activity.description"></div>
      </div>
    </div>
  }

  @if (activity.lastComment) {
    <div>
      <div class="text-600 font-bold mb-2">
        {{ "activity.label.lastComment" | translate }}
        <span class="text-500"
          >({{ activity.lastComment.createdAt | date: "Y/MM/dd HH:mm" }})</span
        >:
      </div>
      <div class="border-2 border-dashed surface-border p-2 flex gap-4">
        <div>
          <app-user-avatar
            [user]="activity.lastComment.actor"
          ></app-user-avatar>
        </div>
        <div [innerHtml]="activity.lastComment.text"></div>
      </div>
    </div>
  }

  @if (activity.additionalContacts && activity.additionalContacts.length > 0) {
    <div>
      <div class="text-600 font-bold mb-2">
        {{ "activity.label.additionalContacts" | translate }}:
      </div>
      <div class="border-2 border-dashed surface-border p-2 flex gap-4">
        @for (contact of activity.additionalContacts; track $index) {
          <app-contact-link [contact]="contact"></app-contact-link>
        }
      </div>
    </div>
  }
</div>
