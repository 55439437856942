import { ContactType, SlimContact } from '~/contact/models';
import { ICollection } from '~/@core/models';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SlimContactHttpService } from '~/contact/services/http/slim-contact.http-service';
import { HttpErrorResponse } from '@angular/common/http';
import { withLatestFrom } from 'rxjs';

export interface IContactSearchState {
  search: string;
  type?: ContactType;
  suggestions: ICollection<SlimContact>;
}

const initialState = (): IContactSearchState => ({
  search: '',
  suggestions: {
    items: [],
    total: 0,
    page: 0,
    perPage: 0,
  },
});

@Injectable()
export class ContactSearchStore extends ComponentStore<IContactSearchState> {
  public suggestions$ = this.select(
    this.state$,
    ({ suggestions }) => suggestions.items,
  );

  constructor(private readonly slimContactService: SlimContactHttpService) {
    super(initialState());
  }

  public findSuggestions = this.effect<string>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.select((state) => state.type)),
      map(([searchTerm, type]) => ({
        search: searchTerm,
        ...(type ? { type } : {}),
      })),
      switchMap((filter) =>
        this.slimContactService.getList(filter, { page: 1, perPage: 10 }).pipe(
          tapResponse(
            (response) => {
              this.patchState({
                suggestions: response,
              });
            },
            (error: HttpErrorResponse) => {
              this.logError(error);
            },
          ),
        ),
      ),
    ),
  );

  public setType = this.updater<ContactType | undefined>((state, type) => ({
    ...state,
    type,
  }));

  private logError(e: Error) {
    console.log(e);
  }
}
