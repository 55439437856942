import { ComponentStore } from '@ngrx/component-store';
import { exhaustMap, Observable, take, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IListState, initialListState } from '~/@core/store/states';
import { ICollection, IPagination } from '~/@core/models';
import { Router } from '@angular/router';
import { ISubscription } from '../../models';
import {
  ISubscriptionFilter,
  SubscriptionHttpService,
} from '../../services/http';
import { SubscriptionDialogService } from '../../@common/services/dialogs/subscription-dialog.service';
import { ContactFilter } from '~/contact/models';
import { SubscriptionDictionariesService } from '~/subscription/services/data';
import { ExportService } from '~/@core/modules/exporter/services';
import { ScrollContentService } from '~/layout/service/scroll-content.service';
import { tapResponse } from '@ngrx/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { DateTime } from 'luxon';

export interface ISubscriptionsState
  extends IListState<ISubscription, ISubscriptionFilter> {
  isExporting: boolean;
}

const initialState = (): ISubscriptionsState => ({
  isExporting: false,
  ...initialListState<ISubscription, ISubscriptionFilter>({}),
});

@Injectable()
export class SubscriptionsStore extends ComponentStore<ISubscriptionsState> {
  public readonly loadedList$: Observable<ICollection<ISubscription>> =
    this.select((state) => state.loadedList);

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  public disabledExport$ = this.select(
    (state) =>
      state.isExporting ||
      state.loading ||
      state.loadedList?.total === 0 ||
      state.loadedList?.total > 500,
  );

  public dictionaries$ = (type: string | string[]) =>
    this.dictionariesService.getItemsForType$(type);

  constructor(
    private readonly subscriptionService: SubscriptionHttpService,
    private readonly subscriptionDialogService: SubscriptionDialogService,
    private readonly dictionariesService: SubscriptionDictionariesService,
    private readonly scroller: ScrollContentService,
    private readonly router: Router,
    private readonly exportService: ExportService,
  ) {
    super(initialState());
  }

  public readonly load = this.effect<{
    pagination: IPagination;
    filter: ContactFilter;
  }>((loadParams$) => {
    return loadParams$.pipe(
      tap(({ filter, pagination }) => {
        this.setLoadParams({ filter, pagination });
      }),
      tap(() => this.fetchList()),
      tap(() => this.scroller.scrollToTop()),
    );
  });

  public readonly export = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.filter)),
      tap(() => this.patchState({ isExporting: true })),
      switchMap(([_, filter]) => this.subscriptionService.getAll(filter)),
      map((list) => {
        return list.items.reduce<Array<{ [key: string]: any }>>((acc, item) => {
          const dicVals = item.dictionaryValues.reduce<{
            [key: string]: any;
          }>((dAcc, dItem) => {
            dAcc[dItem.label] = dItem.values.map((i) => i.label).join(', ');
            return dAcc;
          }, {});

          acc.push({
            id: item.id,
            contactIdent: item.owner.identifier,
            contactName: item.owner.name,
            type: item.type.name,
            start: DateTime.fromISO(item.start).toISODate(),
            end: DateTime.fromISO(item.end).toISODate(),
            note: item.note,
            ...dicVals,
            url:
              window.location.origin +
              this.router.serializeUrl(
                this.router.createUrlTree(['subscriptions', item.id]),
              ),
          });
          return acc;
        }, []);
      }),
      switchMap((rows) => fromPromise(this.exportService.export(rows))),
      tap(() => this.patchState({ isExporting: false })),
    );
  });

  public showCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      exhaustMap(() => this.subscriptionDialogService.open()),
      tap(
        (subscription) =>
          subscription &&
          this.router.navigate(['subscriptions', subscription.id]),
      ),
    );
  });

  public readonly showUpdateDialog = this.effect<ISubscription>(
    (subscription$) =>
      subscription$.pipe(
        exhaustMap((subscription) =>
          this.subscriptionDialogService.open({ subscription }).pipe(take(1)),
        ),
        tap((subscription) => subscription && this.updateItem(subscription)),
      ),
  );

  public readonly deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      exhaustMap((id) =>
        this.subscriptionService.delete(id).pipe(
          tapResponse(
            () => this.fetchList(),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  private readonly fetchList = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      withLatestFrom(
        this.select((state) => state.filter),
        this.select((state) => state.pagination),
      ),
      switchMap(([_, filter, pagination]) =>
        this.subscriptionService.getList(filter, pagination).pipe(
          tapResponse(
            (list) => this.setList(list),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  private setLoadParams = this.updater<{
    filter: ISubscriptionFilter;
    pagination: IPagination;
  }>((state, { filter, pagination }) => ({
    ...state,
    filter,
    pagination,
  }));

  private setList = this.updater((state, list: ICollection<ISubscription>) => ({
    ...state,
    loadedList: list,
    loading: false,
  }));

  private updateItem = this.updater<ISubscription>((state, item) => ({
    ...state,
    loadedList: {
      ...state.loadedList,
      items: state.loadedList.items.map((i) => (i.id === item.id ? item : i)),
    },
  }));

  private setLoading() {
    this.patchState({
      loading: true,
    });
  }

  private logError(e: Error) {}
}
