<div class="card grid">
  <div class="col-12 xl:col-3">
    <h1 class="text-700 text-3xl mb-2 pb-2 border-bottom-1 border-gray-400">
      {{ "contact.header.phoneContacts" | translate }}
    </h1>
    <div class="border-dashed border-1 border-gray-400 p-3">{{ phone }}</div>
  </div>
  <div class="col-12 xl:col-9">
    <p-table [value]="(list$ | async)?.items || []" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th class="white-space-nowrap">
            {{ "contact.label.identifier" | translate }}
          </th>
          <th class="white-space-nowrap">
            {{ "contact.label.contactType" | translate }}
          </th>
          <th class="white-space-nowrap">
            {{ "contact.label.name" | translate }}
          </th>
          <th class="white-space-nowrap"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-contact>
        <tr
          [routerLink]="['/contacts/companies', contact.id]"
          class="cursor-pointer hover:bg-black-alpha-10"
        >
          <td>{{ contact?.identifier }}</td>
          <td>{{ contact.type }}</td>
          <td>{{ contact.name }}</td>
          <td class="flex justify-content-end gap-1">
            <app-row-view-button [link]="['/contacts/companies', contact.id]" />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
