import { NgModule } from '@angular/core';
import { RowViewButtonComponent } from './row-view-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [RowViewButtonComponent],
  imports: [SharedExtModule],
  exports: [RowViewButtonComponent],
})
export class RowViewButtonModule {}
