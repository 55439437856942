import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportButtonComponent {
  @Input() public disabled = false;
}
