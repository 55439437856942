import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { exhaustMap, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PeriodHttpService } from '../../../services/http';

import { IPeriod, ISubscription } from '../../../models';
import { PeriodDialogService } from '~/subscription/services/dialogs/period-dialog.service';
import { SubscriptionViewStore } from '~/subscription/pages/subscription-view/subscription-view.store';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface ISubscriptionViewState {
  subscription?: ISubscription;
  periods: IPeriod[];
  loading: boolean;
}

const initialState = (): ISubscriptionViewState => ({
  loading: false,
  periods: [],
});

@Injectable()
export class PeriodsStore extends ComponentStore<ISubscriptionViewState> {
  public periods$ = this.select((state) => state.periods);
  public loading$ = this.select((state) => state.loading);
  public loaded$ = this.select((state) => !state.loading && !!state.periods);

  constructor(
    protected readonly periodService: PeriodHttpService,
    public readonly subStore: SubscriptionViewStore,
    protected readonly periodDialogService: PeriodDialogService,
    protected readonly errorDialogService: ErrorDialogService,
  ) {
    super(initialState());
  }

  public readonly openAddPeriodModal = this.effect<void>((period$) => {
    return period$.pipe(
      withLatestFrom(this.select((state) => state.subscription)),
      exhaustMap(([_, subscription]) =>
        this.periodDialogService.open(subscription as ISubscription),
      ),
      tap((period) => period && this.addPeriod(period)),
      tap((period) => period && this.subStore.loadSubscription()),
    );
  });

  public deletePeriod = this.effect<string>((id$) => {
    return id$.pipe(
      switchMap((id) =>
        this.periodService.delete(id).pipe(tap(() => this.removePeriod(id))),
      ),
      tap(() => this.subStore.loadSubscription()),
      this.errorDialogService.handleHttpError(),
    );
  });

  public readonly loadPeriods = this.effect<string>((id$) => {
    return id$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((id) => this.periodService.getAll({ subscriptionId: id })),
      tap((collection) =>
        this.patchState({
          periods: collection.items,
          loading: false,
        }),
      ),
      this.errorDialogService.handleHttpError(),
    );
  });

  private readonly addPeriod = this.updater((state, item: IPeriod) => ({
    ...state,
    periods: [...state.periods, item],
  }));

  private readonly removePeriod = this.updater((state, id: string) => ({
    ...state,
    periods: state.periods.filter((p) => p.id !== id),
  }));
}
