<p-dropdown
  [options]="(types$ | async) || []"
  [disabled]="(loading$ | async) === true || disabled"
  [showClear]="showClear"
  [placeholder]="placeholder"
  optionLabel="name"
  appendTo="body"
>
  <ng-template let-status pTemplate="item">
    {{ status.name }}
  </ng-template>
</p-dropdown>
