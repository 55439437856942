@if (cs.isBusy$ | async) {
  <app-loading-space></app-loading-space>
} @else {
  @if (cs.items$ | async; as items) {
    @if (items.length > 0) {
      <ul class="list-none p-0 m-0">
        <li *ngFor="let item of items">
          <div
            class="flex gap-3 flex-row align-items-center p-2 border-bottom-1 surface-border"
          >
            <div class="flex-grow-1">{{ addressString(item) }}</div>
            <div class="w-3">{{ item?.note }}</div>
            @if (edit) {
              <div class="flex-grow-0 flex align-items-end gap-1">
                <app-row-edit-button (edit)="cs.showUpdateDialog(item)" />
                <app-row-delete-button (delete)="cs.deleteItem(item.id)" />
              </div>
            }
          </div>
        </li>
      </ul>
      @if (edit) {
        <div class="mt-4 flex justify-content-end">
          <app-new-item-button
            (click)="cs.showCreateDialog()"
            [label]="'contact.button.addAddress' | translate"
          />
        </div>
      }
    } @else {
      <app-empty-space>
        @if (edit) {
          <app-new-item-button
            (click)="cs.showCreateDialog()"
            [label]="'contact.button.addAddress' | translate"
          />
        }
      </app-empty-space>
    }
  }
}
