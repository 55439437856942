import { ModuleWithProviders, NgModule } from '@angular/core';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { BadgeModule } from 'primeng/badge';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { DialogService } from 'primeng/dynamicdialog';

import { SharedModule } from '~/shared.module';
import { SharedExtModule } from '~/shared-ext.module';
import { UserFeatureModule } from '~/user/@feature';
import { DictionaryFeatureModule } from '~/dictionary/@feature';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import {
  ActionsButtonComponent,
  SubscriptionsComponent,
} from './pages/subscriptions';
import { SubscriptionViewComponent } from './pages/subscription-view';
import { SubscriptionCommonModule } from './@common';
import { PeriodsComponent } from './pages/subscription-view/periods/periods.component';
import { AddPeriodComponent } from './components/add-period';
import { SubscriptionRootModule } from './subscription-root.module';
import { PeriodInfoComponent } from './pages/subscription-view/periods/period-info';
import {
  PeriodDialogService,
  PickSubscriptionService,
} from './services/dialogs';
import { SubscriptionImportComponent } from './pages/subscription-import';
import { SubscriptionPickModalComponent } from '~/subscription/components/subscription-pick-modal';

@NgModule({
  providers: [PeriodDialogService, PickSubscriptionService, DialogService],
  declarations: [
    SubscriptionsComponent,
    SubscriptionViewComponent,
    AddPeriodComponent,
    ActionsButtonComponent,
    PeriodsComponent,
    PeriodInfoComponent,
    SubscriptionImportComponent,
    SubscriptionPickModalComponent,
  ],
  imports: [
    SubscriptionCommonModule,
    SharedModule,
    SharedExtModule,
    SubscriptionRoutingModule,
    MultiSelectModule,
    UserFeatureModule,
    BadgeModule,
    TieredMenuModule,
    CardModule,
    TimelineModule,
    ListboxModule,
    DictionaryFeatureModule,
  ],
})
export class SubscriptionModule {
  static forRoot(): ModuleWithProviders<SubscriptionRootModule> {
    return {
      ngModule: SubscriptionRootModule,
    };
  }
}
