import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { User } from '../../models';
import {
  UserCreatePayload,
  UserHttpService,
  UserUpdatePayload,
} from '../../services/http';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface ICreateState {
  user?: User;
  busy: boolean;
}

const initialState = (user?: User): ICreateState => ({
  user: user,
  busy: false,
});

@Injectable()
export class UserEditStore extends ComponentStore<ICreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  private user$ = this.select((state) => state.user);

  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly ref: DynamicDialogRef,
    private readonly errorDialogService: ErrorDialogService,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data?.user));
  }

  public readonly createUser = this.effect<UserCreatePayload>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.user$),
      switchMap(([data, subscription]) => this.userHttpService.create(data)),
      tap(() => this.patchState({ busy: false })),
      tap((user) => this.ref.close(user)),
      this.errorDialogService.handleHttpError(),
    );
  });

  public readonly updateUser = this.effect<UserUpdatePayload>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.user$),
      switchMap(([data, user]) =>
        this.userHttpService.update(user?.id as string, data),
      ),
      tap(() => this.patchState({ busy: false })),
      tap((user) => this.ref.close(user)),
      this.errorDialogService.handleHttpError(),
    );
  });

  public emailIsUnique(email: string) {
    return this.userHttpService
      .getList({ email }, { page: 1, perPage: 1 })
      .pipe(
        withLatestFrom(this.select((state) => state.user)),
        map(([users, user]) => {
          return (
            users.items.length === 0 ||
            (!!user && users.total === 1 && users.items[0].id === user.id)
          );
        }),
      );
  }

  public cancel(): void {
    this.ref.close();
  }
}
