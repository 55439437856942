import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPeriod } from '../../models';
import { HttpClient } from '@angular/common/http';
import { ICollection, IPagination } from '~/@core/models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';
import { IPeriodFilter, IPeriodPayload } from './period.payloads';

@Injectable({
  providedIn: 'root',
})
export class PeriodHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    IPeriodFilter,
    IPeriod
  >(this.getList.bind(this));

  constructor(private readonly http: HttpClient) {}

  public getList(filter?: IPeriodFilter, pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<IPeriod>>('/api/subscription-periods', {
      params: {
        ...filter,
        ...paginationQuery,
      },
    });
  }

  public getAll(filter?: IPeriodFilter) {
    return this.getAllItems(filter);
  }

  public getOne(id: string): Observable<IPeriod> {
    return this.http.get<IPeriod>(`/api/subscription-periods/${id}`);
  }

  public create(data: IPeriodPayload): Observable<IPeriod> {
    return this.http.post<IPeriod>('/api/subscription-periods/', data);
  }

  public update(id: string, data: IPeriodPayload): Observable<IPeriod> {
    return this.http.put<IPeriod>(`/api/subscription-periods/${id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/subscription-periods/${id}`);
  }
}
