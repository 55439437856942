import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-import-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-file-import"
      class="p-button-outlined"
      [pTooltip]="'common.label.import' | translate"
      [tooltipPosition]="'top'"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportButtonComponent {}
