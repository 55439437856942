@if (cs.isBusy$ | async) {
  <app-loading-space></app-loading-space>
} @else {
  @if (cs.phones$ | async; as phones) {
    @if (phones.length > 0) {
      <ul class="list-none p-0 m-0">
        <li *ngFor="let item of cs.phones$ | async">
          <app-phone-item
            *ngIf="cs.isItemInViewMode$(item.number) | async"
            [item]="item"
            [edit]="allowEdit"
            (update)="cs.setItemToEditMode(item.number)"
            (delete)="cs.deleteItem(item.number)"
          ></app-phone-item>
          <app-phone-edit
            *ngIf="cs.isItemInEditMode$(item.number) | async"
            [item]="item"
            (save)="cs.updateItem({ number: item.number, payload: $event })"
            (cancel)="cs.setItemToViewMode(item.number)"
          ></app-phone-edit>
        </li>
      </ul>
      @if (allowEdit) {
        <div class="flex justify-content-end mt-4">
          <app-new-item-button
            [label]="'contact.button.addPhone' | translate"
            (click)="cs.showCreateDialog()"
          />
        </div>
      }
    } @else {
      <app-empty-space>
        @if (allowEdit) {
          <app-new-item-button
            [label]="'contact.button.addPhone' | translate"
            (click)="cs.showCreateDialog()"
          />
        }
      </app-empty-space>
    }
  }
}
