import { NgModule } from '@angular/core';
import { InfoRowComponent } from '~/@widgets/info-row/info-row.component';
import { SharedExtModule } from '~/shared-ext.module';
import { ChipModule } from 'primeng/chip';

@NgModule({
  declarations: [InfoRowComponent],
  imports: [SharedExtModule, ChipModule],
  exports: [InfoRowComponent],
})
export class InfoRowModule {}
