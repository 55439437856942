import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubscriptionsComponent } from './pages/subscriptions';
import { SubscriptionViewComponent } from './pages/subscription-view';
import { SubscriptionImportComponent } from './pages/subscription-import';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: SubscriptionsComponent,
        pathMatch: 'full',
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./modules/configuration').then((m) => m.ConfigurationModule),
      },
      {
        path: 'import',
        component: SubscriptionImportComponent,
        pathMatch: 'full',
      },
      {
        path: ':id',
        component: SubscriptionViewComponent,
      },
    ]),
  ],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
