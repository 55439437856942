import {
  ComponentStore,
  OnStoreInit,
  tapResponse,
} from '@ngrx/component-store';

import { SubscriptionHttpService } from '../../services/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { exhaustMap, filter, take, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ISubscription } from '../../models';
import { SubscriptionDialogService } from '../../@common/services/dialogs/subscription-dialog.service';

export interface ISubscriptionViewState {
  subscription?: ISubscription;
  loading: boolean;
}

const initialState = (): ISubscriptionViewState => ({
  loading: false,
});

@Injectable()
export class SubscriptionViewStore
  extends ComponentStore<ISubscriptionViewState>
  implements OnStoreInit
{
  public subscription$ = this.select((state) => state.subscription);
  public loading$ = this.select((state) => state.loading);
  public loaded$ = this.select(
    (state) => !state.loading && !!state.subscription,
  );

  constructor(
    protected readonly subscriptionService: SubscriptionHttpService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly dialogService: DialogService,
    protected readonly subscriptionDialogService: SubscriptionDialogService,
  ) {
    super(initialState());
  }

  public ngrxOnStoreInit(): void {
    this.loadSubscription();
  }

  public readonly deleteSubscription = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      withLatestFrom(
        this.select((state) => state.subscription),
        (trigger, sub) => sub,
      ),
      filter((sub) => !!sub),
      switchMap((sub) =>
        this.subscriptionService.delete(sub!.id).pipe(
          tapResponse(
            () => this.router.navigate(['/subscriptions']),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  public readonly openUpdateSubscriptionModal = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(
        this.subscription$,
        (trigger, subscription) => subscription,
      ),
      filter((subscription) => !!subscription),
      exhaustMap((subscription) =>
        this.subscriptionDialogService.open({ subscription }).pipe(take(1)),
      ),
      tap((sub) => sub && this.patchState({ subscription: sub })),
    ),
  );

  public readonly loadSubscription = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(() =>
        this.subscriptionService
          .getOne(this.activatedRoute.snapshot.params['id'])
          .pipe(
            tapResponse(
              (subscription) => {
                this.patchState({ subscription, loading: false });
              },
              (e: HttpErrorResponse) => this.logError(e),
            ),
          ),
      ),
    );
  });

  protected logError(e: Error) {}
}
