<div class="layout-footer">
  <div class="flex gap-1">
    @if (footerWidgets && footerWidgets.length > 0) {
      @for (widget of footerWidgets; track widget.widgetId) {
        <app-dynamic-widget [widget]="widget"></app-dynamic-widget>
      }
    }
    <app-config></app-config>
  </div>
  <div class="flex gap-2 align-items-center">
    <div class="text-700 font-bold">ST CRM</div>
  </div>
</div>
