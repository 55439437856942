import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActivityType } from '../../../models';
import {
  addActivityType,
  deleteActivityType,
  fetchActivityTypes,
  fetchActivityTypesFail,
  fetchActivityTypesSuccess,
  updateActivityType,
} from './activity-type.actions';

export const activityTypeEntityAdapter = createEntityAdapter<ActivityType>();

export interface ActivityTypeState extends EntityState<ActivityType> {
  loading: boolean;
  lastLoadedTime?: Date;
}

const createActivityTypeInitState = (): ActivityTypeState =>
  activityTypeEntityAdapter.getInitialState({
    loading: false,
  });

export const activityTypeReducer = createReducer<ActivityTypeState>(
  createActivityTypeInitState(),
  on(
    fetchActivityTypes,
    (state): ActivityTypeState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    fetchActivityTypesSuccess,
    (state, { items }): ActivityTypeState =>
      activityTypeEntityAdapter.setAll(items, {
        ...state,
        loading: false,
        lastLoadedTime: new Date(),
      }),
  ),
  on(
    fetchActivityTypesFail,
    (state): ActivityTypeState => ({
      ...state,
      loading: false,
      lastLoadedTime: undefined,
    }),
  ),
  on(
    addActivityType,
    (state, { item }): ActivityTypeState =>
      activityTypeEntityAdapter.addOne(item, state),
  ),
  on(
    updateActivityType,
    (state, { item }): ActivityTypeState =>
      activityTypeEntityAdapter.updateOne(
        {
          id: item.id,
          changes: item,
        },
        state,
      ),
  ),
  on(
    deleteActivityType,
    (state, { id }): ActivityTypeState =>
      activityTypeEntityAdapter.removeOne(id, state),
  ),
);
