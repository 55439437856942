import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICollection, IPagination } from '~/@core/models';

import { ActivityType } from '../../models';
import { getAllItemsFactory } from '~/@core/utils';
import { ActivityTypeData } from './activity-type.payloads';

@Injectable({
  providedIn: 'root',
})
export class ActivityTypeHttpService {
  private readonly getAllItems = getAllItemsFactory<ActivityType>(
    this.getList.bind(this),
  );

  constructor(private readonly http: HttpClient) {}

  public create(linkType: ActivityTypeData) {
    return this.http.post<ActivityType>('/api/activity-types', linkType);
  }

  public getList(pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<ActivityType>>('/api/activity-types', {
      params: {
        ...paginationQuery,
      },
    });
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(id: string, linkType: ActivityTypeData) {
    return this.http.put<ActivityType>(`/api/activity-types/${id}`, linkType);
  }

  public delete(id: string) {
    return this.http.delete<void>(`/api/activity-types/${id}`);
  }
}
