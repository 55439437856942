import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ConfigValue, ICollection, IPagination } from '../../models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';

@Injectable({
  providedIn: 'root',
})
export class ConfigValueHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    { group: string },
    ConfigValue
  >(this.getList.bind(this));

  constructor(protected readonly http: HttpClient) {}

  public getList(
    filter?: { group: string },
    pagination?: IPagination,
  ): Observable<ICollection<ConfigValue>> {
    return this.http.get<ICollection<ConfigValue>>(
      '/api/configuration-values',
      {
        params: {
          ...filter,
          ...pagination,
        },
      },
    );
  }

  public getAll(filter?: { group: string }) {
    return this.getAllItems(filter);
  }

  public getGlobal(
    pagination?: IPagination,
  ): Observable<ICollection<ConfigValue>> {
    return this.http.get<ICollection<ConfigValue>>(
      '/api/global-configuration-values',
    );
  }

  public update(id: string, value: string): Observable<ConfigValue> {
    return this.http.put<ConfigValue>(`/api/configuration-values/${id}`, {
      value: value,
    });
  }
}
