import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ContactEmailHttpService } from '../../services/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactEmailCreate } from '~/contact/modules/email/services/http/contact-email.payloads';

export interface IEmailCreateState {
  contactId: string;
  busy: boolean;
}

const initialState = (contactId: string): IEmailCreateState => ({
  contactId,
  busy: false,
});

@Injectable()
export class PersonEditStore extends ComponentStore<IEmailCreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly contactPhoneService: ContactEmailHttpService,
    readonly dialogConfig: DynamicDialogConfig,
  ) {
    super(initialState(dialogConfig.data?.contactId));
  }

  public readonly saveEmail = this.effect<ContactEmailCreate>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setBusy(true)),
      withLatestFrom(this.select((state) => state.contactId)),
      switchMap(([data, contactId]) => this.createEmail(contactId, data)),
    );
  });

  private setBusy(busy: boolean) {
    this.setState(
      (state): IEmailCreateState => ({
        ...state,
        busy: busy,
      }),
    );
  }

  private createEmail(contactId: string, data: ContactEmailCreate) {
    return this.contactPhoneService.create(contactId, data).pipe(
      tap((company) => {
        this.dialogRef.close(company);
      }),
    );
  }
}
