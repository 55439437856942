import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactEmail } from '../../models';
import {
  ContactEmailCreate,
  ContactEmailUpdate,
} from './contact-email.payloads';

@Injectable({
  providedIn: 'root',
})
export class ContactEmailHttpService {
  constructor(protected http: HttpClient) {}

  public getAll(contactId: string): Observable<ContactEmail[]> {
    return this.http.get<ContactEmail[]>(`/api/contacts/${contactId}/emails`);
  }

  public create(
    contactId: string,
    data: ContactEmailCreate,
  ): Observable<ContactEmail> {
    return this.http.post<ContactEmail>(`/api/contacts/${contactId}/emails`, {
      ...data,
    });
  }

  public update(
    contactId: string,
    number: string,
    data: ContactEmailUpdate,
  ): Observable<ContactEmail> {
    return this.http.put<ContactEmail>(
      `/api/contacts/${contactId}/emails/${number}`,
      {
        ...data,
      },
    );
  }

  public delete(contactId: string, number: string): Observable<void> {
    return this.http.delete<void>(
      `/api/contacts/${contactId}/emails/${number}`,
    );
  }
}
