import { createAction, props } from '@ngrx/store';

import { ILinkTypeData } from '~/contact/services/http';
import { LinkType } from '~/contact/models/link-type';

export const fetchLinkTypes = createAction('[Links] Fetch link types');
export const fetchLinkTypesSuccess = createAction(
  '[Links] Fetch link types success',
  props<{ items: LinkType[] }>(),
);
export const fetchLinkTypesFail = createAction(
  '[Links] Fetch link types fail',
  props<{ error: Error }>(),
);

export const createLinkType = createAction(
  '[Links] Create link type',
  props<{ data: ILinkTypeData }>(),
);
export const createLinkTypeSuccess = createAction(
  '[Links] Create link type success',
  props<{ item: LinkType }>(),
);
export const createLinkTypeFail = createAction(
  '[Links] Create link type fail',
  props<{ error: Error }>(),
);

export const updateLinkType = createAction(
  '[Links] Update link type',
  props<{ id: string; data: ILinkTypeData }>(),
);
export const updateLinkTypeSuccess = createAction(
  '[Links] Update link type success',
  props<{ item: LinkType }>(),
);
export const updateLinkTypeFail = createAction(
  '[Links] Update link type fail',
  props<{ error: Error }>(),
);

export const deleteLinkType = createAction(
  '[Links] Delete link type',
  props<{ id: string }>(),
);
export const deleteLinkTypeSuccess = createAction(
  '[Links] Delete link type success',
  props<{ id: string }>(),
);
export const deleteLinkTypeFail = createAction(
  '[Links] Delete link type fail',
  props<{ error: Error }>(),
);
