import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-data-dialog-footer',
  template: `
    <app-cancel-button (click)="ref.close()"></app-cancel-button>
    <app-save-button
      (click)="!disabled && this.saveButtonClicked.next()"
      [disabled]="disabled"
    ></app-save-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDataDialogFooterComponent implements OnDestroy {
  protected disabled: boolean = true;

  protected saveButtonClicked: Subject<void>;

  private subs = new Subscription();

  constructor(
    protected readonly ref: DynamicDialogRef,
    readonly config: DynamicDialogConfig,
    readonly cdRef: ChangeDetectorRef,
  ) {
    this.saveButtonClicked = this.config.data.footerSubmit;
    this.subs.add(
      this.config.data.formBlocked.subscribe((blocked: boolean) => {
        this.disabled = blocked;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
