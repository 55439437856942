export enum GlobalConfigIds {
  Language = 'tenant:i18n:language',
  TimeZone = 'tenant:i18n:timezone',
  CaptchaSiteKey = 'env:captcha:siteKey',
}

export enum ConfigValueType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
}

export type ConfigValue = {
  id: string;
  group: string;
  name: string;
  description: string;
  value: string;
  type: ConfigValueType;
};
