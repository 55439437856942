import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { Store } from '@ngrx/store';
import {
  selectActivityTypes,
  selectActivityTypesLoading,
} from '~/activity/@root/store/activity-type/activity-type.selectors';
import { ActivityType } from '~/activity/models';
import { checkActivityTypes } from '~/activity/@root/store/activity-type/activity-type.actions';

@Component({
  selector: 'app-activity-type-dropdown',
  templateUrl: './activity-type-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityTypeDropdownComponent),
      multi: true,
    },
  ],
})
export class ActivityTypeDropdownComponent implements ControlValueAccessor {
  @ViewChild(Dropdown, { static: true })
  protected dropdown!: Dropdown;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public showClear = false;

  @Input()
  public placeholder?: string;

  protected types$: Observable<ActivityType[]> =
    this.store.select(selectActivityTypes);

  protected loading$ = this.store.select(selectActivityTypesLoading);

  protected disabled = false;

  constructor(
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.store.dispatch(checkActivityTypes());
  }

  public writeValue(val: any): void {
    this.dropdown.writeValue({
      ...val,
    });
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }
}
