<div class="card" [formGroup]="form" (ngSubmit)="createDictionary()">
  <div class="flex flex-wrap align-items-center justify-content-between gap-3">
    <h3 class="text-900 text-xl font-boldblock">
      {{ "dictionary.header.createDictionary" | translate }}
    </h3>
    <div class="flex flex-wrap align-items-center gap-2">
      <button
        pButton
        pRipple
        (click)="createDictionary()"
        [disabled]="form.invalid"
        icon="pi pi-check"
        class="p-button-text p-button-rounded p-button-success"
      ></button>
      <button
        pButton
        pRipple
        (click)="cancel()"
        icon="pi pi-times"
        class="p-button-text p-button-rounded p-button-warning"
      ></button>
    </div>
  </div>

  <div class="grid formgrid p-fluid mt-4">
    <div class="field col-12">
      <label for="name" class="font-medium text-900">{{
        "dictionary.label.name" | translate
      }}</label>
      <input formControlName="name" id="name" type="text" pInputText />
      @if (form.get("name"); as control) {
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="minlength"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>
  </div>
</div>
