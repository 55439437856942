import { Company } from '../../models';
import { CompanyHttpService } from '../../services/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  AbstractContactViewStore,
  IContactViewState,
} from '../abstract-contact-view.store';
import { CompanyDialogService } from '~/contact/services/dialogs';
import { exhaustMap, take, tap, withLatestFrom } from 'rxjs';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface ICompanyViewState extends IContactViewState<Company> {
  contact?: Company;
  contactLoading: boolean;
}

const initialState = (): ICompanyViewState => ({
  contactLoading: false,
});

@Injectable()
export class CompanyViewStore extends AbstractContactViewStore<
  Company,
  ICompanyViewState
> {
  public showUpdateDialog = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.contact$, (_, company) => company),
      exhaustMap((company) =>
        this.companyDialogService.open({ company }).pipe(take(1)),
      ),
      tap((company) => company && this.setContact(company)),
    ),
  );

  constructor(
    protected readonly companyService: CompanyHttpService,
    protected override readonly errorDialogService: ErrorDialogService,
    protected readonly router: Router,
    protected readonly companyDialogService: CompanyDialogService,
  ) {
    super(companyService, errorDialogService, initialState());
  }

  protected override redirectAfterDelete() {
    void this.router.navigate(['/contacts/companies']);
  }
}
