import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewStore } from './view.store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dictionary-view',
  templateUrl: './view.component.html',
  providers: [ViewStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewComponent {
  protected canManageDictionary$ = this.store.select(
    selectUserHasRole(['admin']),
  );

  public dictionary$ = this.cs.dictionary$;

  constructor(
    protected readonly cs: ViewStore,
    protected readonly store: Store,
  ) {}
}
