import { NgModule } from '@angular/core';

import { HookWidgetsDirective } from './directives';
import { DynamicWidgetComponent } from './components/dynamic-widget';

@NgModule({
  declarations: [HookWidgetsDirective, DynamicWidgetComponent],
  exports: [HookWidgetsDirective, DynamicWidgetComponent],
})
export class HooksModule {}
