import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ActionPayload,
  ActivityCommentHttpService,
} from 'src/app/activity/services/http';
import { Activity, ActivityComment } from '~/activity/models';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

interface IEditState {
  comment?: ActivityComment;
  activity: Activity;
  busy: boolean;
}

const initialState = (
  activity: Activity,
  comment?: ActivityComment,
): IEditState => ({
  comment: comment,
  activity: activity,
  busy: false,
});

@Injectable()
export class ActivityEditDialogStore extends ComponentStore<IEditState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly actionHttpService: ActivityCommentHttpService,
    private readonly ref: DynamicDialogRef,
    private readonly errorDialogService: ErrorDialogService,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data.activity, config.data?.comment));
  }

  public readonly submit = this.effect<Omit<ActionPayload, 'activityId'>>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.patchState({ busy: true })),
        withLatestFrom(this.state$),
        switchMap(([data, state]) =>
          state.comment
            ? this.actionHttpService
                .update(state.comment.id, {
                  activityId: state.activity.id,
                  ...data,
                })
                .pipe(
                  tap((action) => this.ref.close(action)),
                  this.errorDialogService.handleHttpError(),
                )
            : this.actionHttpService
                .create({
                  activityId: state.activity.id,
                  ...data,
                })
                .pipe(
                  tap((action) => this.ref.close(action)),
                  this.errorDialogService.handleHttpError(),
                ),
        ),
        tap(() => this.patchState({ busy: false })),
      );
    },
  );
}
