import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UsersListComponent } from './pages/users-list';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: '', component: UsersListComponent }]),
  ],
  exports: [RouterModule],
})
export class UserRoutingModule {}
