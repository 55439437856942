import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ConfirmDialogService } from '~/@core/modules/confirm';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card-delete-button',
  template: `
    <button
      pButton
      pRipple
      [disabled]="disabled"
      icon="pi pi-trash"
      class="p-button-text p-button-rounded p-button-danger"
      (click)="confirmDelete()"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDeleteButtonComponent implements OnDestroy {
  @Input() public disabled = false;

  private subs = new Subscription();

  constructor(private readonly confirmDialogService: ConfirmDialogService) {}

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Output() protected delete = new EventEmitter<void>();

  protected confirmDelete(): void {
    if (this.disabled) {
      return;
    }

    const delSub = this.confirmDialogService.open().subscribe((result) => {
      if (result) {
        this.delete.emit();
      }
    });
    this.subs.add(delSub);
  }
}
