import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextButtonComponent } from './next-button.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NextButtonComponent],
  imports: [CommonModule, ButtonModule, TranslateModule],
  exports: [NextButtonComponent],
})
export class NextButtonModule {}
