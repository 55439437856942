import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Company } from '~/contact/models';

@Component({
  selector: 'app-company-data-dialog-header',
  template: `<div class="p-dialog-title">
    @if (company) {
      {{ 'contact.header.editCompany' | translate }}: {{ company.shortName }}
      <div class="text-xs text-500 mt-1">{{ company.id }}</div>
    } @else {
      {{ 'contact.header.addCompany' | translate }}
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDataDialogHeaderComponent {
  protected company?: Company;

  constructor(config: DynamicDialogConfig) {
    this.company = config.data?.company;
  }
}
