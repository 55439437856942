import { Component, Input } from '@angular/core';
import { Activity } from '~/activity/models';

@Component({
  selector: 'app-activity-row-expand',
  templateUrl: './activity-row-expand.component.html',
})
export class ActivityRowExpandComponent {
  @Input() activity!: Activity;
}
