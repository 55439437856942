import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CompanyDataDialogStore } from './company-data-dialog.store';
import { provideComponentStore } from '@ngrx/component-store';
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Company,
  ConfigurationValuesEnum,
  ContactDictionary,
  ContactDictionaryValue,
} from '../../models';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-company-data-dialog',
  templateUrl: './company-data-dialog.component.html',
  providers: [provideComponentStore(CompanyDataDialogStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDataDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  private subs = new Subscription();

  private dictionaries: ContactDictionary[] = [];

  private uniqueIdentValidator: AsyncValidatorFn = (control) => {
    return this.cs
      .identIsUnique(control.value)
      .pipe(map((unique) => (unique ? null : { uniqueIdent: true })));
  };

  constructor(
    protected readonly cs: CompanyDataDialogStore,
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
  ) {
    this.form = this.buildForm(config.data?.company);
  }

  public ngOnInit() {
    this.createDictionaryFieldsSub();
    this.createIdentValidatorsSub();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public close() {
    this.ref.close();
  }

  public save() {
    this.cs.submitCompanyData({
      identifier: this.form.value.identifier,
      shortName: this.form.value.shortName,
      longName: this.form.value.longName,
      note: this.form.value.note,
      dictionaryValues: this.dictionaryValuesPayload(),
    });
  }

  private buildForm(data?: Company) {
    return new FormGroup({
      identifier: new FormControl(data?.identifier || ''),
      shortName: new FormControl(data?.shortName || '', [Validators.required]),
      longName: new FormControl(data?.longName || ''),
      note: new FormControl(data?.note || ''),
    });
  }

  private createDictionaryFieldsSub() {
    const company = this.config.data?.company;
    const dictionariesSub = this.cs.dictionaries$.subscribe((dictionaries) => {
      if (!dictionaries) {
        return;
      }

      for (let dictionary of dictionaries) {
        const dictionaryValue = company
          ? (company as Company)?.dictionaryValues?.find(
              (v: ContactDictionaryValue) => v.dictionaryId === dictionary.id,
            )
          : null;

        const valueItems = dictionaryValue?.values.map((i) => i.id);
        const formValue = dictionary.multiple ? valueItems : valueItems?.[0];

        this.form.addControl(
          this.dictionaryFormKey(dictionary.id),
          new FormControl(formValue),
        );
      }
      this.dictionaries = dictionaries;
    });
    this.subs.add(dictionariesSub);
  }

  protected dictionaryFormKey(dictionaryId: string) {
    return `dictionaryValues.${dictionaryId}`;
  }

  private dictionaryValuesPayload() {
    return this.dictionaries
      .map((d) => {
        const formVal = this.form.value[this.dictionaryFormKey(d.id)];
        if (!formVal) {
          return undefined;
        }
        return {
          dictionaryId: d.id,
          valueIds: d.multiple ? formVal : [formVal],
        };
      })
      .filter((v) => !!v) as { valueIds: string[]; dictionaryId: string }[];
  }

  private createIdentValidatorsSub() {
    const sub = this.cs.identConfiguration$.subscribe((identValidationConf) => {
      const control = this.form.get('identifier');
      if (!control) {
        return;
      }

      const validators: ValidatorFn[] = [];
      const asyncValidators: AsyncValidatorFn[] = [];

      const required =
        identValidationConf.get(
          ConfigurationValuesEnum.companyIdentRequired,
        ) === 'true';
      if (required) {
        validators.push(Validators.required);
      }

      const regex = identValidationConf.get(
        ConfigurationValuesEnum.companyIdentRegex,
      );
      if (regex) {
        validators.push(Validators.pattern(regex));
      }

      const unique =
        identValidationConf.get(ConfigurationValuesEnum.companyIdentUnique) ===
        'true';
      if (unique) {
        asyncValidators.push(this.uniqueIdentValidator);
      }

      control.setValidators(validators);
      control.setAsyncValidators(asyncValidators);
      control.updateValueAndValidity();
    });
    this.subs.add(sub);
  }
}
