import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  template: `
    <button
      pButton
      type="button"
      class="p-button-outlined"
      icon="pi pi-times"
      [disabled]="disabled"
      [label]="'widgets.buttons.cancel' | translate"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelButtonComponent {
  @Input() public disabled = false;
}
