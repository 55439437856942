import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly primengConfig: PrimeNGConfig,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.primengConfig.ripple = true;
    const defaultLang = this.translateService.getDefaultLang();

    this.translateService
      .get('primeng')
      .pipe(take(1))
      .subscribe((res) => this.primengConfig.setTranslation(res[defaultLang]));

    this.translateService.onLangChange.subscribe((event) => {
      this.primengConfig.setTranslation(event.translations.primeng);
    });
  }
}
