import { NgModule } from '@angular/core';

import { HooksModule } from '~/hook';
import { ExporterModule } from '~/@core/modules/exporter';
import { ImporterModule } from '~/@core/modules/importer';

import { TableFilterModule } from './@widgets/table-view/table-filter';
import { TableHeaderModule } from './@widgets/table-view/table-header';
import { EmptySpaceModule } from './@widgets/placeholders/empty-space';
import { LoadingSpaceModule } from './@widgets/placeholders/loading-space';
import { SaveButtonModule } from './@widgets/buttons/save-button';
import { CancelButtonModule } from './@widgets/buttons/cancel-button';
import { NewItemButtonModule } from './@widgets/table-view/new-item-button';
import { NextButtonModule } from './@widgets/buttons/next-button';
import { PrevButtonModule } from './@widgets/buttons/prev-button';
import { InfoRowModule } from './@widgets/info-row';
import { RowDeleteButtonModule } from '~/@widgets/table-view/row-delete-button';
import { RowViewButtonModule } from '~/@widgets/table-view/row-view-button';
import { RowEditButtonModule } from '~/@widgets/table-view/row-edit-button';
import { ValidationErrorsModule } from '~/@widgets/form/validation-errors';
import { EditorModule } from '~/@widgets/form/editor/editor.module';
import { CardEditButtonModule } from '~/@widgets/card-buttons/card-edit-button';
import { CardDeleteButtonModule } from '~/@widgets/card-buttons/card-delete-button';
import { RowCancelButtonModule } from '~/@widgets/table-view/row-cancel-button';
import { RowConfirmButtonModule } from '~/@widgets/table-view/row-confirm-button';

const widgets = [
  TableFilterModule,
  TableHeaderModule,
  EmptySpaceModule,
  LoadingSpaceModule,
  SaveButtonModule,
  CancelButtonModule,
  NewItemButtonModule,
  NextButtonModule,
  PrevButtonModule,
  InfoRowModule,
  RowViewButtonModule,
  RowEditButtonModule,
  RowDeleteButtonModule,
  ValidationErrorsModule,
  EditorModule,
  CardEditButtonModule,
  CardDeleteButtonModule,
  RowCancelButtonModule,
  RowConfirmButtonModule,
];

const common = [ImporterModule, ExporterModule, HooksModule];

@NgModule({
  imports: [...common, ...widgets],
  exports: [...common, ...widgets],
})
export class SharedModule {}
