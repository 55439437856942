@if (data && mapping) {
  <p-table [value]="data" [paginator]="true" [rows]="100">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let mapEntry of mapping.entries()">
          <div>
            {{ getColumnConfigName(mapEntry[1]) | translate }}
          </div>
          <div class="p-text-secondary">
            {{ getColumnDataName(mapEntry[0]) }}
          </div>
        </th>
        <th *ngIf="result"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
      <tr>
        @for (mapData of mapping.keys(); track $index) {
          <td>
            {{ row[mapData] }}
          </td>
        }
        @if (result) {
          <td>
            @if (result[rowIndex]; as rowResult) {
              <div class="flex justify-content-end gap-2">
                @if (rowResult?.resultLink) {
                  <a
                    pButton
                    pRipple
                    icon="pi pi-link"
                    class="p-button-success h-2rem w-2rem"
                    target="_blank"
                    [href]="rowResult.resultLink"
                  ></a>
                }
                @if (rowResult?.errors && rowResult.errors.length > 0) {
                  <app-result-errors [result]="rowResult"></app-result-errors>
                }
              </div>
            }
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
}
