<form [formGroup]="form" (ngSubmit)="save()">
  <div class="grid p-fluid formgrid">
    @if (form.get("identifier"); as control) {
      <div class="col-12 md:col-6 field">
        <label for="identifier" class="text-900 font-semibold">{{
          "contact.label.identifier" | translate
        }}</label>

        <input
          id="identifier"
          type="text"
          pInputText
          [formControl]="$any(control)"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="pattern"
            [message]="'contact.validation.nonValidIdentifier' | translate"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="uniqueIdent"
            [message]="'contact.validation.identifierAlreadyExist' | translate"
          />
        </app-validation-errors>
      </div>
    }

    @if (form.get("firstName"); as control) {
      <div class="col-12 md:col-6 field">
        <label for="firstName" class="text-900 font-semibold">{{
          "contact.label.firstName" | translate
        }}</label>
        <input
          id="firstName"
          type="text"
          pInputText
          [formControl]="$any(control)"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }

    <div class="col-6 field">
      <label for="middleName" class="text-900 font-semibold">{{
        "contact.label.middleName" | translate
      }}</label>
      <input
        id="middleName"
        type="text"
        pInputText
        formControlName="middleName"
      />
    </div>

    <div class="col-6 field">
      <label for="lastName" class="text-900 font-semibold">{{
        "contact.label.lastName" | translate
      }}</label>
      <input id="lastName" type="text" pInputText formControlName="lastName" />
    </div>
    <div class="col-12 field">
      <label for="note" class="text-900 font-semibold">{{
        "contact.label.note" | translate
      }}</label>
      <app-editor
        id="note"
        formControlName="note"
        [style]="{ height: '150px' }"
      ></app-editor>
    </div>

    @for (dictionary of cs.dictionaries$ | async; track dictionary.id) {
      <div class="col-12 field">
        <label class="text-900 font-semibold">{{ dictionary.name }}</label>
        @if (dictionary.multiple) {
          <p-multiSelect
            [formControlName]="dictionaryFormKey(dictionary.id)"
            [options]="
              (cs.dictionaryItems$(dictionary.dictionary.id) | async) || []
            "
            [showClear]="true"
            optionLabel="value"
            optionValue="id"
            appendTo="body"
          ></p-multiSelect>
        } @else {
          <p-dropdown
            [formControlName]="dictionaryFormKey(dictionary.id)"
            [showClear]="true"
            [options]="
              (cs.dictionaryItems$(dictionary.dictionary.id) | async) || []
            "
            optionLabel="value"
            optionValue="id"
            appendTo="body"
          ></p-dropdown>
        }
      </div>
    }
  </div>
  <div class="flex gap-2 justify-content-end">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="form.invalid"></app-save-button>
  </div>
</form>
