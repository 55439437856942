import { NgModule } from '@angular/core';

import { SharedModule } from '~/shared.module';
import { SharedExtModule } from '~/shared-ext.module';

import { PhoneCreateComponent } from './components/phone-create';
import { PhonesListComponent } from './components/phones-list';
import { PhoneItemComponent } from './components/phone-item/phone-item.component';
import { PhoneEditComponent } from './components/phone-edit/phone-edit.component';
import { AddPhoneDialogService } from '~/contact/modules/phone/services/dialogs';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [
    PhoneCreateComponent,
    PhonesListComponent,
    PhoneItemComponent,
    PhoneEditComponent,
  ],
  providers: [AddPhoneDialogService],
  imports: [SharedModule, SharedExtModule, InputMaskModule],
  exports: [PhoneCreateComponent, PhonesListComponent],
})
export class PhoneModule {}
