import { NgModule } from '@angular/core';

import { SharedExtModule } from '~/shared-ext.module';

import { ErrorDialogService } from './error-dialog.service';
import { ErrorDialogComponent } from './error-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { InplaceModule } from 'primeng/inplace';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [ErrorDialogComponent],
  providers: [ErrorDialogService, DialogService],
  imports: [SharedExtModule, InplaceModule, MessagesModule, PanelModule],
})
export class ErrorDialogModule {}
