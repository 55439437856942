@if (cs.loaded$) {
  @if (cs.subscription$ | async; as subscription) {
    <div class="grid">
      <div class="col-12 md:col-5">
        <div class="card">
          <div class="card-header pb-2 border-bottom-1 border-gray-300">
            <div class="flex justify-content-between align-items-center gap-3">
              <h1 class="card-title text-2xl m-0">
                <span class="text-2xl">{{ subscription.type.name }}</span
                ><br />
                <span class="text-700 font-semibold mb-2 text-sm">
                  {{ subscription?.start | date: "y-MM-dd" }} -
                  {{ subscription?.end | date: "y-MM-dd" }}
                </span>
              </h1>
              <div class="flex align-items-end gap-2">
                <app-card-edit-button
                  (edit)="cs.openUpdateSubscriptionModal()"
                />
                <app-card-delete-button
                  (delete)="cs.deleteSubscription()"
                  [disabled]="(canDelete$ | async) === false"
                />
              </div>
            </div>
          </div>
          <div class="card-body py-2">
            @if (subscription.note) {
              <div class="text-600 font-semibold mb-2">
                {{ "subscription.label.note" | translate }}
              </div>
              <div class="border-2 border-dashed surface-border p-2 mb-4">
                <div [innerHtml]="subscription.note"></div>
              </div>
            }

            @if (subscription.dictionaryValues.length > 0) {
              <div class="flex flex-column gap-2">
                @for (dValue of subscription.dictionaryValues; track $index) {
                  <app-dictionary-info-row
                    [dictionaryValue]="dValue"
                  ></app-dictionary-info-row>
                }
              </div>
            }
          </div>
        </div>

        @if (subscription.owner) {
          <app-contact-card
            [contactId]="subscription.owner.id"
          ></app-contact-card>
        }
      </div>
      <div class="col-12 md:col-7">
        <div class="mb-4">
          <app-periods [subscription]="subscription"></app-periods>
        </div>
      </div>
    </div>
  }
}
