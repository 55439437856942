import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IDictionaryPayload } from '../../../models';
import { DictionaryHttpService } from '../../../http';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DictionariesStore } from '../dictionaries.store';

export interface ICreateState {
  busy: boolean;
}

const initialState = (): ICreateState => ({
  busy: false,
});

@Injectable()
export class CreateStore extends ComponentStore<ICreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly dictionaryService: DictionaryHttpService,
    private readonly dictionariesStore: DictionariesStore,
  ) {
    super(initialState());
  }

  public readonly createDictionary = this.effect<IDictionaryPayload>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.setBusy(true)),
        switchMap((userData) =>
          this.dictionaryService.create(userData).pipe(
            tapResponse(
              (dictionary) => this.dictionariesStore.addDictionary(dictionary),
              (e: HttpErrorResponse) => this.logError(e),
            ),
          ),
        ),
        tap(() => this.setBusy(false)),
      );
    },
  );

  public returnToList() {
    this.dictionariesStore.viewListOnly();
  }

  private setBusy(busy: boolean) {
    this.setState(
      (state): ICreateState => ({
        ...state,
        busy: busy,
      }),
    );
  }

  private logError(e: Error) {}
}
