import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IContactAddress } from '../../models';
import { IContractAddressPayload } from './contact-address.payloads';

@Injectable({
  providedIn: 'root',
})
export class ContactAddressHttpService {
  constructor(protected http: HttpClient) {}

  public getAll(contactId: string): Observable<IContactAddress[]> {
    return this.http.get<IContactAddress[]>(
      `/api/contacts/${contactId}/addresses`,
    );
  }

  public create(
    contactId: string,
    data: IContractAddressPayload,
  ): Observable<IContactAddress> {
    return this.http.post<IContactAddress>(
      `/api/contacts/${contactId}/addresses`,
      {
        ...data,
      },
    );
  }

  public update(
    contactId: string,
    addressId: string,
    data: IContractAddressPayload,
  ): Observable<IContactAddress> {
    return this.http.put<IContactAddress>(
      `/api/contacts/${contactId}/addresses/${addressId}`,
      {
        ...data,
      },
    );
  }

  public delete(contactId: string, addressId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/contacts/${contactId}/addresses/${addressId}`,
    );
  }
}
