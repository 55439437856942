import { NgModule } from '@angular/core';

import { SharedExtModule } from '~/shared-ext.module';
import { SharedModule } from '~/shared.module';

import { EmailsListComponent } from './components/emails-list';
import { EmailItemComponent } from './components/email-item';
import { EmailEditComponent } from './components/email-edit';
import { EmailCreateComponent } from './components/email-create';
import { AddEmailDialogService } from './services/dialogs';

@NgModule({
  declarations: [
    EmailsListComponent,
    EmailItemComponent,
    EmailEditComponent,
    EmailCreateComponent,
  ],
  imports: [SharedModule, SharedExtModule],
  providers: [AddEmailDialogService],
  exports: [EmailsListComponent],
})
export class EmailModule {}
