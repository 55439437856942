import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UpdateStore } from './update.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dictionary-update',
  templateUrl: './update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UpdateStore],
})
export class UpdateComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public dictionary$ = this.store.dictionary$;
  private subs = new Subscription();

  constructor(private readonly store: UpdateStore) {
    this.form = new FormGroup<{
      name: FormControl;
    }>({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });
  }

  public ngOnInit() {
    this.subs.add(
      this.dictionary$.subscribe((dictionary) => {
        if (dictionary) {
          this.form.patchValue({
            name: dictionary.name,
          });
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public updateDictionary() {
    this.store.updateDictionary(this.form.value);
  }

  public cancel(id: string) {
    this.store.viewDictionary(id);
  }
}
