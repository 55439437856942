import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { AddressesListStore } from './addresses-list.store';
import { IContactAddress } from '~/contact/modules/address/models';

@Component({
  selector: 'app-address-list',
  templateUrl: './addresses-list.component.html',
  providers: [provideComponentStore(AddressesListStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesListComponent implements OnInit {
  @Input() public contactId?: string | undefined;
  @Input() public edit: boolean = true;

  constructor(public readonly cs: AddressesListStore) {}

  public ngOnInit() {
    if (this.contactId) {
      this.cs.load(this.contactId);
    }
  }

  public addressString(address: IContactAddress): string {
    return [
      address.country,
      address.area,
      address.region,
      address.settlement,
      address.firstAddressLine,
      address.secondAddressLine,
      address.zipCode,
    ]
      .filter((p) => !!p)
      .join(', ');
  }
}
