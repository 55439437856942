import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlimContactHttpService } from '~/contact/services/http';
import { ICollection } from '~/@core/models';
import { SlimContact } from '~/contact/models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-call-list',
  templateUrl: './phone-call.component.html',
})
export class PhoneCallComponent implements OnDestroy {
  protected list$?: Observable<ICollection<SlimContact>>;

  private subs = new Subscription();

  protected phone?: string;

  constructor(
    private readonly slimContactHttp: SlimContactHttpService,
    private readonly activeRoute: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    const sub = this.activeRoute.queryParams.subscribe((params) => {
      const phone = decodeURI(params['phone']);
      if (phone) {
        this.list$ = this.slimContactHttp.getAll({
          phone,
        });
      } else {
        this.list$ = undefined;
      }

      this.phone = phone;
      this.cdRef.markForCheck();
    });
    this.subs.add(sub);
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
