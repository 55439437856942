import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-row-confirm-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-check"
      class="p-button-success h-2rem w-2rem"
      [type]="type"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowConfirmButtonComponent {
  @Input() public type: string = 'button';
}
