import { Component, Host, Input, Optional, SkipSelf } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AbstractValidationComponent } from './abstract-validation.component';

@Component({
  selector: 'app-validation-error',
  template: `
    @if (control?.errors?.[error]) {
      <p class="text-red-500">
        {{ message ? message : (defaultMessage | translate: errorProps) }}
      </p>
    }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ValidationErrorComponent,
      multi: true,
    },
  ],
})
export class ValidationErrorComponent extends AbstractValidationComponent {
  protected defaultMessages: { [key: string]: string } = {
    required: 'common.validation.requiredField',
    maxlength: 'common.validation.maxLength',
    minlength: 'common.validation.minLength',
    email: 'common.validation.nonValidEmail',
  };

  @Input()
  public override formControl!: AbstractControl;

  @Input()
  public error!: string;

  @Input()
  public message?: string;

  @Input()
  public override formControlName!: string;

  protected get defaultMessage() {
    return this.defaultMessages[this.error];
  }

  protected get errorProps() {
    return this.control?.errors?.[this.error];
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected controlContainer: ControlContainer,
  ) {
    super();
  }
}
