import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IDictionary, IDictionaryPayload } from '../../../models';
import { DictionaryHttpService } from '../../../http';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DictionariesStore } from '../dictionaries.store';

export interface IUpdateDictionaryState {
  busy: boolean;
}

const initialState = (): IUpdateDictionaryState => ({
  busy: false,
});

@Injectable()
export class UpdateStore extends ComponentStore<IUpdateDictionaryState> {
  public dictionary$: Observable<IDictionary | undefined> =
    this.dictionariesStore.selectedDictionary$;

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly dictionaryService: DictionaryHttpService,
    private readonly dictionariesStore: DictionariesStore,
  ) {
    super(initialState());
  }

  public readonly updateDictionary = this.effect<IDictionaryPayload>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.setBusy(true)),
        withLatestFrom(this.dictionary$),
        filter(([, dictionary]) => !!dictionary),
        switchMap(([data, dictionary]) =>
          this.dictionaryService
            .update((dictionary as IDictionary).id, data)
            .pipe(
              tapResponse(
                (dictionary) =>
                  this.dictionariesStore.setDictionary(dictionary),
                (e: HttpErrorResponse) => this.logError(e),
              ),
            ),
        ),
        tap(() => this.setBusy(false)),
      );
    },
  );

  public viewDictionary(id: string) {
    this.dictionariesStore.viewDictionary(id);
  }

  private setBusy(busy: boolean) {
    this.setState(
      (state): IUpdateDictionaryState => ({
        ...state,
        busy: busy,
      }),
    );
  }

  private logError(e: Error) {}
}
