import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ImportColumnConfiguration } from '../../../models';
import { IDataColumn } from '../importer.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorComponent implements OnInit, OnDestroy {
  @Input() public columnConfigurations: ImportColumnConfiguration[] = [];
  @Input() public dataColumns: IDataColumn[] = [];
  @Input() public data?: any[];

  @Output() public dataMapping = new EventEmitter<Map<number, string>>();
  @Output() public backStep = new EventEmitter<void>();

  public form = new FormGroup({});
  public columnMappingHeader = new Map<number, string>();
  public subs = new Subscription();
  public nonSetColumns: IDataColumn[] = [];

  public ngOnInit() {
    this.form = this.buildForm();
    this.nonSetColumns = this.dataColumns;
    this.createColumnMappingHeadersSub();
  }

  public dropDownOptions(key: string) {
    const value = this.form.get(key)?.value;
    return value ? [value, ...this.nonSetColumns] : [...this.nonSetColumns];
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public next() {
    if (this.form.invalid) {
      return;
    }
    this.dataMapping.emit(
      new Map(
        Object.entries(this.form.value).reduce<Array<[number, string]>>(
          (acc, [formKey, formVal]) => {
            if (!formVal) {
              return acc;
            }
            acc.push([(formVal as IDataColumn).key, formKey]);
            return acc;
          },
          [],
        ),
      ),
    );
  }

  private buildForm() {
    return this.columnConfigurations.reduce((form, column) => {
      const validators = column.required ? [Validators.required] : [];
      form.addControl(column.key, new FormControl(undefined, validators));
      return form;
    }, this.form);
  }

  private createColumnMappingHeadersSub() {
    const sub = this.form?.valueChanges.subscribe((value) => {
      this.columnMappingHeader = new Map<number, string>(
        Object.entries(value).reduce<Array<[number, string]>>(
          (acc, [key, value]) => {
            if (!value) {
              return acc;
            }
            const dataKey = (value as IDataColumn).key;
            const confName = this.columnConfigurations.find(
              (c) => c.key === key,
            )?.name as string;
            acc.push([dataKey, confName]);
            return acc;
          },
          [],
        ),
      );
      this.nonSetColumns = this.dataColumns.filter(
        (column) => !Object.values(value).includes(column),
      );
    });
    this.subs.add(sub);
  }
}
