import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabMenuModule } from 'primeng/tabmenu';
import { BadgeModule } from 'primeng/badge';

import { SharedModule } from '~/shared.module';
import { SharedExtModule } from '~/shared-ext.module';
import { PhoneModule } from '~/contact/modules/phone';
import { EmailModule } from '~/contact/modules/email';

import { ContactCommonModule } from '../@common';
import { ContactNamePipe } from '../@common/pipes/contact-name.pipe';
import { ContactPickModalComponent } from './components/contact-pick-modal';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactLinkComponent } from './components/contact-link/contact-link.component';
import { ContactSearchComponent } from './components/contact-search/contact-search.component';
import { PickContactService } from './services/dialogs';
import { ListboxModule } from 'primeng/listbox';
import { ContactGetterService } from '~/contact/@feature/services/contact-getter.service';
import { ContextMenuModule } from 'primeng/contextmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  imports: [
    SharedModule,
    SharedExtModule,
    ContactCommonModule,
    AutoCompleteModule,
    RouterModule,
    PhoneModule,
    EmailModule,
    TabMenuModule,
    BadgeModule,
    TabViewModule,
    ListboxModule,
    ContextMenuModule,
    OverlayPanelModule,
  ],
  providers: [PickContactService, ContactGetterService],
  declarations: [
    ContactSearchComponent,
    ContactCardComponent,
    ContactLinkComponent,
    ContactPickModalComponent,
  ],
  exports: [
    ContactSearchComponent,
    ContactNamePipe,
    ContactLinkComponent,
    ContactCardComponent,
  ],
})
export class ContactFeatureModule {}
