import { SlimContact } from '~/contact/models';
import { User } from '~/user/models';

import { ActivityType } from './activity-type';
import { ActivityStatus } from './activity-status';
import { SlimActivity } from './slim-activity';
import { ActivityComment } from './activity-comment';

export enum ActivityPriorityEnum {
  LOWEST = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  HIGHEST = 5,
}

export type Activity = {
  id: string;
  title: string;
  description?: string;
  parent?: SlimActivity;
  contact: SlimContact;
  additionalContacts?: SlimContact[];
  status: ActivityStatus;
  type: ActivityType;
  creator: User;
  actors: User[];
  supervisors: User[];
  createdAt: string;
  updatedAt: string;
  resolvedAt: string;
  estimatedStartAt?: string;
  estimatedEndAt?: string;
  priority: ActivityPriorityEnum;
  lastComment?: ActivityComment;
};
