import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

import { SharedExtModule } from '~/shared-ext.module';

import { ExportButtonComponent } from './components/export-button';
import { ExportService } from './services/export.service';

@NgModule({
  declarations: [ExportButtonComponent],
  providers: [ExportService],
  imports: [CommonModule, ButtonModule, TranslateModule, SharedExtModule],
  exports: [ExportButtonComponent],
})
export class ExporterModule {}
