<button
  pButton
  pRipple
  icon="pi pi-exclamation-circle"
  class="h-2rem w-2rem"
  [ngClass]="{
    'p-button-danger': !result.resultId,
    'p-button-warn': result.resultId
  }"
  (click)="setVisible(true)"
></button>
<p-dialog
  [header]="'common.header.importErrors'"
  [modal]="true"
  [visible]="visible"
  (visibleChange)="setVisible($event)"
>
  @for (error of result.errors; track $index) {
    <div>
      {{ error }}
    </div>
  }
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Cancel"
      severity="secondary"
      (onClick)="setVisible(false)"
    />
  </div>
</p-dialog>
