import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ISubscription } from '~/subscription/models';

@Component({
  selector: 'app-subscription-pick-modal',
  templateUrl: './subscription-pick-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPickModalComponent {
  protected subscriptions: ISubscription[];

  constructor(
    private readonly ref: DynamicDialogRef,
    readonly config: DynamicDialogConfig,
  ) {
    this.subscriptions = config.data.subscriptions;
  }

  protected pickSubscription(subscription: ISubscription) {
    this.ref.close(subscription);
  }
}
