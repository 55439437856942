import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-user-password-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-unlock"
      class="p-button-primary h-2rem w-2rem"
      [pTooltip]="'user.button.passwordChange' | translate"
      (click)="!disabled && changePassword.emit($event)"
      [disabled]="disabled"
      tooltipPosition="top"
      type="button"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPasswordButtonComponent {
  @Output() public changePassword = new EventEmitter<MouseEvent>();
  @Input() public disabled = false;
}
