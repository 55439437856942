import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ContactType, SlimContact } from '~/contact/models';
import { ContactPhone } from '~/contact/modules/phone/models';
import { ContactPhoneHttpService } from '~/contact/modules/phone/services/http';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-contact-link',
  styleUrls: ['./contact-link.component.scss'],
  templateUrl: './contact-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactLinkComponent {
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      this.additionalBlockOpened = false;
      this.cdRef.detectChanges();
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.additionalBlockOpened = false;
      this.cdRef.detectChanges();
    }
  }

  @ViewChild(OverlayPanel, { static: false })
  private overlayPanel!: OverlayPanel;

  @Input() public contact!: SlimContact;

  protected readonly limit = 25;

  protected phonesLoading = false;

  protected phonesLoaded = false;

  protected phones: ContactPhone[] = [];

  private additionalBlockOpened = false;

  protected get normalizedName() {
    return this.contact?.name || '';
  }

  protected get showAdditionalBlock() {
    return this.additionalBlockOpened && this.phonesLoaded;
  }

  protected get buttonText() {
    const name = this.normalizedName;
    return name.length > this.limit
      ? `${name?.slice(0, this.limit - 3)}...`
      : name;
  }

  protected get link() {
    switch (this.contact?.type) {
      case ContactType.Person:
        return `/contacts/persons/${this.contact.id}`;
      case ContactType.Company:
        return `/contacts/companies/${this.contact.id}`;
      default:
        return '';
    }
  }

  protected get noTooltip() {
    return (
      this.normalizedName.length <= this.limit && !this.contact?.identifier
    );
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdRef: ChangeDetectorRef,
    private readonly contactPhoneService: ContactPhoneHttpService,
  ) {}

  protected openAdditionalBlock(ev: MouseEvent) {
    if (this.phonesLoading) {
      return;
    }

    if (this.additionalBlockOpened) {
      this.additionalBlockOpened = false;
      this.cdRef.markForCheck();
      return;
    }

    if (!this.phonesLoaded) {
      this.getPhones();
    }

    if (this.overlayPanel.overlayVisible) {
      this.overlayPanel.hide();
    } else {
      this.overlayPanel.show(ev);
    }

    this.cdRef.markForCheck();
  }

  private getPhones() {
    this.phonesLoading = true;
    this.cdRef.markForCheck();

    this.contactPhoneService.getAll(this.contact.id).subscribe((phones) => {
      this.phones = phones;
      this.phonesLoaded = true;
      this.phonesLoading = false;
      this.cdRef.markForCheck();
    });
  }
}
