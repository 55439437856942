<p-editor [style]="style">
  <ng-template pTemplate="header">
    <span class="ql-formats">
      <button type="button" class="ql-bold" aria-label="Bold"></button>
      <button type="button" class="ql-italic" aria-label="Italic"></button>
      <button
        type="button"
        class="ql-underline"
        aria-label="Underline"
      ></button>
    </span>
  </ng-template>
</p-editor>
