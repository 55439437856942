<div class="py-2 border-1 border-gray-300 border-round">
  @for (subscription of subscriptions; track subscription.id) {
    <div
      (click)="pickSubscription(subscription)"
      class="flex align-items-center justify-content-between gap-2 w-full px-3 py-2 hover:bg-primary-50 cursor-pointer"
    >
      <div>
        {{ subscription.type.name }}<br />
        {{ subscription?.start | date: "y-MM-dd" }} -
        {{ subscription?.end | date: "y-MM-dd" }}
      </div>
      <div class="flex gap-2">
        <a
          pButton
          pRipple
          icon="pi pi-link"
          class="p-button-rounded p-button-primary"
          target="_blank"
          (click)="$event.stopPropagation()"
          [routerLink]="'/subscription/' + subscription.id"
        ></a>
      </div>
    </div>
  }
</div>
