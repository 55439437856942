import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivityResolutionEnum } from '~/activity/models';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-activity-resolution-dropdown',
  templateUrl: './activity-resolution-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityResolutionDropdownComponent),
      multi: true,
    },
  ],
})
export class ActivityResolutionDropdownComponent
  implements ControlValueAccessor
{
  public options = [
    { label: 'activity.resolution.to_do', value: ActivityResolutionEnum.ToDo },
    {
      label: 'activity.resolution.in_progress',
      value: ActivityResolutionEnum.InProgress,
    },
    {
      label: 'activity.resolution.finished',
      value: ActivityResolutionEnum.Finished,
    },
  ];

  @ViewChild(MultiSelect, { static: true })
  protected dropdown!: MultiSelect;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public showClear = false;

  @Input()
  public placeholder?: string;

  protected disabled = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  public writeValue(val: any): void {
    this.dropdown.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }
}
