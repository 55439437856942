<div class="py-2 border-1 border-gray-300 border-round">
  @for (contact of contacts; track contact.id) {
    <div
      (click)="pickContact(contact)"
      class="flex align-items-center justify-content-between gap-2 w-full px-3 py-2 hover:bg-primary-50 cursor-pointer"
    >
      <div>{{ contact.name }}</div>
      <div class="flex gap-2">
        <a
          pButton
          pRipple
          icon="pi pi-link"
          class="p-button-rounded p-button-primary"
          target="_blank"
          (click)="$event.stopPropagation()"
          [routerLink]="link(contact)"
        ></a>
      </div>
    </div>
  }
</div>
