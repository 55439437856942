import { IHooked } from '~/hook/models';
import { FooterContactSearchComponent } from './footer-contact-search.component';

export const footerContactSearchWidget: IHooked<FooterContactSearchComponent> =
  {
    hookName: 'footer',
    widgetId: 'contact-search-footer-widget',
    import: () =>
      import('./footer-contact-search.component').then(
        (m) => m.FooterContactSearchComponent,
      ),
  };
