import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DictionariesComponent } from './pages/dictionaries';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: DictionariesComponent,
      },
      { path: '**', redirectTo: '/notfound' },
    ]),
  ],
  exports: [RouterModule],
})
export class DictionaryRoutingModule {}
