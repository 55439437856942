import { Component } from '@angular/core';

@Component({
  selector: 'app-table-header',
  template: `
    <div
      class="flex flex-wrap gap-2 align-items-center justify-content-between"
    >
      <div><ng-content select="[filter]"></ng-content></div>
      <div><ng-content select="[actions]"></ng-content></div>
    </div>
  `,
})
export class TableHeaderComponent {}
