import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { exhaustMap, Observable, take, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { ICollection, IPagination } from '~/@core/models';
import { IListState, initialListState } from '~/@core/store/states';

import { ContactFilter, ContactType, SlimContact } from '../../models';
import {
  ContactHttpService,
  SlimContactHttpService,
} from '../../services/http';
import { PersonDialogService } from '../../services/dialogs';
import { ScrollContentService } from '~/layout/service/scroll-content.service';

export interface IPersonsListState
  extends IListState<SlimContact, ContactFilter> {}

const initialState = (): IPersonsListState => ({
  ...initialListState<SlimContact, ContactFilter>({}),
});

@Injectable()
export class PersonsListStore extends ComponentStore<IPersonsListState> {
  public readonly loadedList$: Observable<ICollection<SlimContact>> =
    this.select((state) => state.loadedList);

  public readonly loading$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  constructor(
    private readonly slimContactHttp: SlimContactHttpService,
    private readonly contactHttp: ContactHttpService,
    private readonly personDialogService: PersonDialogService,
    private readonly scroller: ScrollContentService,
    private readonly router: Router,
  ) {
    super(initialState());
  }

  public readonly load = this.effect<{
    pagination: IPagination;
    filter: ContactFilter;
  }>((loadParams$) => {
    return loadParams$.pipe(
      tap(({ filter, pagination }) => {
        this.setLoadParams({ filter, pagination });
      }),
      tap(() => this.fetchList()),
      tap(() => this.scroller.scrollToTop()),
    );
  });

  public showCreateDialog = this.effect<void>((trigger$) =>
    trigger$.pipe(
      exhaustMap(() => this.personDialogService.open().pipe(take(1))),
      tap(
        (person) =>
          person && this.router.navigate(['/contacts/persons/', person.id]),
      ),
    ),
  );

  public readonly deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      switchMap((id) =>
        this.contactHttp.delete(id).pipe(
          tapResponse(
            () => this.fetchList(),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  private readonly fetchList = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      withLatestFrom(
        this.select((state) => state.filter),
        this.select((state) => state.pagination),
      ),
      switchMap(([_, filter, pagination]) =>
        this.slimContactHttp
          .getList(
            {
              ...filter,
              type: ContactType.Person,
            },
            pagination,
          )
          .pipe(
            tapResponse(
              (list) => this.setList(list),
              (e: HttpErrorResponse) => this.logError(e),
            ),
          ),
      ),
    );
  });

  private setList = this.updater<ICollection<SlimContact>>(
    (state, list: ICollection<SlimContact>) => ({
      ...state,
      loadedList: list,
      loading: false,
    }),
  );

  private setLoading() {
    this.patchState({
      loading: true,
    });
  }

  private setLoadParams = this.updater<{
    filter: ContactFilter;
    pagination: IPagination;
  }>((state, { filter, pagination }) => ({
    ...state,
    filter,
    pagination,
  }));

  private logError(e: Error) {}
}
