import { Component, Input } from '@angular/core';
import { ISubscription } from '~/subscription/models';

@Component({
  selector: 'app-subscription-expand',
  templateUrl: './subscription-expand.component.html',
})
export class SubscriptionExpandComponent {
  @Input() subscription!: ISubscription;
}
