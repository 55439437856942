<div class="card" *ngIf="cs.periods$ | async as items">
  <div class="card-body">
    <div class="card-header pb-2 border-bottom-1 border-gray-300">
      <div class="flex justify-content-between align-items-center gap-3">
        <h2 class="card-title text-2xl m-0">
          {{ "subscription.header.periods" | translate }}
        </h2>
        <div class="flex align-items-end gap-2">
          <button
            pButton
            pRipple
            icon="pi pi-plus"
            class="p-button-text p-button-rounded p-button-success"
            (click)="cs.openAddPeriodModal()"
          ></button>
        </div>
      </div>
    </div>
    <div class="card-body pb-2">
      <p-table [value]="items" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem"></th>
            <th>{{ "subscription.label.start" | translate }}</th>
            <th>{{ "subscription.label.end" | translate }}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-period let-expanded="expanded">
          <tr>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="period"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                [disabled]="noExpandData(period)"
              ></button>
            </td>
            <td>{{ period.start | date: "y-MM-dd" }}</td>
            <td>{{ period.end | date: "y-MM-dd" }}</td>
            <td class="text-right">
              <app-row-delete-button
                (delete)="deletePeriod(period.id)"
                [disabled]="(canDelete$ | async) === false"
              ></app-row-delete-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-period>
          <tr>
            <td colspan="4" class="border-left-1 border-solid pl-6">
              <app-period-info [period]="period"></app-period-info>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
