import { IHooked } from '~/hook/models';
import { UserActivitiesComponent } from './user-activities';

export const homepageActivitiesWidget: IHooked<UserActivitiesComponent> = {
  hookName: 'homepage',
  widgetName: 'activity.header.myCurrentActivities',
  widgetId: 'user-activities-list',
  import: () =>
    import('src/app/activity/standalone/homepage-widget/user-activities').then(
      (m) => m.UserActivitiesComponent,
    ),
};
