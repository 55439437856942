import { Component, Host, Input, Optional, SkipSelf } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AbstractValidationComponent } from './abstract-validation.component';

@Component({
  selector: 'app-validation-errors',
  template: `
    @if (control?.invalid && (control?.dirty || control?.touched)) {
      <div class="text-danger">
        <ng-content></ng-content>
      </div>
    }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ValidationErrorsComponent,
      multi: true,
    },
  ],
})
export class ValidationErrorsComponent extends AbstractValidationComponent {
  @Input()
  public override formControl!: AbstractControl;

  @Input()
  public override formControlName!: string;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected controlContainer: ControlContainer,
  ) {
    super();
  }
}
