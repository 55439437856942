import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AppMenuComponent } from './components/menu/app.menu.component';
import { AppMenuitemComponent } from './components/menu/app.menuitem.component';
import { AppSidebarComponent } from './components/sidebar/app.sidebar.component';
import { AppFooterComponent } from './components/footer/app.footer.component';
import { AppConfigModule } from '~/layout/modules/config/config.module';
import { AppLayoutComponent } from './components/layout/app.layout.component';
import { StyleClassModule } from 'primeng/styleclass';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { SharedExtModule } from '~/shared-ext.module';
import { SharedModule } from '~/shared.module';

@NgModule({
  declarations: [
    AppMenuitemComponent,
    AppSidebarComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppLayoutComponent,
  ],
  imports: [
    SharedExtModule,
    SharedModule,
    BrowserAnimationsModule,
    InputSwitchModule,
    MenuModule,
    SidebarModule,
    StyleClassModule,
    BadgeModule,
    RadioButtonModule,
    TooltipModule,
    AppConfigModule,
  ],
  exports: [AppLayoutComponent],
})
export class AppLayoutModule {}
