import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-space',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="text-center px-3 py-5 flex flex-column align-items-center align-content-center gap-2 border-round border-1 border-200"
    >
      <p class="text-600 font-bold">
        @if (message) {
          {{ message }}
        } @else {
          {{ 'widgets.emptySpace.noDataToDisplay' | translate }}
        }
      </p>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class EmptySpaceComponent {
  @Input() public message?: string;
}
