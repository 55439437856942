import { taskStatusEntityAdapter } from './activity-status.reducer';
import { selectActivityFeature } from '../feature.selector';
import { createSelector } from '@ngrx/store';

const { selectAll } = taskStatusEntityAdapter.getSelectors();

const selectActivityStatusSlice = createSelector(
  selectActivityFeature,
  (s) => s.activityStatus,
);
export const selectActivityStatuses = createSelector(
  selectActivityStatusSlice,
  selectAll,
);
export const selectActivityStatusesCount = createSelector(
  selectActivityStatuses,
  (s) => s?.length,
);
export const selectActivityStatusesLoading = createSelector(
  selectActivityStatusSlice,
  (s) => s.loading,
);

export const selectActivityStatusesLoadedTime = createSelector(
  selectActivityStatusSlice,
  (s) => s.lastLoadedTime,
);
