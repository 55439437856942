<p-autoComplete
  [suggestions]="$any(cs.suggestions$ | async)"
  (completeMethod)="search($event)"
  [dropdown]="true"
  [forceSelection]="true"
  [showClear]="true"
  [appendTo]="appendTo"
  [multiple]="multiple"
  showTransitionOptions="0ms"
  hideTransitionOptions="0ms"
  dataKey="id"
  field="name"
  class="w-full"
>
  <ng-template let-group pTemplate="group">
    <div class="flex align-items-center">
      <span>{{ group.label }}</span>
    </div>
  </ng-template>
  <ng-template let-contact pTemplate="item">
    <div class="flex align-items-center justify-content-between gap-2">
      <div>
        <div>{{ contact.name }}</div>
        <div *ngIf="contact?.identifier" class="font-light text-sm">
          ({{ contact.identifier }})
        </div>
      </div>
      <p-badge [value]="contact.type" />
    </div>
  </ng-template>
</p-autoComplete>
