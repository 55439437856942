import { Component, ViewChild } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { CompaniesListStore } from './companies-list.store';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ContactFilter } from '~/contact/models';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  providers: [provideComponentStore(CompaniesListStore)],
})
export class CompaniesListComponent {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  @ViewChild('dt', { static: false })
  protected dt!: Table;

  constructor(
    public readonly cs: CompaniesListStore,
    private readonly store: Store,
  ) {}

  public load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    const filter = event.filters?.['filter']
      ? ((event.filters?.['filter'] as FilterMetadata).value as ContactFilter)
      : {};

    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        search: event?.globalFilter
          ? (event?.globalFilter as string)
          : undefined,
        ...filter,
      },
    });
  }

  protected updateFilter(filter: ContactFilter) {
    this.dt.filter(filter, 'filter', 'equals');
  }

  public showCreateDialog() {
    this.cs.showCreateDialog();
  }
}
