import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ContactDictionary } from '../../models';
import { createReducer, on } from '@ngrx/store';
import {
  addItem,
  fetchAllItems,
  fetchAllItemsFail,
  fetchAllItemsSuccess,
  removeItem,
  setItem,
} from './contact-dictionary.actions';

export const dictionaryItemsAdapter = createEntityAdapter<ContactDictionary>();

export interface ContactDictionaryItemsState {
  loaded: boolean;
  loading: boolean;
  entities: EntityState<ContactDictionary>;
}

const createDictionaryInitState = (): ContactDictionaryItemsState => ({
  loaded: false,
  loading: false,
  entities: dictionaryItemsAdapter.getInitialState(),
});

export const contactDictionaryReducer =
  createReducer<ContactDictionaryItemsState>(
    createDictionaryInitState(),
    on(
      fetchAllItems,
      (state): ContactDictionaryItemsState => ({
        ...state,
        loading: true,
      }),
    ),
    on(
      fetchAllItemsSuccess,
      (state, { list }): ContactDictionaryItemsState => ({
        ...state,
        loading: false,
        loaded: true,
        entities: dictionaryItemsAdapter.setAll(list, state.entities),
      }),
    ),
    on(
      fetchAllItemsFail,
      (state): ContactDictionaryItemsState => ({
        ...state,
        loading: false,
      }),
    ),
    on(
      addItem,
      (state, { item }): ContactDictionaryItemsState => ({
        ...state,
        loading: false,
        entities: dictionaryItemsAdapter.addOne(item, state.entities),
      }),
    ),
    on(
      setItem,
      (state, { item }): ContactDictionaryItemsState => ({
        ...state,
        loading: false,
        entities: dictionaryItemsAdapter.setOne(item, state.entities),
      }),
    ),
    on(
      removeItem,
      (state, { itemId }): ContactDictionaryItemsState => ({
        ...state,
        loading: false,
        entities: dictionaryItemsAdapter.removeOne(itemId, state.entities),
      }),
    ),
  );
