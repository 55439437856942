<form
  class="flex flex-column gap-3"
  [formGroup]="formGroup"
  (ngSubmit)="save()"
>
  <div class="grid p-fluid formgrid">
    <div class="field col-12 md:col-4">
      @if (formGroup.get("country"); as control) {
        <label for="country" class="text-900 font-semibold">{{
          "contact.label.country" | translate
        }}</label>
        <input id="country" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12 md:col-4">
      @if (formGroup.get("area"); as control) {
        <label for="area" class="text-900 font-semibold">{{
          "contact.label.area" | translate
        }}</label>
        <input id="area" pInputText type="text" [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12 md:col-4">
      @if (formGroup.get("region"); as control) {
        <label for="region" class="text-900 font-semibold">{{
          "contact.label.region" | translate
        }}</label>
        <input id="region" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12 md:col-8">
      @if (formGroup.get("settlement"); as control) {
        <label for="settlement" class="text-900 font-semibold">{{
          "contact.label.settlement" | translate
        }}</label>
        <input id="settlement" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12 md:col-4">
      @if (formGroup.get("zipCode"); as control) {
        <label for="zipCode" class="text-900 font-semibold">{{
          "contact.label.zipCode" | translate
        }}</label>
        <input id="zipCode" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12">
      @if (formGroup.get("firstAddressLine"); as control) {
        <label for="firstAddressLine" class="text-900 font-semibold required">{{
          "contact.label.firstAddressLine" | translate
        }}</label>
        <input id="firstAddressLine" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12">
      @if (formGroup.get("secondAddressLine"); as control) {
        <label for="secondAddressLine" class="text-900 font-semibold">{{
          "contact.label.secondAddressLine" | translate
        }}</label>
        <input
          id="secondAddressLine"
          pInputText
          [formControl]="$any(control)"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12">
      @if (formGroup.get("note"); as control) {
        <label for="note" class="text-900 font-semibold required">{{
          "contact.label.note" | translate
        }}</label>
        <input id="note" pInputText [formControl]="$any(control)" />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error
            [formControl]="$any(control)"
            error="maxlength"
          />
        </app-validation-errors>
      }
    </div>
  </div>
  <div class="flex gap-2 justify-content-end">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="formGroup.invalid"></app-save-button>
  </div>
</form>
