import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { CompanyViewStore } from './company-view.store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  providers: [provideComponentStore(CompanyViewStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyViewComponent implements OnDestroy {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  private subs = new Subscription();

  constructor(
    protected readonly cs: CompanyViewStore,
    private readonly store: Store,
    readonly route: ActivatedRoute,
  ) {
    this.subs.add(
      route.params.subscribe((params) => cs.loadContact(params['id'])),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
