import { ActionReducer, MetaReducer, INIT, UPDATE } from '@ngrx/store';
import { IAuthState } from './auth.reducer';
import { logout, setAuthToken } from './auth.actions';

const LOCAL_STORAGE_AUTH_KEY = 'store.auth';

function localStorageSyncMetaReducer(
  reducer: ActionReducer<IAuthState>,
): ActionReducer<IAuthState> {
  return (state, action) => {
    const nextState = reducer(state, action);

    if (action.type === INIT || action.type === UPDATE) {
      const storageAuthValue = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
      if (storageAuthValue) {
        try {
          const rehydratedState = { ...nextState };
          rehydratedState.authToken = storageAuthValue;
          return rehydratedState;
        } catch {
          localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        }
      }
    }

    if (action.type === setAuthToken.type || action.type === logout.type)
      if (nextState.authToken === null) {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      } else {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, nextState.authToken);
      }

    return nextState;
  };
}

export const metaReducers: MetaReducer<IAuthState>[] = [
  localStorageSyncMetaReducer,
];
