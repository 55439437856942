import { NgModule } from '@angular/core';
import { CancelButtonComponent } from './cancel-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [CancelButtonComponent],
  imports: [SharedExtModule],
  exports: [CancelButtonComponent],
})
export class CancelButtonModule {}
