import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthRootModule } from './auth-root.module';
import { AuthService } from './http/auth.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, AuthRoutingModule],
  providers: [AuthService],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthRootModule> {
    return {
      ngModule: AuthRootModule,
    };
  }
}
