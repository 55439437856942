import { APP_INITIALIZER } from '@angular/core';
import { AppConfigService } from '~/@core/services/app-config.service';
import { tap } from 'rxjs';
import { ConfigValueHttpService } from '~/@core/services/http';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfigIds } from '~/@core/models';
import { getEnTranslation, getUkTranslation } from '../../../i18n';

const loadConfig = (
  translate: TranslateService,
  http: ConfigValueHttpService,
  configService: AppConfigService,
) => {
  return () => {
    return http.getGlobal().pipe(
      tap((config) => {
        configService.setConfig(config.items);
      }),
      tap((config) => {
        const defaultLang = config.items.find(
          (c) => c.id === GlobalConfigIds.Language,
        )?.value;

        translate.addLangs(['en', 'uk']);
        translate.setTranslation('en', getEnTranslation());
        translate.setTranslation('uk', getUkTranslation());
        translate.setDefaultLang(defaultLang || 'en');
      }),
    );
  };
};

export const initializers = [
  {
    provide: APP_INITIALIZER,
    useFactory: loadConfig,
    deps: [TranslateService, ConfigValueHttpService, AppConfigService],
    multi: true,
  },
];
