<p-multiSelect
  [options]="(types$ | async) || []"
  [disabled]="(loading$ | async) === true || disabled"
  [showClear]="showClear"
  optionLabel="name"
  optionValue="id"
  [appendTo]="appendTo"
  [placeholder]="placeholder"
  [filter]="false"
  [showToggleAll]="false"
  [showHeader]="false"
  dataKey="id"
/>
