<div class="card" *ngIf="dictionary$ | async as dictionary">
  <div class="flex flex-wrap align-items-center justify-content-between gap-3">
    <div>
      <h2 class="text-900 text-xl font-bold mb-1 block">
        {{ dictionary.name }}
      </h2>
      <span class="text-400 text-sm block">{{
        (dictionary$ | async)?.createdAt | date: "medium"
      }}</span>
    </div>
    <div class="flex flex-wrap align-items-center gap-2">
      <app-card-edit-button
        (edit)="cs.editDictionary(dictionary.id)"
        [disabled]="(canManageDictionary$ | async) === false"
      />
      <app-card-delete-button
        (delete)="cs.deleteCurrentDictionary()"
        [disabled]="(canManageDictionary$ | async) === false"
      />
    </div>
  </div>
</div>
