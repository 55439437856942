@if (user) {
  <div class="text-sm text-600">ID: {{ user.id }}</div>
}
<form class="flex flex-column gap-3" [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid formgrid p-fluid mt-4">
    @if (form.get("email"); as control) {
      <div class="field col-12 lg:col-4">
        <label for="email" class="text-900 font-semibold">{{
          "user.label.email" | translate
        }}</label>
        <input
          pInputText
          type="email"
          [formControl]="$any(control)"
          id="email"
          autocomplete="off"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
          <app-validation-error [formControl]="$any(control)" error="email" />
          <app-validation-error
            [formControl]="$any(control)"
            error="uniqueEmail"
            [message]="'user.validation.emailAlreadyExist' | translate"
          />
        </app-validation-errors>
      </div>
    }

    @if (form.get("name"); as control) {
      <div class="field col-12 lg:col-4">
        <label for="name" class="text-900 font-semibold">{{
          "user.label.name" | translate
        }}</label>
        <input
          pInputText
          type="text"
          [formControl]="$any(control)"
          id="name"
          autocomplete="nope"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }

    @if (form.get("status"); as control) {
      <div class="field col-12 lg:col-4">
        <label for="status" class="text-900 font-semibold">{{
          "user.label.status" | translate
        }}</label>
        <p-dropdown
          [options]="statuses"
          [formControl]="$any(control)"
          id="status"
          appendTo="body"
        >
          <ng-template let-item pTemplate="selectedItem">
            {{ item.label | translate }}
          </ng-template>
          <ng-template let-item pTemplate="item">{{
            item.label | translate
          }}</ng-template>
        </p-dropdown>
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }

    @if (form.get("roles"); as control) {
      <div class="field col-12">
        <label for="roles" class="text-900 font-semibold">{{
          "user.label.roles" | translate
        }}</label>
        <p-multiSelect
          [options]="roles"
          [formControl]="$any(control)"
          id="roles"
          appendTo="body"
        >
          <ng-template let-value pTemplate="selectedItems">
            <div class="inline-flex align-items-center gap-2">
              @for (sItem of value; track $index) {
                {{ getRoleLabel(sItem) | translate
                }}{{ $index !== value.length - 1 ? ", " : "" }}
              }
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">{{
            item.label | translate
          }}</ng-template>
        </p-multiSelect>
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }

    @if (form.get("password"); as control) {
      <div class="field col-12">
        <label for="password" class="text-900 font-semibold">{{
          "user.label.password" | translate
        }}</label>
        <input
          pInputText
          [formControl]="$any(control)"
          id="password"
          type="password"
          autocomplete="new-password"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }
  </div>
  <div class="flex justify-content-end gap-2">
    <app-cancel-button (click)="cs.cancel()"></app-cancel-button>
    <app-save-button
      [disabled]="!form.valid || (cs.isBusy$ | async) === true"
    ></app-save-button>
  </div>
</form>
