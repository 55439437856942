import {
  isDevMode,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from '~/auth';
import { DictionaryModule } from '~/dictionary';
import { ActivityModule } from '~/activity';
import { UserModule } from '~/user';
import { ContactModule } from '~/contact';
import { SubscriptionModule } from '~/subscription';

import { ConfirmModule } from './modules/confirm';
import { ErrorDialogModule } from './modules/error-dialog';
import { initializers } from '~/@core/initializers';

// TODO: Currently import order is relevant for menu items order. It should be refactored.
const featureRootModules = [
  AuthModule.forRoot(),
  DictionaryModule.forRoot(),
  ContactModule.forRoot(),
  ActivityModule.forRoot(),
  SubscriptionModule.forRoot(),
  UserModule.forRoot(),
];

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    TranslateModule.forRoot(),

    ConfirmModule,
    ErrorDialogModule,

    ...featureRootModules,
  ],
  providers: [...initializers],
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
