<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="submitForm()"
  class="flex flex-row gap-3 align-items-center p-2 border-bottom-1 surface-border font-semibold"
>
  <span class="flex-grow-1">{{ item?.number }}</span>
  <input
    pInputText
    type="text"
    formControlName="note"
    [placeholder]="'contact.label.note' | translate"
    class="w-3"
  />
  <div class="flex-grow-0 flex align-items-end gap-1">
    <app-row-confirm-button type="submit"></app-row-confirm-button>
    <app-row-cancel-button (click)="cancel.emit()"></app-row-cancel-button>
  </div>
</form>
