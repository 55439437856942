import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { provideComponentStore } from '@ngrx/component-store';
import { UserPasswordStore } from './user-password.store';
import { User } from '~/user/models';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  providers: [provideComponentStore(UserPasswordStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPasswordComponent {
  public form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });

  protected user?: User;

  constructor(
    private readonly config: DynamicDialogConfig,
    public readonly cs: UserPasswordStore,
  ) {
    this.user = this.config.data?.user;
  }

  protected submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.cs.patchUser(this.form.value);
  }
}
