<p-multiSelect
  [options]="options"
  [disabled]="disabled"
  [showClear]="showClear"
  id="subscriptionType"
  optionValue="value"
  [appendTo]="appendTo"
  [placeholder]="placeholder"
  [filter]="false"
  [showToggleAll]="false"
  [showHeader]="false"
>
  <ng-template let-resolutions pTemplate="selectedItems">
    @if (resolutions?.length > 0) {
      <div class="flex justify-content-between gap-1 w-full">
        @for (resolution of resolutions; track $index) {
          <span>{{ "activity.resolution." + resolution | translate }}</span>
        }
      </div>
    } @else {
      <div class="text-500">None</div>
    }
  </ng-template>
  <ng-template let-resolution pTemplate="item">
    {{ resolution.label | translate }}
  </ng-template>
</p-multiSelect>
