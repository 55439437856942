import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { ActivityChildrenStore } from '~/activity/pages/activity-view/activity-children/activity-children.store';
import { FilterMetadata } from 'primeng/api/filtermetadata';

@Component({
  selector: 'app-activity-children',
  templateUrl: './activity-children.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActivityChildrenStore],
})
export class ActivityChildrenComponent {
  constructor(protected readonly cs: ActivityChildrenStore) {}

  protected load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    const filterMeta = event.filters?.['parentId'] as FilterMetadata;

    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        parentId: filterMeta.value as string,
      },
    });
  }
}
