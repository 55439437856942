import { IAuthTokenResponse, ILoginCredentials } from '../models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  constructor(private readonly http: HttpClient) {}

  public login(credentials: ILoginCredentials): Observable<IAuthTokenResponse> {
    return this.http.post<IAuthTokenResponse>('/api/auth-tokens', credentials);
  }

  public refresh(): Observable<IAuthTokenResponse> {
    return this.http.post<IAuthTokenResponse>(
      '/api/refresh-token-exchanges',
      {},
    );
  }

  public forgotPasswordRequest(email: string): Observable<IAuthTokenResponse> {
    return this.http.post<IAuthTokenResponse>('/api/password-reset-requests', {
      email,
    });
  }

  public restorePassword(
    token: string,
    newPassword: string,
  ): Observable<IAuthTokenResponse> {
    return this.http.post<IAuthTokenResponse>('/api/password-resets', {
      token,
      newPassword,
    });
  }
}
