import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { DividerModule } from 'primeng/divider';

import {
  CreateComponent,
  DictionariesComponent,
  ItemsListComponent,
  ItemUpdateComponent,
  ListComponent,
  UpdateComponent,
  ViewComponent,
} from './pages/dictionaries';
import { DictionaryRootModule } from './dictionary-root.module';
import { DictionaryFeatureModule } from './@feature';
import { DictionaryRoutingModule } from './dictionary-routing.module';
import { SharedExtModule } from '~/shared-ext.module';
import { SharedModule } from '~/shared.module';

@NgModule({
  imports: [
    ProgressBarModule,
    FileUploadModule,
    DictionaryRoutingModule,
    MenuModule,
    DividerModule,
    SharedExtModule,
    SharedModule,
  ],
  declarations: [
    DictionariesComponent,
    ListComponent,
    CreateComponent,
    UpdateComponent,
    ViewComponent,
    ItemsListComponent,
    ItemUpdateComponent,
  ],
})
export class DictionaryModule {
  static forRoot(): ModuleWithProviders<DictionaryRootModule> {
    return {
      ngModule: DictionaryRootModule,
    };
  }

  static forFeature(): ModuleWithProviders<DictionaryFeatureModule> {
    return {
      ngModule: DictionaryFeatureModule,
    };
  }
}
