import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ContactEmail } from '../../models';

@Component({
  selector: 'app-email-item',
  templateUrl: './email-item.component.html',
  styleUrls: ['./email-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailItemComponent {
  @Input() public item?: ContactEmail;
  @Input() public edit: boolean = true;
  @Output() public delete = new EventEmitter<ContactEmail>();
  @Output() public update = new EventEmitter<ContactEmail>();
}
