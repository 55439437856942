import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { MENU_ITEM_TOKEN } from '~/layout/menu-item.token';

import { activityFeatureKey, taskReducer } from './store/reducer';
import { activityRootEffects } from './store/effects';

const menuItemsFactory = (trans: TranslateService) => [
  {
    label: trans.instant('activity.menu.activity'),
    icon: 'pi pi-forward',
    items: [
      {
        label: trans.instant('activity.menu.activitiesList'),
        icon: 'pi pi-list',
        routerLink: ['/activities'],
      },
      {
        label: trans.instant('activity.menu.configuration'),
        icon: 'pi pi-cog',
        routerLink: ['/activities/configuration'],
      },
    ],
  },
];

@NgModule({
  imports: [
    StoreModule.forFeature(activityFeatureKey, taskReducer),
    EffectsModule.forFeature(activityRootEffects),
  ],
  providers: [
    {
      provide: MENU_ITEM_TOKEN,
      deps: [TranslateService],
      multi: true,
      useFactory: menuItemsFactory,
    },
  ],
})
export class ActivityRootModule {
  public constructor(
    @Optional() @SkipSelf() parentModule?: ActivityRootModule,
  ) {
    if (parentModule) {
      throw new Error(
        'ActivityRootModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
