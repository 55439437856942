import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SharedExtModule } from '~/shared-ext.module';

import { TableFilterComponent } from './table-filter.component';

@NgModule({
  declarations: [TableFilterComponent],
  imports: [SharedExtModule, InputTextModule],
  exports: [TableFilterComponent],
})
export class TableFilterModule {}
