@if (dictionaryItems$ | async; as items) {
  @if (multiple) {
    <p-multiSelect
      [ngModel]="value"
      (ngModelChange)="setValue($event)"
      [options]="items"
      [showClear]="true"
      optionLabel="value"
      optionValue="id"
      appendTo="body"
    ></p-multiSelect>
  } @else {
    <p-dropdown
      [ngModel]="singleValue"
      (ngModelChange)="setSingleValue($event)"
      [showClear]="true"
      [options]="items"
      optionLabel="value"
      optionValue="id"
      appendTo="body"
    ></p-dropdown>
  }
}
