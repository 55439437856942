@switch (cs.step$ | async) {
  @case (0) {
    <app-file-loader></app-file-loader>
  }

  @case (1) {
    @if (cs.isParsed$ | async) {
      <app-configurator
        [columnConfigurations]="columnConfigurations"
        [dataColumns]="$any(cs.dataColumns$ | async)"
        [data]="$any(cs.data$ | async)"
        (backStep)="cs.rePickFile()"
        (dataMapping)="cs.setImportMapping($event)"
      ></app-configurator>
    }
  }

  @case (2) {
    <div class="card flex gap-2">
      <p-button
        [label]="'common.button.startImport' | translate"
        (click)="cs.startImport()"
      ></p-button>
      <p-button
        [label]="'common.button.changeConfiguration' | translate"
        (click)="cs.rePickMapping()"
      ></p-button>
    </div>
    <div class="card">
      <app-data-preview
        [columnConfigurations]="columnConfigurations"
        [dataColumns]="$any(cs.dataColumns$ | async)"
        [data]="$any(cs.data$ | async)"
        [mapping]="$any(cs.mapping$ | async)"
      ></app-data-preview>
    </div>
  }

  @case (3) {
    <div class="card flex gap-2">
      <p-button
        (click)="cs.exportResults()"
        [disabled]="(cs.finished$ | async) === false"
        [label]="'common.button.exportImportResult' | translate"
      ></p-button>
    </div>
    <div class="card">
      <app-data-preview
        [columnConfigurations]="columnConfigurations"
        [dataColumns]="$any(cs.dataColumns$ | async)"
        [data]="$any(cs.data$ | async)"
        [mapping]="$any(cs.mapping$ | async)"
        [result]="$any(cs.result$ | async)"
      ></app-data-preview>
    </div>
  }
}
