import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';

import { refreshDictionaryItems } from '~/dictionary/store/dictionary-items/dictionary-items.actions';
import { selectItemsForDictionaryList } from '~/dictionary/store/dictionary-items/dictionary-items.selectors';

import { PersonHttpService, SlimContactHttpService } from '../../services/http';
import {
  ConfigurationValuesEnum,
  ContactDictionary,
  ContactRestriction,
  ContactType,
  Person,
  PersonPayload,
} from '../../models';
import { selectContactContactConfigurationMapFor } from '~/contact/store/configuration/configuration.selectors';
import { selectContactDictionaryList } from '~/contact/store/contact-dictionary/contact-dictionary-items.selectors';

export interface IPersonDataDialogState {
  person?: Person;
  busy: boolean;
}

const initialState = (person?: Person): IPersonDataDialogState => ({
  person: person,
  busy: false,
});

@Injectable()
export class PersonDataDialogStore extends ComponentStore<IPersonDataDialogState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  public readonly dictionaries$: Observable<ContactDictionary[]> = this.store
    .select(selectContactDictionaryList)
    .pipe(
      map((dictionaries) =>
        dictionaries.filter((d) =>
          [ContactRestriction.Person, ContactRestriction.Any].includes(
            d.contactType,
          ),
        ),
      ),
      tap((dictionaries) =>
        dictionaries.forEach((d) =>
          this.store.dispatch(
            refreshDictionaryItems({ dictionaryId: d.dictionary.id }),
          ),
        ),
      ),
    );

  public identConfiguration$ = this.store.select(
    selectContactContactConfigurationMapFor([
      ConfigurationValuesEnum.personIdentRequired,
      ConfigurationValuesEnum.personIdentRegex,
      ConfigurationValuesEnum.personIdentUnique,
    ]),
  );

  public dictionaryItems$ = (dictionaryId: string) =>
    this.store.select(selectItemsForDictionaryList(dictionaryId));

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly slimContactHttp: SlimContactHttpService,
    private readonly personService: PersonHttpService,
    private readonly store: Store,
    dialogConfig: DynamicDialogConfig,
  ) {
    super(initialState(dialogConfig.data?.person));
  }

  public readonly editPerson = this.effect<PersonPayload>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.select((state) => state.person)),
      switchMap(([data, person]) =>
        person ? this.updatePerson(person.id, data) : this.createPerson(data),
      ),
    );
  });

  public identIsUnique(ident: string) {
    return this.slimContactHttp
      .getList({ ident, type: ContactType.Person }, { page: 1, perPage: 1 })
      .pipe(
        withLatestFrom(this.select((state) => state.person)),
        switchMap(([contacts, person]) => {
          return of(
            contacts.items.length === 0 ||
              (!!person &&
                contacts.total === 1 &&
                contacts.items[0].id === person.id),
          );
        }),
      );
  }

  private logError(e: Error) {}

  private createPerson(data: PersonPayload) {
    return this.personService.create(data).pipe(
      tapResponse(
        (company) => {
          this.dialogRef.close(company);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }

  private updatePerson(id: string, data: PersonPayload) {
    return this.personService.update(id, data).pipe(
      tapResponse(
        (person) => {
          this.dialogRef.close(person);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }
}
