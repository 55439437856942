import { ComponentStore } from '@ngrx/component-store';
import { exhaustMap, Observable, take, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Activity } from '~/activity/models';
import { ActivityHttpService } from 'src/app/activity/services/http';
import { IListState, initialListState } from '~/@core/store/states';
import { ActivityFilter } from '~/activity/services/http/activity.payloads';
import { ICollection, IPagination } from '~/@core/models';
import { ActivityViewStore } from '~/activity/pages/activity-view/activity-view.store';
import { ActivityDataDialogService } from '~/activity/@common/services/dialogs';
import { Router } from '@angular/router';

export interface IActivitiesState
  extends IListState<Activity, ActivityFilter> {}

const initialState = (): IActivitiesState => ({
  ...initialListState<Activity, ActivityFilter>({}),
});

@Injectable()
export class ActivityChildrenStore extends ComponentStore<IActivitiesState> {
  public readonly activity$ = this.activityStore.activity$;

  public readonly loadedList$: Observable<ICollection<Activity>> = this.select(
    (state) => state.loadedList,
  );

  public readonly showPaginator$ = this.loadedList$.pipe(
    map((list) => list.total > 0),
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  constructor(
    private readonly activityHttpService: ActivityHttpService,
    private readonly activityStore: ActivityViewStore,
    private readonly activityDataDialogService: ActivityDataDialogService,
    private readonly router: Router,
  ) {
    super(initialState());
  }

  public readonly load = this.effect<{
    pagination: IPagination;
    filter: ActivityFilter;
  }>((loadParams$) => {
    return loadParams$.pipe(
      tap(({ filter, pagination }) => {
        this.setLoadParams({ filter, pagination });
      }),
      tap(() => this.fetchList()),
    );
  });

  public openCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.activity$),
      exhaustMap(([_, parent]) =>
        this.activityDataDialogService.open({ parent }).pipe(take(1)),
      ),
      tap(
        (activity) =>
          activity && this.router.navigate(['activities', activity.id]),
      ),
    );
  });

  public readonly openUpdateDialog = this.effect<Activity>((task$) =>
    task$.pipe(
      exhaustMap((task) =>
        this.activityDataDialogService.open({ activity: task }),
      ),
      tap((task) => task && this.updateItem(task)),
    ),
  );

  public readonly deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      exhaustMap((id) =>
        this.activityHttpService.delete(id).pipe(tap(() => this.fetchList())),
      ),
    );
  });

  private readonly fetchList = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      withLatestFrom(
        this.select((state) => state.filter),
        this.select((state) => state.pagination),
      ),
      switchMap(([_, filter, pagination]) =>
        this.activityHttpService
          .getList(filter, pagination)
          .pipe(tap((list) => this.setList(list))),
      ),
    );
  });

  private setList = this.updater((state, list: ICollection<Activity>) => ({
    ...state,
    loadedList: list,
    loading: false,
  }));

  private updateItem = this.updater<Activity>((state, item) => ({
    ...state,
    loadedList: {
      ...state.loadedList,
      items: state.loadedList.items.map((i) => (i.id === item.id ? item : i)),
    },
  }));

  private setLoadParams = this.updater<{
    filter: ActivityFilter;
    pagination: IPagination;
  }>((state, { filter, pagination }) => ({
    ...state,
    filter,
    pagination,
  }));

  private setLoading() {
    this.patchState({
      loading: true,
    });
  }
}
