import {
  ComponentStore,
  OnStoreInit,
  tapResponse,
} from '@ngrx/component-store';

import { Injectable } from '@angular/core';
import { DictionaryItemHttpService } from '../../../http';
import { DictionaryItemPayload, IDictionaryItem } from '../../../models';
import { DictionariesStore } from '../dictionaries.store';
import { Store } from '@ngrx/store';
import { switchMap, takeUntil } from 'rxjs/operators';
import {
  selectItemsForDictionaryList,
  selectItemsForDictionaryLoading,
} from '../../../store/dictionary-items/dictionary-items.selectors';
import { filter, Observable, of, withLatestFrom } from 'rxjs';
import {
  addDictionaryItem,
  refreshDictionaryItems,
  removeDictionaryItem,
  setDictionaryItem,
} from '../../../store/dictionary-items/dictionary-items.actions';

export interface IDictionaryItemsListState {
  filter: string | null;
  createBusy: boolean;
  editItemIds: Set<string>;
}

const initialState = (): IDictionaryItemsListState => ({
  filter: null,
  createBusy: false,
  editItemIds: new Set<string>(),
});

@Injectable()
export class ItemsListStore
  extends ComponentStore<IDictionaryItemsListState>
  implements OnStoreInit
{
  private readonly dictionaryId$ = this.dictionariesStore.dictionaryId$;
  public readonly items$: Observable<IDictionaryItem[]> =
    this.dictionaryId$.pipe(
      filter((dictionaryId) => !!dictionaryId),
      switchMap((dictionaryId) =>
        this.store.select(selectItemsForDictionaryList(dictionaryId as string)),
      ),
    );
  public readonly isItemInEditMode$ = (id: string) =>
    this.select((state) => state.editItemIds.has(id));

  public readonly isItemInViewMode$ = (id: string) =>
    this.select((state) => !state.editItemIds.has(id));

  private readonly filter$ = this.select((state) => state.filter);

  public readonly filteredItems$: Observable<IDictionaryItem[]> = this.select(
    this.filter$,
    this.items$,
    (filterTerm, items) => {
      if (!filterTerm) {
        return items;
      }
      return items.filter((item) =>
        item.value.toLowerCase().includes(filterTerm.toLowerCase()),
      );
    },
  );

  public readonly itemsLoading$: Observable<boolean> = this.dictionaryId$.pipe(
    switchMap((dictionaryId) =>
      dictionaryId
        ? this.store.select(selectItemsForDictionaryLoading(dictionaryId))
        : of(false),
    ),
  );

  constructor(
    private readonly itemService: DictionaryItemHttpService,
    private readonly dictionariesStore: DictionariesStore,
    private readonly store: Store,
  ) {
    super(initialState());
  }

  public ngrxOnStoreInit(): void {
    this.dictionaryId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dictionaryId) => {
        if (dictionaryId) {
          this.store.dispatch(refreshDictionaryItems({ dictionaryId }));
        }
      });
  }

  public createItem = this.effect<DictionaryItemPayload>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.dictionaryId$),
      filter(([, dictionaryId]) => !!dictionaryId),
      switchMap(([data, dictionaryId]) =>
        this.itemService.create(dictionaryId as string, data).pipe(
          tapResponse(
            (item) =>
              this.store.dispatch(
                addDictionaryItem({
                  dictionaryId: dictionaryId as string,
                  item,
                }),
              ),
            this.logError,
          ),
        ),
      ),
    );
  });

  public updateItem = this.effect<{
    id: string;
    payload: DictionaryItemPayload;
  }>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.dictionaryId$),
      filter(([, dictionaryId]) => !!dictionaryId),
      switchMap(([{ id, payload }, dictionaryId]) =>
        this.itemService.update(dictionaryId as string, id, payload).pipe(
          tapResponse((item) => {
            const action = setDictionaryItem({
              dictionaryId: dictionaryId as string,
              item,
            });
            this.store.dispatch(action);
            this.setItemToViewMode(item.id);
          }, this.logError),
        ),
      ),
    );
  });

  public deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.dictionaryId$),
      filter(([, dictionaryId]) => !!dictionaryId),
      switchMap(([id, dictionaryId]) =>
        this.itemService.delete(dictionaryId as string, id).pipe(
          tapResponse(() => {
            const action = removeDictionaryItem({
              dictionaryId: dictionaryId as string,
              itemId: id,
            });
            this.store.dispatch(action);
          }, this.logError),
        ),
      ),
    );
  });

  public filterList(filter: string | null) {
    this.setState(
      (state): IDictionaryItemsListState => ({
        ...state,
        filter,
      }),
    );
  }

  public setItemToEditMode(itemId: string) {
    this.setState(
      (state): IDictionaryItemsListState => ({
        ...state,
        editItemIds: new Set([...state.editItemIds, itemId]),
      }),
    );
  }

  public setItemToViewMode(itemId: string) {
    this.setState((state): IDictionaryItemsListState => {
      const items = new Set([...state.editItemIds]);
      items.delete(itemId);
      return {
        ...state,
        editItemIds: items,
      };
    });
  }

  private logError(e: Error) {
    console.log(e);
  }
}
