import * as Papa from 'papaparse';
import { ParseResult } from 'papaparse';
import { Observable } from 'rxjs';
import { AbstractFileParser } from '~/@core/modules/importer/parsers/abstract-file-parser';

export class CsvFileParser extends AbstractFileParser {
  public parse(): Observable<unknown[]> {
    return new Observable((observer) => {
      const { parseRows } = this.configuration;
      Papa.parse(this.file, {
        preview: parseRows,
        complete(results: ParseResult<unknown>) {
          observer.next(results.data);
        },
        error(error: Error) {
          observer.error(error);
        },
      });
    });
  }
}
