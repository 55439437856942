import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ContactEmail } from '../../models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactEmailUpdate } from '~/contact/modules/email/services/http/contact-email.payloads';

@Component({
  selector: 'app-email-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailEditComponent implements OnInit {
  @Input()
  public item?: ContactEmail;
  @Output()
  public cancel = new EventEmitter<void>();
  @Output()
  public save = new EventEmitter<ContactEmailUpdate>();

  public form?: FormGroup;

  public ngOnInit(): void {
    this.form = new FormGroup({
      note: new FormControl(this.item?.note || '', [Validators.maxLength(100)]),
    });
  }

  public submitForm() {
    this.save.emit({
      note: this.form?.value.note,
    });
  }
}
