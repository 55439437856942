import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-row-view-button',
  template: `
    <a
      pButton
      icon="pi pi-eye"
      severity="info"
      class="p-button-info h-2rem w-2rem"
      [pTooltip]="'common.button.view' | translate"
      tooltipPosition="top"
      type="button"
      [routerLink]="link"
    ></a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowViewButtonComponent {
  @Input() public link!: any;
}
