@if (sessionId) {
  <p class="mb-2">
    <span class="text-500">{{ "common.label.requestId" | translate }}: </span>
    <span class="font-semibold">{{ sessionId }}</span>
  </p>
}

<p-messages severity="warn">
  <ng-template pTemplate>
    @if (message) {
      {{ message }}
    } @else {
      {{ "common.text.someMessage" | translate }}
    }
  </ng-template>
</p-messages>

@if (error) {
  <div class="my-4">
    <p-inplace>
      <ng-template pTemplate="display">
        <span>View Content</span>
      </ng-template>
      <ng-template pTemplate="content">
        <pre style="overflow-x: auto" class="surface-card">{{
          error | json
        }}</pre>
      </ng-template>
    </p-inplace>
  </div>
}

<div class="flex justify-content-center gap-4 mt-4">
  <button
    pButton
    pRipple
    (click)="close()"
    icon="pi pi-exclamation-circle"
    class="p-button-danger"
    [label]="'common.button.close' | translate"
  ></button>
</div>
