import { NgModule } from '@angular/core';
import { CardDeleteButtonComponent } from './card-delete-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [CardDeleteButtonComponent],
  imports: [SharedExtModule],
  exports: [CardDeleteButtonComponent],
})
export class CardDeleteButtonModule {}
