import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { provideComponentStore } from '@ngrx/component-store';

import { AddressEditStore } from './address-edit.store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IContractAddressPayload } from '~/contact/modules/address/services/http/contact-address.payloads';

@Component({
  selector: 'app-address-create',
  templateUrl: './address-edit.component.html',
  providers: [provideComponentStore(AddressEditStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressEditComponent {
  public formGroup: FormGroup;

  constructor(
    public readonly cs: AddressEditStore,
    public readonly ref: DynamicDialogRef,
    readonly dialogConfig: DynamicDialogConfig,
  ) {
    this.formGroup = this.buildForm(dialogConfig.data?.address);
  }

  protected close() {
    this.ref.close();
  }

  protected save() {
    if (!this.formGroup.valid) return;

    this.cs.saveAddress({
      note: this.formGroup.value.note,
      firstAddressLine: this.formGroup.value.firstAddressLine,
      secondAddressLine: this.formGroup.value.secondAddressLine,
      country: this.formGroup.value.country,
      area: this.formGroup.value.area,
      region: this.formGroup.value.region,
      settlement: this.formGroup.value.settlement,
      zipCode: this.formGroup.value.zipCode,
    } as IContractAddressPayload);
  }

  private buildForm(data?: IContractAddressPayload) {
    return new FormGroup({
      country: new FormControl<string>(data?.country || '', [
        Validators.maxLength(100),
      ]),
      area: new FormControl<string>(data?.area || '', [
        Validators.maxLength(100),
      ]),
      region: new FormControl<string>(data?.region || '', [
        Validators.maxLength(100),
      ]),
      settlement: new FormControl<string>(data?.settlement || '', [
        Validators.maxLength(100),
      ]),
      firstAddressLine: new FormControl<string>(data?.firstAddressLine || '', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      secondAddressLine: new FormControl<string>(
        data?.secondAddressLine || '',
        [Validators.maxLength(100)],
      ),
      zipCode: new FormControl<string>(data?.zipCode || '', [
        Validators.maxLength(10),
      ]),
      note: new FormControl<string>(data?.note || '', [
        Validators.required,
        Validators.maxLength(255),
      ]),
    });
  }
}
