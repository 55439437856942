import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActivityViewStore } from '../activity-view.store';

@Component({
  selector: 'app-activity-info',
  templateUrl: './activity-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityInfoComponent {
  constructor(protected readonly cs: ActivityViewStore) {}
}
