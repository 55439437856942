import { ParseConfiguration } from '~/@core/modules/importer/parsers/parse-configuration';
import { AbstractFileParser } from '~/@core/modules/importer/parsers/abstract-file-parser';
import { CsvFileParser } from '~/@core/modules/importer/parsers/csv-file-parser';
import { Injectable } from '@angular/core';

@Injectable()
export class FileParserFactoryService {
  public static allowedMimeTypes = ['text/csv'];

  public createParser(
    file: File,
    configuration: ParseConfiguration,
  ): AbstractFileParser {
    const ext = file.name.split('.').pop();
    switch (ext) {
      case 'csv':
        return new CsvFileParser(file, configuration);
      default:
        throw new Error('Unsupported file type');
    }
  }
}
