import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { PhoneCreateComponent } from '../../components/phone-create';
import { ContactPhone } from '../../models';

@Injectable()
export class AddPhoneDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(data?: {
    contactId: string;
    phones?: ContactPhone[];
  }): Observable<ContactPhone | undefined> {
    const ref = this.dialogService.open(PhoneCreateComponent, {
      header: this.translateService.instant('contact.header.addPhone'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-6 lg:w-4',
      data,
    });
    return ref.onClose;
  }
}
