import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivityViewStore } from './activity-view.store';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrl: './activity-view.component.scss',
  providers: [ActivityViewStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityViewComponent implements OnInit, OnDestroy {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  private subs = new Subscription();

  constructor(
    protected readonly cs: ActivityViewStore,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.subs.add(
      this.activatedRoute.params.subscribe((params) => {
        this.cs.loadActivity(params['id']);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
