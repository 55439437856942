import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISubscription } from '../../models';
import {
  ISubscriptionFilter,
  ISubscriptionPayload,
} from './subscription.payloads';
import { HttpClient } from '@angular/common/http';
import { ICollection, IPagination } from '~/@core/models';
import {
  filterUndefinedParams,
  getAllItemsWithFilterFactory,
} from '~/@core/utils';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ISubscriptionFilter,
    ISubscription
  >(this.getList.bind(this));

  constructor(private readonly http: HttpClient) {}

  public getList(filter?: ISubscriptionFilter, pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };

    return this.http.post<ICollection<ISubscription>>(
      '/api/subscription-lists',
      {
        ...filterUndefinedParams({
          ...filter,
          ...paginationQuery,
        }),
      },
    );
  }

  public getAll(filter?: ISubscriptionFilter) {
    return this.getAllItems(filter);
  }

  public getOne(id: string): Observable<ISubscription> {
    return this.http.get<ISubscription>(`/api/subscriptions/${id}`);
  }

  public create(data: ISubscriptionPayload): Observable<ISubscription> {
    return this.http.post<ISubscription>('/api/subscriptions/', data);
  }

  public update(
    id: string,
    data: ISubscriptionPayload,
  ): Observable<ISubscription> {
    return this.http.put<ISubscription>(`/api/subscriptions/${id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/subscriptions/${id}`);
  }
}
