import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared.module';

import { AddressesListComponent } from './components/addresses-list';
import { AddressEditComponent } from './components/address-edit';
import { AddressDialogService } from '~/contact/modules/address/services/dialogs';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [AddressesListComponent, AddressEditComponent],
  imports: [SharedModule, SharedExtModule],
  exports: [AddressesListComponent],
  providers: [AddressDialogService],
})
export class AddressModule {}
