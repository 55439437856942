@if (isResolved) {
  <i class="pi pi-check mr-1"></i>
  @if (activity.resolvedAt) {
    <span>{{ activity.resolvedAt | date: "y-MM-dd" }}</span>
  } @else {
    <span class="text-red-300">{{
      "activity.label.noResolutionTime" | translate
    }}</span>
  }
} @else {
  @if (noEstimations) {
    <span class="text-red-300">{{
      "activity.label.noEstimations" | translate
    }}</span>
  } @else if (sameDateStartAndEnd) {
    <span
      >{{ activity.estimatedStartAt | date: "y-MM-dd" }}
      {{ activity.estimatedStartAt | date: "HH:mm" }} -
      {{ activity.estimatedEndAt | date: "HH:mm" }}</span
    >
  } @else {
    <span
      >{{ (activity.estimatedStartAt | date: "y-MM-dd") || "none" }}
      -
      {{ (activity.estimatedEndAt | date: "y-MM-dd") || "none" }}</span
    >
  }
}
