import { Component, Input } from '@angular/core';
import {
  ColorScheme,
  LayoutService,
  MenuMode,
} from '../../service/app.layout.service';
import { MenuService } from '../../components/menu/app.menu.service';

@Component({
  selector: 'app-config',
  templateUrl: './app.config.component.html',
})
export class AppConfigComponent {
  @Input() minimal: boolean = false;

  scales: number[] = [12, 13, 14, 15, 16];

  get colorScheme(): ColorScheme {
    return this.layoutService.config().colorScheme;
  }
  set colorScheme(_val: ColorScheme) {
    if (_val == 'dark') {
      this.layoutService.config.update((config) => ({
        ...config,
        layoutTheme: 'colorScheme',
      }));
    }
    this.layoutService.config.update((config) => ({
      ...config,
      colorScheme: _val,
    }));
  }

  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }
  set visible(_val: boolean) {
    this.layoutService.state.configSidebarVisible = _val;
  }

  get scale(): number {
    return this.layoutService.config().scale;
  }
  set scale(_val: number) {
    this.layoutService.config.update((config) => ({
      ...config,
      scale: _val,
    }));
  }

  get menuMode(): MenuMode {
    return this.layoutService.config().menuMode;
  }
  set menuMode(_val: MenuMode) {
    this.layoutService.config.update((config) => ({
      ...config,
      menuMode: _val,
    }));
    if (this.layoutService.isSlimPlus() || this.layoutService.isSlim()) {
      this.menuService.reset();
    }
  }

  constructor(
    public layoutService: LayoutService,
    public menuService: MenuService,
  ) {}

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  decrementScale() {
    this.scale--;
  }

  incrementScale() {
    this.scale++;
  }
}
