import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICollection, IPagination } from '../../../@core/models';
import { Company, CompanyPayload, ContactFilter } from '../../models';
import { Injectable } from '@angular/core';
import { ContactHttpService } from './contact.http-service';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService extends ContactHttpService<Company> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public create(payload: CompanyPayload): Observable<Company> {
    return this.http.post<Company>('/api/companies', payload);
  }

  public getList(
    filter: ContactFilter,
    pagination: IPagination,
  ): Observable<ICollection<Company>> {
    return this.http.get<ICollection<Company>>('/api/companies', {
      params: {
        ...filter,
        ...pagination,
      },
    });
  }

  public override getOne(id: string): Observable<Company> {
    return this.http.get<Company>(`/api/companies/${id}`);
  }

  public update(id: string, payload: CompanyPayload): Observable<Company> {
    return this.http.put<Company>(`/api/companies/${id}`, payload);
  }
}
