import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Person, PersonPayload } from '../../models';
import { ContactHttpService } from './contact.http-service';

@Injectable({
  providedIn: 'root',
})
export class PersonHttpService extends ContactHttpService<Person> {
  public create(payload: PersonPayload): Observable<Person> {
    return this.http.post<Person>('/api/persons', payload);
  }

  public update(id: string, payload: PersonPayload): Observable<Person> {
    return this.http.put<Person>(`/api/persons/${id}`, payload);
  }
}
