import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActivitiesComponent } from './pages/activities';
import { ActivityViewComponent } from './pages/activity-view';
import { ActivitiesImportComponent } from './pages/activities-import';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ActivitiesComponent,
        pathMatch: 'full',
      },
      {
        path: 'import',
        component: ActivitiesImportComponent,
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./modules/configuration').then((m) => m.ConfigurationModule),
      },
      {
        path: ':id',
        component: ActivityViewComponent,
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ActivityRoutingModule {}
