<div class="card">
  <div class="flex align-items-center justify-content-between gap-4 mb-4">
    <h3 class="text-900 text-xl font-bold block m-0">
      {{ "dictionary.header.itemsList" | translate }}
    </h3>
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        (input)="search($event)"
        [placeholder]="'common.label.search' | translate"
        class="w-full"
      />
    </span>
  </div>
  <p-divider></p-divider>
  @if (cs.filteredItems$ | async; as dictionaryItems) {
    <ul class="list-none p-0 m-0">
      @if ((canManageItems$ | async) === true) {
        <li class="px-2 py-4 border-bottom-1 surface-border">
          <form [formGroup]="form" (ngSubmit)="createItem()">
            <div class="flex gap-3 align-items-center">
              <input
                pInputText
                type="text"
                formControlName="value"
                [placeholder]="'dictionary.label.value' | translate"
                class="flex-grow-1"
              />
              <app-new-item-button [disabled]="form.invalid" />
            </div>
            <app-validation-errors formControlName="value">
              <app-validation-error formControlName="value" error="required" />
              <app-validation-error formControlName="value" error="minlength" />
              <app-validation-error formControlName="value" error="maxlength" />
              <app-validation-error
                formControlName="value"
                error="uniqueValue"
                [message]="'dictionary.error.valueNotUnique' | translate"
              />
            </app-validation-errors>
          </form>
        </li>
      }

      @for (item of dictionaryItems; track item.id) {
        <li
          class="flex flex-column gap-3 md:flex-row md:align-items-center p-2 border-bottom-1 surface-border"
        >
          @if (cs.isItemInViewMode$(item.id) | async) {
            <div class="flex-grow-1">
              <span>{{ item.value }}</span
              ><br />
              <small>{{ item.id }}</small>
            </div>
            <div class="flex align-items-end gap-2">
              <app-row-edit-button
                (edit)="cs.setItemToEditMode(item.id)"
                [disabled]="(canManageItems$ | async) === false"
              />
              <app-row-delete-button
                [disabled]="(canManageItems$ | async) === false"
                (delete)="cs.deleteItem(item.id)"
              />
            </div>
          }
          @if (cs.isItemInEditMode$(item.id) | async) {
            <app-dictionary-item-update
              [item]="item"
              (updateItem)="cs.updateItem({ id: item.id, payload: $event })"
              (cancelUpdate)="cs.setItemToViewMode(item.id)"
            ></app-dictionary-item-update>
          }
        </li>
      }
    </ul>
  }
</div>
