import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';

import { PeriodsStore } from './periods.store';
import { IPeriod, ISubscription } from '../../../models';
import { Store } from '@ngrx/store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';

@Component({
  selector: 'app-periods',
  templateUrl: './periods.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(PeriodsStore)],
})
export class PeriodsComponent implements OnInit {
  @Input() public subscription!: ISubscription;

  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  constructor(
    public readonly cs: PeriodsStore,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.cs.patchState({ subscription: this.subscription });
    this.cs.loadPeriods(this.subscription.id);
  }

  public deletePeriod(id: string): void {
    this.cs.deletePeriod(id);
  }

  protected noExpandData(row: IPeriod) {
    return row.dictionaryValues.length === 0 && !row.note;
  }
}
