<p-dropdown
  [options]="priorityOptions"
  [disabled]="disabled"
  [showClear]="showClear"
  id="subscriptionType"
  optionValue="value"
  [appendTo]="appendTo"
  [placeholder]="'activity.label.priority' | translate"
>
  <ng-template let-priority pTemplate="selectedItem">
    {{ priority.label | translate }}
  </ng-template>
  <ng-template let-priority pTemplate="item">
    {{ priority.label | translate }}
  </ng-template>
</p-dropdown>
