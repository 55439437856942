import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { provideComponentStore } from '@ngrx/component-store';
import { UserEditStore } from './user-edit.store';
import { User } from '~/user/models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  providers: [provideComponentStore(UserEditStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent {
  protected form: FormGroup;

  protected user?: User;

  protected roles = [
    {
      value: 'admin',
      label: 'user.role.admin',
    },
    {
      value: 'user',
      label: 'user.role.user',
    },
  ];

  protected statuses = [
    {
      value: 'active',
      label: 'user.status.active',
    },
    {
      value: 'disabled',
      label: 'user.status.disabled',
    },
  ];

  // TODO: this is fired 3 times on init
  private uniqueEmailValidator: AsyncValidatorFn = (control) => {
    return this.cs
      .emailIsUnique(control.value)
      .pipe(map((unique) => (unique ? null : { uniqueEmail: true })));
  };

  constructor(
    private readonly config: DynamicDialogConfig,
    public readonly cs: UserEditStore,
  ) {
    this.user = this.config.data?.user;
    this.form = this.buildForm(this.user);
  }

  protected submit(): void {
    if (this.form.invalid) return;
    if (this.user) {
      this.cs.updateUser(this.form.value);
    } else this.cs.createUser(this.form.value);
  }

  protected getRoleLabel(role: string): string {
    return this.roles.find((r) => r.value === role)?.label ?? '';
  }

  private buildForm(user?: User): FormGroup {
    const controls = {
      email: new FormControl(
        user?.email || '',
        [Validators.required, Validators.email],
        [this.uniqueEmailValidator],
      ),
      name: new FormControl(user?.name || '', [Validators.required]),
      status: new FormControl(user?.status || 'active', [Validators.required]),
      roles: new FormControl<string[]>(user?.roles || [], [
        Validators.required,
      ]),
    };

    return new FormGroup({
      ...controls,
      ...(!user
        ? { password: new FormControl('', [Validators.required]) }
        : {}),
    });
  }
}
