import { exhaustMap, take, tap, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Person } from '../../models';
import { PersonHttpService } from '../../services/http';
import {
  AbstractContactViewStore,
  IContactViewState,
} from '../abstract-contact-view.store';
import { PersonDialogService } from '~/contact/services/dialogs';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface IPersonViewState extends IContactViewState<Person> {
  contact?: Person;
  contactLoading: boolean;
}

const initialState = (): IPersonViewState => ({
  contactLoading: false,
});

@Injectable()
export class PersonViewStore extends AbstractContactViewStore<
  Person,
  IPersonViewState
> {
  public showUpdateDialog = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.contact$, (trigger, company) => company),
      exhaustMap((person) => this.personDialog.open({ person }).pipe(take(1))),
      tap((person) => person && this.setContact(person)),
    ),
  );

  constructor(
    protected readonly personService: PersonHttpService,
    protected override readonly errorDialogService: ErrorDialogService,
    protected readonly router: Router,
    protected readonly personDialog: PersonDialogService,
  ) {
    super(personService, errorDialogService, initialState());
  }

  protected override redirectAfterDelete() {
    this.router.navigate(['/contacts/persons']);
  }
}
