import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigValue } from '~/@core/models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private conf = new BehaviorSubject<Map<string, ConfigValue>>(new Map([]));

  public setConfig(config: ConfigValue[]) {
    const map = new Map<string, ConfigValue>(
      config.map((c) => [c.id, c] as [string, ConfigValue]),
    );
    this.conf.next(map);
  }

  public getConfigValue$(id: string) {
    return this.conf.pipe(map((config) => config.get(id)?.value));
  }
}
