<ng-container [formGroup]="form">
  <div class="grid p-fluid formgrid">
    <div class="col-12 field">
      <label for="ident" class="text-900 font-semibold">{{
        "contact.label.identifier" | translate
      }}</label>
      <input pInputText id="ident" formControlName="ident" />
    </div>
    <div class="col-12 field">
      <label for="email" class="text-900 font-semibold">{{
        "contact.label.emailAddress" | translate
      }}</label>
      <input pInputText id="email" formControlName="email" />
    </div>
    <div class="col-12 field">
      <label for="phone" class="text-900 font-semibold">{{
        "contact.label.phoneNumber" | translate
      }}</label>
      <input pInputText id="phone" formControlName="phone" />
    </div>
  </div>
</ng-container>
