<form
  class="grid formgrid p-fluid mt-4"
  [formGroup]="form"
  (ngSubmit)="submit()"
>
  @if (form.get("comment"); as control) {
    <div class="field col-12">
      <label for="note" class="text-900 font-semibold">{{
        "activity.label.comment" | translate
      }}</label>
      <app-editor id="note" [formControl]="$any(control)"></app-editor>
      <app-validation-errors [formControl]="$any(control)">
        <app-validation-error [formControl]="$any(control)" error="required" />
      </app-validation-errors>
    </div>
  }
</form>
