<form [formGroup]="form" (ngSubmit)="save()">
  <div class="grid formgrid p-fluid">
    <div class="field col-12">
      <label for="emailAddress" class="font-medium text-900 required">{{
        "contact.label.emailAddress" | translate
      }}</label>
      <input id="emailAddress" pInputText formControlName="address" />
      <app-validation-errors formControlName="address">
        <app-validation-error formControlName="address" error="required" />
        <app-validation-error formControlName="address" error="email" />
        <app-validation-error
          formControlName="address"
          error="emailExist"
          [message]="'contact.validation.emailAlreadyExists' | translate"
        />
      </app-validation-errors>
    </div>

    <div class="field col-12">
      <label for="note" class="font-medium text-900">{{
        "contact.label.note" | translate
      }}</label>
      <input pInputText id="note" formControlName="note" />
      <app-validation-errors formControlName="note">
        <app-validation-error formControlName="note" error="maxLength" />
      </app-validation-errors>
    </div>
  </div>

  <div class="flex gap-2 justify-content-end">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="form.invalid"></app-save-button>
  </div>
</form>
