import { createSelector } from '@ngrx/store';
import { selectDictionaryFeature } from '../feature.selector';
import { dictionaryItemsAdapter } from './dictionary-items.reducer';

const selectItemsSlice = createSelector(
  selectDictionaryFeature,
  (state) => state.items,
);
const selectItemsForDictionary = (dictionaryId: string) =>
  createSelector(selectItemsSlice, (state) => state[dictionaryId]);

export const selectItemsForDictionaryLoading = (dictionaryId: string) =>
  createSelector(
    selectItemsForDictionary(dictionaryId),
    (state) => state?.loading || false,
  );

export const selectItemsForDictionaryList = (dictionaryId: string) =>
  createSelector(selectItemsForDictionary(dictionaryId), (state) =>
    state?.entities
      ? dictionaryItemsAdapter.getSelectors().selectAll(state.entities)
      : [],
  );
