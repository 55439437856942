<div
  class="flex flex-row gap-3 align-items-center p-2 border-bottom-1 surface-border"
>
  <div
    class="flex-grow-1 text-700 flex gap-1 align-items-center phone-link font-semibold"
  >
    <span>{{ item.number }}</span>
    <a
      pButton
      pRipple
      icon="pi pi-phone"
      class="p-button-success h-2rem w-2rem"
      href="tel:{{ item.number }}"
    ></a>
    <a
      pButton
      pRipple
      icon="pi pi-link"
      class="p-button-primary h-2rem w-2rem"
      [routerLink]="['/contacts', 'phone-call']"
      [queryParams]="{ phone: item.number }"
    ></a>
  </div>
  <span class="w-3" [ngClass]="{ 'text-right': !edit }">
    {{ item.note || "-" }}</span
  >
  @if (edit) {
    <div class="flex-grow-0 flex align-items-end gap-1">
      <app-row-edit-button (edit)="update.emit(item)" />
      <app-row-delete-button (delete)="delete.emit(item)" />
    </div>
  }
</div>
