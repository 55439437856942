import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, pipe, throwError } from 'rxjs';

import { ErrorDialogComponent } from './error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(data: {
    message?: string;
    error?: any;
    sessionId?: string;
  }): Observable<boolean | undefined> {
    const ref = this.dialogService.open(ErrorDialogComponent, {
      header: this.translateService.instant('common.header.someError'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-8 md:w-6 lg:w-4',
      data,
    });
    return ref.onClose;
  }

  public openHttpError(error: HttpErrorResponse) {
    const ref = this.dialogService.open(ErrorDialogComponent, {
      header: this.translateService.instant('common.header.someError'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-8 md:w-6 lg:w-4',
      data: {
        message: error.error.message,
        error: error,
        sessionId: error.headers.get('X-Request-Id'),
      },
    });
    return ref.onClose;
  }

  public handleHttpError() {
    return pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          this.openHttpError(error);
          return caught;
        }
        return throwError(() => error);
      }),
    );
  }
}
