import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-action-edit-dialog-footer',
  template: `
    <app-cancel-button (click)="ref.close()"></app-cancel-button>
    <app-save-button
      (click)="this.saveButtonClicked.next()"
      [disabled]="(formBlocked | async) === true"
    ></app-save-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCommentEditDialogFooterComponent {
  protected saveButtonClicked: Subject<void>;
  protected formBlocked: BehaviorSubject<boolean>;

  constructor(
    protected ref: DynamicDialogRef,
    protected config: DynamicDialogConfig,
  ) {
    this.saveButtonClicked = this.config.data.footerSubmit;
    this.formBlocked = this.config.data.formBlocked;
  }
}
