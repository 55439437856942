<form class="flex flex-column gap-3" [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid formgrid p-fluid mt-4">
    @if (form.get("password"); as control) {
      <div class="field col-12">
        <label for="password" class="text-900 font-semibold">{{
          "user.label.password" | translate
        }}</label>
        <input
          pInputText
          [formControl]="$any(control)"
          id="password"
          type="password"
          autocomplete="new-password"
        />
        <app-validation-errors [formControl]="$any(control)">
          <app-validation-error
            [formControl]="$any(control)"
            error="required"
          />
        </app-validation-errors>
      </div>
    }
  </div>
  <div class="flex justify-content-end gap-2">
    <app-cancel-button (click)="cs.cancel()"></app-cancel-button>
    <app-save-button
      [disabled]="!form.valid || (cs.isBusy$ | async) === true"
    ></app-save-button>
  </div>
</form>
