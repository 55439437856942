import { Component, Input } from '@angular/core';
import { SubscriptionsStore } from '../subscriptions.store';
import { ISubscription } from '../../../models';
import { Store } from '@ngrx/store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';

@Component({
  selector: 'app-actions-button',
  templateUrl: './actions-button.component.html',
})
export class ActionsButtonComponent {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  @Input() subscription?: ISubscription;

  constructor(
    protected readonly cs: SubscriptionsStore,
    private store: Store,
  ) {}
}
