<div class="card" *ngIf="!!form" [formGroup]="form">
  <div class="mb-3 flex gap-2">
    <p-button
      [label]="'common.button.submit' | translate"
      [disabled]="form.invalid"
      (click)="next()"
    ></p-button>
    <p-button
      [label]="'common.button.pickAnotherFile' | translate"
      (click)="backStep.emit()"
    ></p-button>
  </div>
  <div class="grid p-fluid formgrid">
    <div
      *ngFor="let columnConfig of columnConfigurations"
      class="col-12 md:col-3 field"
    >
      <label
        >{{ columnConfig.name | translate }}
        @if (columnConfig.required) {
          <span>*</span>
        }
      </label>
      <p-dropdown
        [options]="dropDownOptions(columnConfig.key)"
        [showClear]="true"
        optionLabel="name"
        dataKey="key"
        [formControlName]="columnConfig.key"
      ></p-dropdown>
    </div>
  </div>
</div>

<div class="card" *ngIf="data">
  <p-table [value]="data.slice(0, 5)">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let head of dataColumns">
          <div>{{ head.name }}</div>
          <div
            *ngIf="columnMappingHeader.get(head.key) as importMapName"
            class="p-text-secondary text-500 mt-1"
          >
            <i class="pi pi-file-import mr-1"></i
            >{{ importMapName | translate }}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td *ngFor="let col of row">
          {{ col.length > 15 ? col.substring(0, 12) + "..." : col }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
