import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ConfirmDialogService } from '~/@core/modules/confirm';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-row-delete-button',
  template: `
    <button
      [disabled]="disabled"
      pButton
      pRipple
      icon="pi pi-trash"
      class="p-button-danger h-2rem w-2rem"
      (click)="$event.stopPropagation(); confirmDelete()"
      [pTooltip]="'common.button.delete' | translate"
      tooltipPosition="top"
      type="button"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowDeleteButtonComponent implements OnDestroy {
  @Input() public disabled = false;

  private subs = new Subscription();

  constructor(private readonly confirmDialogService: ConfirmDialogService) {}

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Output() protected delete = new EventEmitter<void>();

  protected confirmDelete(): void {
    const delSub = this.confirmDialogService.open().subscribe((result) => {
      if (result) {
        this.delete.emit();
      }
    });
    this.subs.add(delSub);
  }
}
