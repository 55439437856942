import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Activity } from '~/activity/models';
import { SlimContact } from '~/contact/models';

import {
  ActivityDataDialogComponent,
  ActivityDataDialogFooterComponent,
  ActivityDataDialogHeaderComponent,
} from '../../components/activity-data-dialog';

@Injectable()
export class ActivityDataDialogService {
  constructor(private readonly dialogService: DialogService) {}

  public open(data?: {
    activity?: Activity;
    contact?: SlimContact;
    parent?: Activity;
  }): Observable<Activity | undefined> {
    const ref = this.dialogService.open(ActivityDataDialogComponent, {
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-12 lg:w-10',
      data: {
        ...data,
        formBlocked: new BehaviorSubject(true),
        footerSubmit: new Subject<void>(),
      },
      templates: {
        footer: ActivityDataDialogFooterComponent,
        header: ActivityDataDialogHeaderComponent,
      },
    });

    return ref.onClose;
  }
}
