import { createAction, props } from '@ngrx/store';

import { ActivityStatus } from '~/activity/models';

export const checkActivityStatuses = createAction(
  '[Activity] Fetch activity statuses if needed',
);
export const fetchActivityStatuses = createAction(
  '[Activity] Fetch activity statuses',
);
export const fetchActivityStatusesSuccess = createAction(
  '[Activity] Fetch activity statuses success',
  props<{ items: ActivityStatus[] }>(),
);
export const fetchActivityStatusesFail = createAction(
  '[Activity] Fetch activity statuses fail',
  props<{ error: Error }>(),
);

export const addActivityStatus = createAction(
  '[Activity] Add activity status',
  props<{ item: ActivityStatus }>(),
);
export const updateActivityStatus = createAction(
  '[Activity] Update activity status',
  props<{ item: ActivityStatus }>(),
);
export const deleteActivityStatus = createAction(
  '[Activity] Delete activity status',
  props<{ id: string }>(),
);
