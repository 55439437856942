import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Activity, ActivityComment } from '~/activity/models';

import {
  ActivityCommentEditDialogComponent,
  ActivityCommentEditDialogFooterComponent,
} from '../../components/activity-comment-edit-dialog';

@Injectable()
export class ActivityCommentDataDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(data?: {
    comment?: ActivityComment;
    activity: Activity;
  }): Observable<ActivityComment | undefined> {
    const ref = this.dialogService.open(ActivityCommentEditDialogComponent, {
      header: data?.comment
        ? this.translateService.instant('activity.header.editComment')
        : this.translateService.instant('activity.header.addComment'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data: {
        ...data,
        formBlocked: new BehaviorSubject(true),
        footerSubmit: new Subject<void>(),
      },
      templates: {
        footer: ActivityCommentEditDialogFooterComponent,
      },
    });
    return ref.onClose;
  }
}
