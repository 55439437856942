import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { ContactCardStore } from './contact-card.store';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrl: './contact-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(ContactCardStore)],
})
export class ContactCardComponent {
  @Input() public set contactId(contactId: string | null) {
    if (contactId) {
      this.cs.loadContact(contactId);
    }
  }

  @Input() public slim = false;

  constructor(public readonly cs: ContactCardStore) {}
}
