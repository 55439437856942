<h1 class="text-700 text-3xl">
  {{ "contact.header.deletedContacts" | translate }}
</h1>

<div class="card">
  <p-table
    #dt
    *ngIf="cs.loadedList$ | async as list"
    [value]="list.items"
    [totalRecords]="list.total"
    [lazy]="true"
    [paginator]="true"
    [rows]="10"
    [filterDelay]="500"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [currentPageReportTemplate]="'common.text.pagination' | translate"
    [rowsPerPageOptions]="[10, 25, 50]"
    (onLazyLoad)="load($event)"
  >
    <ng-template pTemplate="caption">
      <app-table-header>
        <ng-container filter>
          <app-table-filter
            (filter)="dt.filterGlobal($event, 'equals')"
          ></app-table-filter>
        </ng-container>
      </app-table-header>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap">
          {{ "contact.label.identifier" | translate }}
        </th>
        <th class="white-space-nowrap">
          {{ "contact.label.name" | translate }}
        </th>
        <th class="white-space-nowrap"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-contact>
      <tr>
        <td>{{ contact?.identifier }}</td>
        <td>{{ contact.name }}</td>
        <td class="flex justify-content-end gap-1">
          <button
            pButton
            pRipple
            icon="pi pi-undo"
            severity="info"
            class="p-button-success h-2rem w-2rem"
            [pTooltip]="'contact.button.restore' | translate"
            (click)="cs.restoreItem(contact)"
            tooltipPosition="top"
            type="button"
          ></button>
          <app-row-delete-button
            (delete)="cs.deleteItem(contact.id)"
          ></app-row-delete-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
