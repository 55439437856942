import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';

import { SharedExtModule } from '~/shared-ext.module';

import { FileParserFactoryService } from './services';
import {
  ConfiguratorComponent,
  DataPreviewComponent,
  FileLoaderComponent,
  ImporterComponent,
} from './components/importer';

import { ImportButtonComponent } from './components/import-button';
import { ResultErrorsComponent } from './components/importer/result-errors/result-errors.component';

@NgModule({
  declarations: [
    ConfiguratorComponent,
    FileLoaderComponent,
    ImporterComponent,
    DataPreviewComponent,
    ImportButtonComponent,
    ResultErrorsComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    TableModule,
    PanelModule,
    StepsModule,
    ReactiveFormsModule,
    SharedExtModule,
    CheckboxModule,
  ],
  providers: [FileParserFactoryService],
  exports: [ImporterComponent, ImportButtonComponent],
})
export class ImporterModule {}
