import { Component } from '@angular/core';
import { DictionariesStore } from '../dictionaries.store';
import { Store } from '@ngrx/store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';

@Component({
  selector: 'app-dictionaries-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  protected canCreateDictionary$ = this.store.select(
    selectUserHasRole(['admin']),
  );

  constructor(
    protected store: Store,
    protected dictionariesStore: DictionariesStore,
  ) {}

  public search(event: Event) {
    const search = (event.target as HTMLInputElement).value;
    this.dictionariesStore.filterList({ search });
  }
}
