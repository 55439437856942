import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ContactPhone } from '../../models';

@Component({
  selector: 'app-phone-item',
  templateUrl: './phone-item.component.html',
  styles: `
    .phone-link {
      a {
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s,
          opacity 0.3s linear;
      }

      &:hover {
        a {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneItemComponent {
  @Input() public item!: ContactPhone;

  @Input() public edit: boolean = true;

  @Output() public delete = new EventEmitter<ContactPhone>();

  @Output() public update = new EventEmitter<ContactPhone>();
}
