import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-loading-space',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="bg-bluegray-50 px-2 py-5 flex flex-column align-items-center align-content-center border-round"
    >
      <p-progressSpinner
        styleClass="w-4rem h-4rem"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      ></p-progressSpinner>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class LoadingSpaceComponent {}
