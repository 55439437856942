import { Address } from './address';
import { ContactDictionaryValue } from './contact-dictionary-value';

export enum ContactType {
  Person = 'person',
  Company = 'company',
}

export type Contact = {
  id: string;
  type: ContactType;
  identifier?: string;
  note?: string;
  addresses: Address[];
  dictionaryValues: ContactDictionaryValue[];
};
