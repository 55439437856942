import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from 'primeng/editor';
import { DialogService } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TabViewModule } from 'primeng/tabview';
import { Store } from '@ngrx/store';

import { SharedModule } from '~/shared.module';

import { ContactFeatureModule } from './@feature';
import { ContactCommonModule } from './@common';
import { ContactRoutingModule } from './contact-routing.module';
import {
  CompaniesFilterComponent,
  CompaniesListComponent,
} from './pages/companies-list';
import { CompanyViewComponent } from './pages/company-view';
import {
  PersonsFilterComponent,
  PersonsListComponent,
} from './pages/persons-list';
import { PersonViewComponent } from './pages/person-view';
import {
  CompanyDataDialogComponent,
  CompanyDataDialogHeaderComponent,
} from './components/company-data-dialog';
import {
  PersonDataDialogComponent,
  PersonDataDialogHeaderComponent,
} from './components/person-data-dialog';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { PhoneModule } from './modules/phone';
import { EmailModule } from './modules/email';
import { ContactWidgetsComponent } from './components/contact-widgets/contact-widgets.component';
import { CompanyDialogService, PersonDialogService } from './services/dialogs';
import { CompanyImportComponent } from './pages/company-import';
import { PersonImportComponent } from './pages/person-import';
import { AddressModule } from './modules/address';
import { ContactRootModule } from './contact-root.module';

import { refreshContactConfiguration } from '~/contact/store/configuration/configuration.actions';

import { SharedExtModule } from '~/shared-ext.module';
import { BadgeModule } from 'primeng/badge';
import { MultiSelectModule } from 'primeng/multiselect';
import { DeletedContactsComponent } from './pages/deleted-contacts';
import { PhoneCallComponent } from './pages/phone-call';

@NgModule({
  imports: [
    SharedModule,
    SharedExtModule,
    ContactRoutingModule,
    ProgressBarModule,
    EditorModule,
    TabMenuModule,
    TieredMenuModule,

    ContactCommonModule,

    PhoneModule,
    EmailModule,
    AddressModule,
    TabViewModule,
    BadgeModule,
    MultiSelectModule,
  ],
  providers: [DialogService, CompanyDialogService, PersonDialogService],
  declarations: [
    CompaniesListComponent,
    CompaniesFilterComponent,
    CompanyViewComponent,
    CompanyDataDialogComponent,
    CompanyDataDialogHeaderComponent,
    CompanyImportComponent,

    PersonsListComponent,
    PersonsFilterComponent,
    PersonViewComponent,
    PersonDataDialogComponent,
    PersonDataDialogHeaderComponent,
    PersonImportComponent,

    DeletedContactsComponent,

    PhoneCallComponent,

    ContactInfoComponent,
    ContactWidgetsComponent,
  ],
})
export class ContactModule {
  constructor(store: Store) {
    store.dispatch(refreshContactConfiguration());
  }

  static forRoot(): ModuleWithProviders<ContactRootModule> {
    return {
      ngModule: ContactRootModule,
    };
  }

  static forFeature(): ModuleWithProviders<ContactFeatureModule> {
    return {
      ngModule: ContactFeatureModule,
    };
  }
}
