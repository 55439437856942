<ng-container [formGroup]="filterForm">
  <div class="grid p-fluid formgrid">
    <div class="col-12 field">
      <label for="type" class="text-900 font-semibold">{{
        "activity.label.type" | translate
      }}</label>
      <app-activity-multiple-type-dropdown
        id="type"
        formControlName="type"
        [showClear]="true"
      ></app-activity-multiple-type-dropdown>
    </div>
    <div class="col-12 field">
      <label for="resolution" class="text-900 font-semibold">{{
        "activity.label.resolution" | translate
      }}</label>
      <app-activity-resolution-dropdown
        id="resolution"
        formControlName="resolution"
        [showClear]="true"
      ></app-activity-resolution-dropdown>
    </div>
    <div class="col-12 field">
      <label for="actor" class="text-900 font-semibold">{{
        "activity.label.actor" | translate
      }}</label>
      <app-user-dropdown id="actor" formControlName="actor"></app-user-dropdown>
    </div>
    <div class="col-6 field">
      <label for="estimatedEndFrom" class="text-900 font-semibold">{{
        "activity.label.estimatedEndFrom" | translate
      }}</label>
      <p-calendar
        id="estimatedEndFrom"
        formControlName="estimatedEndFrom"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="estimatedEndTo" class="text-900 font-semibold">{{
        "activity.label.estimatedEndTo" | translate
      }}</label>
      <p-calendar
        id="estimatedEndTo"
        formControlName="estimatedEndTo"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>

    <div class="col-12" [ngClass]="{ 'mb-3': expandFilter }">
      @if (!expandFilter) {
        <span (click)="toggleFilter()" class="cursor-pointer">{{
          "common.label.showMore" | translate
        }}</span>
      } @else {
        <span (click)="toggleFilter()" class="cursor-pointer">{{
          "common.label.showLess" | translate
        }}</span>
      }
    </div>
  </div>
  <div
    class="grid p-fluid formgrid fadein"
    [ngClass]="{ hidden: !expandFilter }"
  >
    <div class="col-12 field">
      <label for="status" class="text-900 font-semibold">{{
        "activity.label.status" | translate
      }}</label>
      <app-activity-status-dropdown
        id="status"
        formControlName="status"
        [showClear]="true"
      ></app-activity-status-dropdown>
    </div>
    <div class="col-12 field">
      <label for="supervisor" class="text-900 font-semibold">{{
        "activity.label.supervisor" | translate
      }}</label>
      <app-user-dropdown
        id="supervisor"
        formControlName="supervisor"
      ></app-user-dropdown>
    </div>
    <div class="col-12 field">
      <label for="creator" class="text-900 font-semibold">{{
        "activity.label.creator" | translate
      }}</label>
      <app-user-dropdown
        id="creator"
        formControlName="creator"
      ></app-user-dropdown>
    </div>
    <div class="col-6 field">
      <label for="estimatedStartFrom" class="text-900 font-semibold">{{
        "activity.label.estimatedStartFrom" | translate
      }}</label>
      <p-calendar
        id="estimatedStartFrom"
        formControlName="estimatedStartFrom"
        [showClear]="true"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="estimatedStartTo" class="text-900 font-semibold">{{
        "activity.label.estimatedStartTo" | translate
      }}</label>
      <p-calendar
        id="estimatedStartTo"
        formControlName="estimatedStartTo"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="finishedFrom" class="text-900 font-semibold">{{
        "activity.label.finishedFrom" | translate
      }}</label>
      <p-calendar
        id="finishedFrom"
        formControlName="finishedFrom"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="finishedTo" class="text-900 font-semibold">{{
        "activity.label.finishedTo" | translate
      }}</label>
      <p-calendar
        id="finishedTo"
        formControlName="finishedTo"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="createdAtFrom" class="text-900 font-semibold">{{
        "activity.label.createdAtFrom" | translate
      }}</label>
      <p-calendar
        id="createdAtFrom"
        formControlName="createdAtFrom"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-6 field">
      <label for="createdAtTo" class="text-900 font-semibold">{{
        "activity.label.createdAtTo" | translate
      }}</label>
      <p-calendar
        id="createdAtTo"
        formControlName="createdAtTo"
        [showClear]="true"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="col-12 field">
      <label for="priority" class="text-900 font-semibold">{{
        "activity.label.priority" | translate
      }}</label>
      <app-activity-priority-dropdown
        id="priority"
        formControlName="priority"
        [showClear]="true"
      ></app-activity-priority-dropdown>
    </div>
  </div>
</ng-container>
