import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MENU_ITEM_TOKEN } from '~/layout/menu-item.token';

const menuItemsFactory = (trans: TranslateService) => [
  {
    label: trans.instant('subscription.menu.subscriptions'),
    icon: 'pi pi-undo',
    items: [
      {
        label: trans.instant('subscription.menu.subscriptions'),
        icon: 'pi pi-users',
        routerLink: ['/subscriptions'],
      },
      {
        label: trans.instant('subscription.menu.configuration'),
        icon: 'pi pi-cog',
        routerLink: ['/subscriptions/configuration'],
      },
    ],
  },
];

@NgModule({
  imports: [],
  providers: [
    {
      provide: MENU_ITEM_TOKEN,
      deps: [TranslateService],
      multi: true,
      useFactory: menuItemsFactory,
    },
  ],
})
export class SubscriptionRootModule {
  public constructor(
    @Optional() @SkipSelf() parentModule?: SubscriptionRootModule,
  ) {
    if (parentModule) {
      throw new Error(
        'SubscriptionRootModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
