import { createAction, props } from '@ngrx/store';

import { ActivityType } from '~/activity/models';

export const checkActivityTypes = createAction(
  '[Activity] Fetch activity types if needed',
);
export const fetchActivityTypes = createAction(
  '[Activity] Fetch activity types',
);
export const fetchActivityTypesSuccess = createAction(
  '[Activity] Fetch activity types success',
  props<{ items: ActivityType[] }>(),
);
export const fetchActivityTypesFail = createAction(
  '[Activity] Fetch activity types fail',
  props<{ error: Error }>(),
);

export const addActivityType = createAction(
  '[Activity] Add activity type',
  props<{ item: ActivityType }>(),
);
export const updateActivityType = createAction(
  '[Activity] Update activity type',
  props<{ item: ActivityType }>(),
);

export const deleteActivityType = createAction(
  '[Activity] Delete activity type',
  props<{ id: string }>(),
);
