import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Address } from '~/contact/models';

import { AddressEditComponent } from '../../components/address-edit';

@Injectable()
export class AddressDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(data?: {
    contactId: string;
    address?: Address;
  }): Observable<Address | undefined> {
    const ref = this.dialogService.open(AddressEditComponent, {
      header: data?.address
        ? this.translateService.instant('contact.header.editAddress')
        : this.translateService.instant('contact.header.addAddress'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data,
    });
    return ref.onClose;
  }
}
