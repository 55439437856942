import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ISubscription } from '~/subscription/models';

@Component({
  selector: 'app-subscription-data-dialog-header',
  template: `<div class="p-dialog-title">
    @if (subscription) {
      {{ 'subscription.header.editSubscription' | translate }}
      <div class="text-xs text-500 mt-1">{{ subscription.id }}</div>
    } @else {
      {{ 'subscription.header.addSubscription' | translate }}
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDataDialogHeaderComponent {
  protected subscription?: ISubscription;

  constructor(config: DynamicDialogConfig) {
    this.subscription = config.data?.subscription;
  }
}
