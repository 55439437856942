import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  interval,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ISubscriptionDictionaryData,
  SubscriptionDictionaryHttpService,
} from '../http';
import { SubscriptionDictionary } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDictionariesService implements OnDestroy {
  private items$: BehaviorSubject<Array<SubscriptionDictionary>> =
    new BehaviorSubject(new Array<SubscriptionDictionary>());
  private loading$ = new BehaviorSubject(false);
  private subs = new Subscription();

  constructor(private readonly http: SubscriptionDictionaryHttpService) {
    this.loadItems();
    this.createIntervalLoading();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public getItemsForType$(type: string | string[], forceLoading = false) {
    if (forceLoading) {
      this.loadItems();
    }
    return this.items$.pipe(
      map((items) => {
        if (typeof type === 'string') {
          return items.filter((i) => i.limitedByType.includes(type));
        }

        // show dictionaries which are limited by all types
        return items.filter((dictionary) => {
          return type.every((t) => dictionary.limitedByType.includes(t));
        });
      }),
    );
  }

  public getItems$(forceLoading = false) {
    if (forceLoading) {
      this.loadItems();
    }
    return this.items$;
  }

  public getItem$(id: string) {
    return this.items$.pipe(
      map((items) => items.find((i) => i.id === id)),
      filter((item) => !!item),
    );
  }

  public getLoading$() {
    return this.loading$;
  }

  public createItem(data: ISubscriptionDictionaryData) {
    return this.http.create(data).pipe(
      withLatestFrom(this.items$),
      tap(([item, items]) => {
        this.items$.next([...items, item]);
      }),
      map(([item, items]) => item),
    );
  }

  public updateItem(id: string, data: ISubscriptionDictionaryData) {
    return this.http.update(id, data).pipe(
      withLatestFrom(this.items$),
      tap(([item, items]) => {
        const index = items.findIndex((i) => i.id === item.id);
        items[index] = item;
        this.items$.next([...items]);
      }),
      map(([item, items]) => item),
    );
  }

  public deleteItem(id: string) {
    return this.http.delete(id).pipe(
      withLatestFrom(this.items$),
      tap(([_, items]) => {
        const index = items.findIndex((i) => i.id === id);
        items.splice(index, 1);
        this.items$.next([...items]);
      }),
    );
  }

  private createIntervalLoading() {
    const intSub = interval(300000)
      .pipe(
        withLatestFrom(this.loading$),
        filter(([_, loading]) => !loading),
      )
      .subscribe(() => {
        if (this.items$.observed) {
          this.loadItems();
        }
      });
    this.subs.add(intSub);
  }

  private loadItems() {
    this.loading$.next(true);
    this.http.getAll().subscribe((res) => {
      this.items$.next(res.items);
      this.loading$.next(false);
    });
  }
}
