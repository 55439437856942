import { Injectable } from '@angular/core';
import { catchError, exhaustMap, of, throwError } from 'rxjs';

import { isUUIDv4 } from '~/@core/utils';
import { PickContactService } from '~/contact/@feature/services/dialogs';
import {
  CompanyHttpService,
  SlimContactHttpService,
} from '~/contact/services/http';

@Injectable()
export class ContactGetterService {
  constructor(
    private readonly contactPickDialogService: PickContactService,
    private readonly slimContactHttpService: SlimContactHttpService,
    private readonly companyHttpService: CompanyHttpService,
  ) {}

  public getOrCreateContact$(
    identifier: string,
    predefined?: {
      identifier?: string;
      name: string;
    },
  ) {
    if (isUUIDv4(identifier)) {
      return this.slimContactHttpService.getOne(identifier).pipe(
        catchError(() => {
          return of(undefined);
        }),
      );
    }

    return this.slimContactHttpService
      .getAll({
        ident: identifier,
      })
      .pipe(
        exhaustMap((list) => {
          if (list.total === 0) {
            if (predefined && predefined.name) {
              return this.companyHttpService.create({
                identifier: predefined.identifier,
                shortName: predefined.name,
                dictionaryValues: [],
              });
            }
            return throwError(() => new Error('Contact not found'));
          }

          if (list.total === 1) return of(list.items[0]);

          return this.contactPickDialogService.open(list.items);
        }),
        catchError(() => {
          return of(undefined);
        }),
      );
  }
}
