import { ComponentStore } from '@ngrx/component-store';
import { exhaustMap, Observable, of, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Activity } from '~/activity/models';
import { ActivityHttpService } from 'src/app/activity/services/http';
import { IListState, initialListState } from '~/@core/store/states';
import { ActivityFilter } from '~/activity/services/http/activity.payloads';
import { ICollection, IPagination } from '~/@core/models';
import { Router } from '@angular/router';
import { ActivityDataDialogService } from '~/activity/@common/services/dialogs/activity-data-dialog.service';
import { ExportService } from '~/@core/modules/exporter/services';
import { ScrollContentService } from '~/layout/service/scroll-content.service';
import { tapResponse } from '@ngrx/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export interface IActivitiesState extends IListState<Activity, ActivityFilter> {
  parent?: Activity;
  isExporting: boolean;
}

const initialState = (): IActivitiesState => ({
  isExporting: false,
  ...initialListState<Activity, ActivityFilter>({}),
});

@Injectable()
export class ActivitiesStore extends ComponentStore<IActivitiesState> {
  public readonly loadedList$: Observable<ICollection<Activity>> = this.select(
    (state) => state.loadedList,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  public readonly parent$: Observable<Activity | undefined> = this.select(
    (state) => state.parent,
  );

  public readonly showPaginator$ = this.loadedList$.pipe(
    map((list) => list.total > 0),
  );

  public disabledExport$ = this.select(
    (state) =>
      state.isExporting ||
      state.loading ||
      state.loadedList?.total === 0 ||
      state.loadedList?.total > 500,
  );

  constructor(
    private readonly activityHttpService: ActivityHttpService,
    private readonly activityDataDialogService: ActivityDataDialogService,
    private readonly router: Router,
    private readonly scroller: ScrollContentService,
    private readonly exportService: ExportService,
  ) {
    super(initialState());
  }

  public readonly load = this.effect<{
    pagination: IPagination;
    filter: ActivityFilter;
  }>((loadParams$) => {
    return loadParams$.pipe(
      tap(({ filter, pagination }) => {
        this.setLoadParams({ filter, pagination });
      }),
      tap(() => this.fetchList()),
      tap(() => this.scroller.scrollToTop()),
    );
  });

  public loadParent = this.effect<string | undefined>((trigger$) => {
    return trigger$.pipe(
      switchMap((id) =>
        id ? this.activityHttpService.getOne(id) : of(undefined),
      ),
      tap((parent) => this.patchState({ parent })),
    );
  });

  public openCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      exhaustMap(() => this.activityDataDialogService.open()),
      tap(
        (activity) =>
          activity && this.router.navigate(['activities', activity.id]),
      ),
    );
  });

  public readonly openUpdateDialog = this.effect<Activity>((task$) =>
    task$.pipe(
      exhaustMap((task) =>
        this.activityDataDialogService.open({ activity: task }),
      ),
      tap((task) => task && this.updateItem(task)),
    ),
  );

  public readonly deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      exhaustMap((id) =>
        this.activityHttpService.delete(id).pipe(
          tapResponse(
            () => this.fetchList(),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  public readonly export = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.filter)),
      tap(() => this.patchState({ isExporting: true })),
      switchMap(([_, filter]) => this.activityHttpService.getAll(filter)),
      map((list) => {
        return list.items.reduce<Array<{ [key: string]: any }>>((acc, item) => {
          acc.push({
            id: item.id,
            title: item.title,
            actors: item.actors.map((actor) => actor.name).join(', '),
            description: item.description,
            type: item.type.name,
            status: item.status.name,
            start: item.estimatedStartAt,
            end: item.estimatedStartAt,
            contactIdent: item.contact?.identifier,
            contact: item.contact?.name,
            lastNoteActor: item.lastComment?.actor?.name,
            lastNoteTime: item.lastComment?.createdAt,
            lastNote: item.lastComment?.text,
            url:
              window.location.origin +
              this.router.serializeUrl(
                this.router.createUrlTree(['activities', item.id]),
              ),
          });
          return acc;
        }, []);
      }),
      switchMap((rows) => fromPromise(this.exportService.export(rows))),
      tap(() => this.patchState({ isExporting: false })),
    );
  });

  private readonly fetchList = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setLoading()),
      withLatestFrom(
        this.select((state) => state.filter),
        this.select((state) => state.pagination),
      ),
      switchMap(([_, filter, pagination]) =>
        this.activityHttpService.getList(filter, pagination).pipe(
          tapResponse(
            (list) => this.setList(list),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  private setList = this.updater((state, list: ICollection<Activity>) => ({
    ...state,
    loadedList: list,
    loading: false,
  }));

  private updateItem = this.updater<Activity>((state, item) => ({
    ...state,
    loadedList: {
      ...state.loadedList,
      items: state.loadedList.items.map((i) => (i.id === item.id ? item : i)),
    },
  }));

  private setLoadParams = this.updater<{
    filter: ActivityFilter;
    pagination: IPagination;
  }>((state, { filter, pagination }) => ({
    ...state,
    filter,
    pagination,
  }));

  private setLoading() {
    this.patchState({
      loading: true,
    });
  }

  private logError(e: Error) {}
}
