import { ComponentStore } from '@ngrx/component-store';

import { ActivityHttpService } from '../../services/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Activity, ActivityComment } from '~/activity/models';
import { exhaustMap, filter, take, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActivityDataDialogService } from '~/activity/@common/services/dialogs/activity-data-dialog.service';
import { ActivityCommentHttpService } from '~/activity/services/http/activity-comment.http-service';
import { ErrorDialogService } from '~/@core/modules/error-dialog';
import { ActivityCommentDataDialogService } from '~/activity/@common/services/dialogs';

export interface IActivityViewState {
  activity?: Activity;
  comments: ActivityComment[];
  loading: boolean;
  commentsLoading: boolean;
}

const initialState = (): IActivityViewState => ({
  loading: false,
  commentsLoading: false,
  comments: [],
});

@Injectable()
export class ActivityViewStore extends ComponentStore<IActivityViewState> {
  public activity$ = this.select((state) => state.activity);

  public comments$ = this.select((state) => state.comments);

  public loading$ = this.select(
    (state) => state.loading || state.commentsLoading,
  );

  public loaded$ = this.select((state) => !state.loading && !!state.activity);

  constructor(
    protected readonly activityHttpService: ActivityHttpService,
    protected readonly activityCommentHttpService: ActivityCommentHttpService,
    protected readonly router: Router,
    protected readonly dialogService: DialogService,
    protected readonly activityDialogService: ActivityDataDialogService,
    protected readonly commentDialogService: ActivityCommentDataDialogService,
    protected readonly errorDialogService: ErrorDialogService,
  ) {
    super(initialState());
  }

  public readonly loadActivity = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((id) =>
        this.activityHttpService.getOne(id).pipe(
          tap((activity) => {
            this.loadComments(id);
            this.patchState({ activity: activity, loading: false });
          }),
          this.errorDialogService.handleHttpError(),
        ),
      ),
    );
  });

  public readonly deleteActivity = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      withLatestFrom(
        this.select((state) => state.activity),
        (trigger, task) => task,
      ),
      filter((task) => !!task),
      switchMap((task) =>
        this.activityHttpService.delete((task as Activity).id).pipe(
          tap(() => this.router.navigate(['/activities'])),
          this.errorDialogService.handleHttpError(),
        ),
      ),
    );
  });

  public readonly openCreateCommentDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.activity$, (trigger, activity) => activity),
      filter((activity) => !!activity),
      exhaustMap((activity) =>
        this.commentDialogService
          .open({ activity: activity as Activity })
          .pipe(take(1)),
      ),
      tap((action) => action && this.addComment(action)),
    );
  });

  public readonly openUpdateCommentDialog = this.effect<ActivityComment>(
    (comment$) => {
      return comment$.pipe(
        withLatestFrom(this.activity$),
        filter((activity) => !!activity),
        exhaustMap(([comment, activity]) =>
          this.commentDialogService
            .open({ comment: comment, activity: activity as Activity })
            .pipe(take(1)),
        ),
        tap((comment) => comment && this.updateComment(comment)),
      );
    },
  );

  public readonly openActivityUpdateDialog = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.activity$, (trigger, activity) => activity),
      filter((activity) => !!activity),
      exhaustMap((activity) =>
        this.activityDialogService.open({ activity: activity }).pipe(take(1)),
      ),
      tap((task) => task && this.patchState({ activity: task })),
    ),
  );

  private readonly loadComments = this.effect<string>((id$) => {
    return id$.pipe(
      tap(() => this.patchState({ commentsLoading: true })),
      switchMap((id) =>
        this.activityCommentHttpService.getAll({ activityId: id }).pipe(
          tap((collection) =>
            this.patchState({
              comments: collection.items,
              commentsLoading: false,
            }),
          ),
          this.errorDialogService.handleHttpError(),
        ),
      ),
    );
  });

  private readonly addComment = this.updater(
    (state: IActivityViewState, item: ActivityComment): IActivityViewState => ({
      ...state,
      comments: [...state.comments, item],
    }),
  );

  private readonly updateComment = this.updater(
    (state: IActivityViewState, item: ActivityComment): IActivityViewState => ({
      ...state,
      comments: state.comments.map((comment) =>
        comment.id === item.id ? item : comment,
      ),
    }),
  );
}
