import { ComponentStore } from '@ngrx/component-store';
import { filter, Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ErrorDialogService } from '~/@core/modules/error-dialog';

import { Contact } from '../models';
import { ContactHttpService } from '../services/http';

export interface IContactViewState<TContact extends Contact> {
  contact?: TContact;
  contactLoading: boolean;
}

export abstract class AbstractContactViewStore<
  TContact extends Contact,
  TStore extends IContactViewState<TContact>,
> extends ComponentStore<TStore> {
  public readonly contact$: Observable<TContact | undefined> = this.select(
    (state) => state.contact,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.contactLoading,
  );

  public readonly loaded$: Observable<boolean> = this.select(
    (state) => !state.contactLoading && !!state.contact,
  );

  protected constructor(
    protected readonly contactService: ContactHttpService<TContact>,
    protected readonly errorDialogService: ErrorDialogService,
    initialState: TStore,
  ) {
    super(initialState);
  }

  public readonly deleteContact = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contact)),
      filter(([_, contact]) => !!contact),
      tap(() => this.setContactLoading(true)),
      switchMap(([_, company]) =>
        this.contactService.delete((company as Contact).id).pipe(
          tap(() => this.redirectAfterDelete()),
          this.errorDialogService.handleHttpError(),
        ),
      ),
    );
  });

  public readonly loadContact = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setContactLoading(true)),
      switchMap((id) =>
        this.contactService.getOne(id).pipe(
          tap((contact) => {
            this.setContactLoading(false);
            this.setContact(contact);
          }),
          this.errorDialogService.handleHttpError(),
        ),
      ),
    );
  });

  protected abstract redirectAfterDelete(): void;

  protected setContactLoading(busy: boolean) {
    this.setState(
      (state): TStore => ({
        ...state,
        contactLoading: busy,
      }),
    );
  }

  protected setContact(item: TContact) {
    this.setState(
      (state): TStore => ({
        ...state,
        contact: item,
        contactLoading: false,
      }),
    );
  }
}
