import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivityType } from '~/activity/models';

@Component({
  selector: 'app-activity-type-badge',
  templateUrl: './activity-type-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTypeBadgeComponent {
  @Input() type!: ActivityType;

  get fontColor(): string {
    const rgbFromHex = (hex: string) => {
      const bigint = parseInt(hex.replace('#', ''), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    };
    const { r, g, b } = rgbFromHex(this.type.color);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    const d = luminance > 0.5 ? 0 : 255; // bright colors - black font

    return `rgb(${d}, ${d}, ${d})`;
  }
}
