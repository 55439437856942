import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Activity, ActivityResolutionEnum } from '../../../models';

@Component({
  selector: 'app-activity-resolution-date',
  templateUrl: './activity-resolution-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityResolutionDateComponent {
  @Input() public activity!: Activity;

  protected get isResolved() {
    return this.activity.status.resolution === ActivityResolutionEnum.Finished;
  }

  protected get noEstimations() {
    return !this.activity.estimatedStartAt && !this.activity.estimatedStartAt;
  }

  protected get sameDateStartAndEnd() {
    if (!this.activity.estimatedStartAt || !this.activity.estimatedEndAt) {
      return false;
    }

    const start = new Date(this.activity.estimatedStartAt as string);
    const end = new Date(this.activity.estimatedEndAt as string);
    return start.toDateString() === end.toDateString();
  }
}
