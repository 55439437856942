<form
  class="flex gap-3 align-items-center w-100"
  [formGroup]="form"
  (ngSubmit)="update()"
>
  <input
    pInputText
    type="text"
    formControlName="value"
    placeholder="Value"
    class="flex-grow-1"
  />
  <div class="flex align-items-end gap-2">
    <app-row-confirm-button type="submit" />
    <app-row-cancel-button (click)="cancel()" />
  </div>
</form>
@if (form.get("value"); as control) {
  <app-validation-errors [formControl]="$any(control)">
    <app-validation-error [formControl]="$any(control)" error="required" />
    <app-validation-error [formControl]="$any(control)" error="minlength" />
    <app-validation-error [formControl]="$any(control)" error="maxlength" />
  </app-validation-errors>
}
