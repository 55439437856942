import { Provider } from '@angular/core';
import { IHooked } from '~/hook/models';
import { HOOKS } from '~/hook/tokens';
import { contactLinkWidget } from '~/contact/modules/link/standalone/hook';
import { contactTaskWidget } from '~/activity/standalone/contact-widget/hook';
import { contactSubscriptionsWidget } from '~/subscription/standalone/contact-widget';
import { footerUserWidget } from '~/user/standalone';
import { homepageActivitiesWidget } from '~/activity/standalone/homepage-widget/hook';
import { footerContactSearchWidget } from '~/contact/standalone/footer-contact-search/hook';

const hooks: IHooked[] = [
  // homepage hook
  homepageActivitiesWidget,

  // contact hook
  contactSubscriptionsWidget,
  contactLinkWidget,
  contactTaskWidget,

  // footer hooks
  footerContactSearchWidget,
  footerUserWidget,
];

export const HOOK_PROVIDERS = hooks.map<Provider>((hook) => ({
  provide: HOOKS,
  multi: true,
  useValue: hook,
}));
