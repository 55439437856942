<div class="card">
  <h1 class="text-900 text-xl font-bold mb-4 block">
    {{ "dictionary.header.dictionaries" | translate }}
  </h1>
  <div class="flex gap-4">
    <span class="p-input-icon-left flex-grow-1">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        (input)="search($event)"
        [placeholder]="'common.label.search' | translate"
        class="w-full"
      />
    </span>
    <app-new-item-button
      (add)="dictionariesStore.createDictionary()"
      [disabled]="(canCreateDictionary$ | async) === false"
    />
  </div>
  <p-divider></p-divider>

  @if (dictionariesStore.filteredDictionaries$ | async; as dictionaries) {
    <ul class="list-none p-0 m-0">
      @for (item of dictionaries; track item.id) {
        <li
          class="flex flex-column gap-3 md:flex-row md:align-items-center p-2 border-bottom-1 surface-border"
        >
          <div class="flex align-items-center flex-1">
            <label
              for="{{ item.id }}"
              style="word-break: break-word"
              class="font-medium ml-2"
              >{{ item.name }}</label
            >
          </div>
          <div class="flex align-items-end">
            <button
              pButton
              pRipple
              icon="pi pi-angle-right"
              class="p-button-rounded p-button-outlined"
              (click)="dictionariesStore.viewDictionary(item.id)"
            ></button>
          </div>
        </li>
      }
    </ul>
  }
</div>
