import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { provideComponentStore } from '@ngrx/component-store';

import { PersonEditStore } from './email-create.store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactEmail } from '~/contact/modules/email/models';

@Component({
  selector: 'app-email-create',
  templateUrl: './email-create.component.html',
  providers: [provideComponentStore(PersonEditStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailCreateComponent {
  protected form = new FormGroup({
    address: new FormControl('', [
      Validators.email,
      Validators.required,
      this.emailExistValidator.bind(this),
    ]),
    note: new FormControl('', [Validators.maxLength(100)]),
  });

  private emails?: ContactEmail[];

  constructor(
    protected readonly cs: PersonEditStore,
    protected readonly ref: DynamicDialogRef,
    readonly dialogConfig: DynamicDialogConfig,
  ) {
    this.emails = dialogConfig.data?.emails;
  }

  protected close() {
    this.ref.close();
  }

  protected save() {
    if (this.form.invalid) {
      return;
    }

    this.cs.saveEmail({
      address: this.form.value.address as string,
      note: this.form.value.note || '',
    });
  }

  private emailExistValidator(control: AbstractControl) {
    if (this.emails?.some((email) => email.address === control.value)) {
      return { emailExist: true };
    }

    return null;
  }
}
