import { ModuleWithProviders, NgModule } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { BadgeModule } from 'primeng/badge';
import { TimelineModule } from 'primeng/timeline';

import { SharedExtModule } from '~/shared-ext.module';
import { SharedModule } from '~/shared.module';
import { ContactFeatureModule } from '~/contact/@feature';
import { UserFeatureModule } from '~/user/@feature';
import { DictionaryFeatureModule } from '~/dictionary/@feature';

import { ActivityRoutingModule } from './activity-routing.module';
import { ActivityRootModule } from './@root/';
import { ActivityCommonModule } from './@common';
import {
  ActivitiesComponent,
  ActivitiesFilterComponent,
} from './pages/activities';
import {
  ActivityChildrenComponent,
  ActivityCommentsComponent,
  ActivityInfoComponent,
  ActivityParentComponent,
  ActivityViewComponent,
} from './pages/activity-view';
import { ActivitiesImportComponent } from './pages/activities-import';

@NgModule({
  declarations: [
    ActivitiesComponent,
    ActivityViewComponent,
    ActivityCommentsComponent,
    ActivitiesFilterComponent,
    ActivityChildrenComponent,
    ActivityInfoComponent,
    ActivityParentComponent,
    ActivitiesImportComponent,
  ],
  imports: [
    SharedModule,
    SharedExtModule,

    ActivityCommonModule,
    ActivityRoutingModule,

    ContactFeatureModule,
    UserFeatureModule,
    DictionaryFeatureModule,

    ChipModule,
    BadgeModule,
    TimelineModule,
  ],
})
export class ActivityModule {
  static forRoot(): ModuleWithProviders<ActivityRootModule> {
    return {
      ngModule: ActivityRootModule,
    };
  }
}
