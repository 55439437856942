import { activityTypeEntityAdapter } from './activity-type.reducer';
import { selectActivityFeature } from '../feature.selector';
import { createSelector } from '@ngrx/store';

const { selectAll } = activityTypeEntityAdapter.getSelectors();

const selectActivityTypeSlice = createSelector(
  selectActivityFeature,
  (s) => s.activityType,
);
export const selectActivityTypes = createSelector(
  selectActivityTypeSlice,
  selectAll,
);
export const selectActivityTypesCount = createSelector(
  selectActivityTypes,
  (s) => s?.length,
);
export const selectActivityTypesLoading = createSelector(
  selectActivityTypeSlice,
  (s) => s.loading,
);
export const selectActivityTypesLoaded = createSelector(
  selectActivityTypeSlice,
  (s) => s.lastLoadedTime,
);
