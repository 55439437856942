import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { HookWidgetsDirective } from '../../directives';
import { IHooked } from '~/hook/models';
import { HOOK_DATA } from '~/hook/tokens';

@Component({
  selector: 'app-dynamic-widget',
  template: `<ng-template appHookWidgets></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicWidgetComponent implements OnInit {
  @Input()
  public widget?: IHooked;

  @Input()
  public data?: any;

  @ViewChild(HookWidgetsDirective, { static: true })
  private widgetHost!: HookWidgetsDirective;

  constructor(
    private readonly injector: Injector,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (!this.widget) {
      return;
    }

    this.widget.import().then((component) => {
      this.widgetHost.viewContainerRef.createComponent(component, {
        injector: Injector.create({
          providers: [
            {
              provide: HOOK_DATA,
              useValue: this.data,
            },
          ],
          parent: this.injector,
        }),
      });
      this.cdRef.markForCheck();
    });
  }
}
