<form class="flex flex-column gap-3" [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 sm:col-6">
      <label for="owner" class="text-900 font-semibold required">{{
        "subscription.label.owner" | translate
      }}</label>
      @if (contact) {
        <div class="py-1">
          <p-chip label="{{ contact.name }}" id="contact"></p-chip>
        </div>
      } @else {
        <app-contact-search
          id="owner"
          formControlName="owner"
          appendTo="body"
        ></app-contact-search>
        <app-validation-errors formControlName="owner">
          <app-validation-error formControlName="owner" error="required" />
        </app-validation-errors>
      }
    </div>

    <div class="field col-12 sm:col-6">
      <label for="subscriptionType" class="text-900 font-semibold required">{{
        "subscription.label.type" | translate
      }}</label>
      <app-subscription-type-dropdown
        id="subscriptionType"
        formControlName="type"
        appendTo="body"
        [activeOnly]="true"
      ></app-subscription-type-dropdown>
      <app-validation-errors formControlName="type">
        <app-validation-error formControlName="type" error="required" />
      </app-validation-errors>
    </div>

    <div class="field col-12">
      <label for="note" class="text-900 font-semibold">{{
        "subscription.label.note" | translate
      }}</label>
      <app-editor
        formControlName="note"
        id="note"
        [style]="{ height: '100px' }"
      />
    </div>
  </div>

  @if (!subscription) {
    <fieldset>
      <legend>{{ "subscription.header.addPeriod" | translate }}</legend>
      <div class="grid formgrid p-fluid">
        <div class="field col-6">
          <label for="start" class="text-900 font-semibold required">{{
            "subscription.label.start" | translate
          }}</label>
          <p-calendar
            id="start"
            formControlName="start"
            styleClass="w-full"
            appendTo="body"
            dateFormat="yy-mm-dd"
          ></p-calendar>
        </div>

        <div class="field col-6">
          <label for="end" class="text-900 font-semibold required">{{
            "subscription.label.end" | translate
          }}</label>
          <p-calendar
            id="end"
            formControlName="end"
            styleClass="w-full"
            appendTo="body"
            dateFormat="yy-mm-dd"
          ></p-calendar>
        </div>

        <div class="field col-12">
          <label for="periodNote" class="text-900 font-semibold">{{
            "subscription.label.periodNote" | translate
          }}</label>
          <app-editor
            id="periodNote"
            formControlName="periodNote"
            [style]="{ height: '70px' }"
          />
        </div>

        <!-- Dictionary fields -->
        <div *ngFor="let dictionary of dictionaries" class="col-12 field">
          <label class="text-900 font-semibold">{{ dictionary.name }}</label>
          <app-dictionary-item-select
            [dictionary]="dictionary.dictionary"
            [multiple]="dictionary.multiple"
            [formControlName]="dictionaryFormKey(dictionary.id)"
          ></app-dictionary-item-select>
        </div>
      </div>
    </fieldset>
  }

  <div class="flex justify-content-end gap-2">
    <app-cancel-button (click)="cs.cancel()"></app-cancel-button>
    <app-save-button
      [disabled]="!form.valid || (cs.isBusy$ | async) === true"
    ></app-save-button>
  </div>
</form>
