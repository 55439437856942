import { ActionReducerMap } from '@ngrx/store';

import {
  activityTypeReducer,
  ActivityTypeState,
} from './activity-type/activity-type.reducer';
import {
  activityStatusReducer,
  ActivityStatusState,
} from './activity-status/activity-status.reducer';

export interface ActivityState {
  activityType: ActivityTypeState;
  activityStatus: ActivityStatusState;
}

export const taskReducer: ActionReducerMap<ActivityState> = {
  activityType: activityTypeReducer,
  activityStatus: activityStatusReducer,
};

export const activityFeatureKey = 'activity';
