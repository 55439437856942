import { Injectable } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects,
} from '@ngrx/effects';
import {
  fetchAllItems,
  fetchAllItemsFail,
  fetchAllItemsSuccess,
  refreshItems,
} from './contact-dictionary.actions';
import { map, mergeMap } from 'rxjs/operators';
import { catchError, EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectContactDictionaryLoading } from './contact-dictionary-items.selectors';
import { ContactDictionaryHttpService } from '~/contact/services/http';

@Injectable()
export class ContactDictionaryEffects implements OnInitEffects {
  /**
   * As we're not switching requests, we need to check if we're already loading
   */
  public readonly checkIfLoading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshItems),
      concatLatestFrom(() => this.store.select(selectContactDictionaryLoading)),
      mergeMap(([_, loading]) => (loading ? EMPTY : of(fetchAllItems()))),
    );
  });

  public readonly fetchAllDictionaryItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAllItems),
      mergeMap(() =>
        this.itemService.getAll().pipe(
          map((dictionaries) =>
            fetchAllItemsSuccess({
              list: dictionaries.items,
            }),
          ),
          catchError(() => of(fetchAllItemsFail())),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly itemService: ContactDictionaryHttpService,
    private readonly store: Store,
  ) {}

  public ngrxOnInitEffects() {
    return refreshItems();
  }
}
