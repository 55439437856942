import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IDictionaryItem } from '../../models';
import { createReducer, on } from '@ngrx/store';
import {
  addDictionaryItem,
  fetchAllDictionaryItems,
  fetchAllDictionaryItemsFail,
  fetchAllDictionaryItemsSuccess,
  removeDictionaryItem,
  setDictionaryItem,
} from './dictionary-items.actions';

export const dictionaryItemsAdapter = createEntityAdapter<IDictionaryItem>();

export interface DictionaryItemsState {
  [key: string]: {
    loading: boolean;
    entities: EntityState<IDictionaryItem>;
  };
}

const createDictionaryInitState = (): DictionaryItemsState => ({});

export const dictionaryItemsReducer = createReducer<DictionaryItemsState>(
  createDictionaryInitState(),
  on(
    fetchAllDictionaryItems,
    (state, { dictionaryId }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: true,
        entities:
          state[dictionaryId]?.entities ||
          dictionaryItemsAdapter.getInitialState(),
      },
    }),
  ),
  on(
    fetchAllDictionaryItemsSuccess,
    (state, { dictionaryId, list }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: false,
        entities: dictionaryItemsAdapter.setAll(
          list,
          state[dictionaryId].entities,
        ),
      },
    }),
  ),
  on(
    fetchAllDictionaryItemsFail,
    (state, { dictionaryId }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: false,
        entities: state[dictionaryId].entities,
      },
    }),
  ),
  on(
    addDictionaryItem,
    (state, { dictionaryId, item }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: false,
        entities: dictionaryItemsAdapter.addOne(
          item,
          state[dictionaryId].entities,
        ),
      },
    }),
  ),
  on(
    setDictionaryItem,
    (state, { dictionaryId, item }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: false,
        entities: dictionaryItemsAdapter.setOne(
          item,
          state[dictionaryId].entities,
        ),
      },
    }),
  ),
  on(
    removeDictionaryItem,
    (state, { dictionaryId, itemId }): DictionaryItemsState => ({
      ...state,
      [dictionaryId]: {
        loading: false,
        entities: dictionaryItemsAdapter.removeOne(
          itemId,
          state[dictionaryId].entities,
        ),
      },
    }),
  ),
);
