import { Component } from '@angular/core';
import { DictionariesStore } from './dictionaries.store';
import { provideComponentStore } from '@ngrx/component-store';

@Component({
  templateUrl: './dictionaries.component.html',
  providers: [provideComponentStore(DictionariesStore)],
})
export class DictionariesComponent {
  constructor(public readonly cs: DictionariesStore) {}
}
