import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ContactPhone } from '../../models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactPhoneUpdate } from '~/contact/modules/phone/services/http/contact-phone.payloads';

@Component({
  selector: 'app-phone-edit',
  templateUrl: './phone-edit.component.html',
  styles: `
    .p-inputtext {
      padding: 0.3rem 0.5rem;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneEditComponent implements OnInit {
  @Input()
  public item?: ContactPhone;

  @Output()
  public cancel = new EventEmitter<void>();

  @Output()
  public save = new EventEmitter<IContactPhoneUpdate>();

  public form?: FormGroup;

  public ngOnInit(): void {
    this.form = new FormGroup({
      note: new FormControl(this.item?.note || '', [Validators.maxLength(100)]),
    });
  }

  public submitForm() {
    this.save.emit({
      note: this.form?.value.note,
    });
  }
}
