import { createAction, props } from '@ngrx/store';
import { ContactDictionary } from '../../models';

export const addItem = createAction(
  '[Contact dictionaries] Add item',
  props<{ item: ContactDictionary }>(),
);
export const setItem = createAction(
  '[Contact dictionaries] Set item',
  props<{ item: ContactDictionary }>(),
);
export const removeItem = createAction(
  '[Contact dictionaries] Remove item',
  props<{ itemId: string }>(),
);
export const refreshItems = createAction(
  '[Contact dictionaries] Fetch all if not loading',
);
export const fetchAllItems = createAction('[Contact dictionaries] Fetch all');
export const fetchAllItemsSuccess = createAction(
  '[Contact dictionaries] Fetch all success',
  props<{ list: ContactDictionary[] }>(),
);
export const fetchAllItemsFail = createAction(
  '[Contact dictionaries] Fetch all fail',
);
