<div class="card">
  <p-fileUpload
    name="importfile"
    [accept]="mimeTypes"
    [maxFileSize]="1000000"
    [customUpload]="true"
    (onSelect)="parseFile($event)"
    (onClear)="clear()"
    (uploadHandler)="import($event)"
    [chooseLabel]="'common.button.chooseFile' | translate"
    [cancelLabel]="'common.button.cancel' | translate"
    [uploadLabel]="'common.button.configureImport' | translate"
    uploadLabel="Configure"
    uploadIcon="pi pi-cog"
  >
    <ng-template pTemplate="toolbar">
      <div *ngIf="data" class="py-4 flex align-items-center gap-2">
        <p-checkbox
          *ngIf="data"
          id="isFirstRowHeader"
          (onChange)="cs.isFirstRowHeader($event.checked)"
          [value]="cs.isFirstRowHeader$ | async"
          [binary]="true"
        ></p-checkbox>
        <label for="isFirstRowHeader">{{
          "common.label.isFirstRowHeader" | translate
        }}</label>
      </div>
    </ng-template>
  </p-fileUpload>
</div>

<div class="card" *ngIf="data">
  <p-table [value]="data">
    <ng-template pTemplate="body" let-row>
      <tr>
        <td *ngFor="let col of row">
          {{ col.length > 15 ? col.substring(0, 12) + "..." : col }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
