import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ContactHttpService } from 'src/app/contact/services/http';
import { Contact, ContactType } from '~/contact/models';
import { Injectable } from '@angular/core';

export interface IContactViewState {
  contact?: Contact;
  loading: boolean;
}

const initialState = (): IContactViewState => ({
  contact: undefined,
  loading: false,
});

@Injectable()
export class ContactCardStore extends ComponentStore<IContactViewState> {
  public readonly contact$: Observable<Contact | undefined> = this.select(
    (state) => state.contact,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  public readonly loaded$: Observable<boolean> = this.select(
    (state) => !state.loading && !!state.contact,
  );

  public link$ = this.select((state) => {
    switch (state.contact?.type) {
      case ContactType.Person:
        return ['/contacts/persons', state.contact.id];
      case ContactType.Company:
        return ['/contacts/companies', state.contact.id];
      default:
        return '';
    }
  });

  constructor(protected readonly contactService: ContactHttpService) {
    super(initialState());
  }

  public readonly loadContact = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((id) =>
        this.contactService.getOne(id).pipe(
          tapResponse(
            (contact) => this.patchState({ contact, loading: false }),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });
  protected logError(e: Error) {}
}
