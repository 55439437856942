import { DateTime } from 'luxon';

export const apiEndOfTheDay = (date: Date): string | undefined => {
  return DateTime.fromJSDate(date).endOf('day').toISO() || undefined;
};

export const apiStartOfTheDay = (date: Date): string | undefined => {
  return DateTime.fromJSDate(date).startOf('day').toISO() || undefined;
};

export const toApiDate = (
  date: Date | null | undefined,
): string | undefined => {
  return !!date
    ? DateTime.fromJSDate(date).toISODate() || undefined
    : undefined;
};
