import { Pipe, PipeTransform } from '@angular/core';
import { Company, Contact, ContactType, Person } from '~/contact/models';
import { personFullName } from '~/contact/utils';

@Pipe({ name: 'contactName' })
export class ContactNamePipe implements PipeTransform {
  transform(contact: Contact | null | undefined): string {
    switch (contact?.type) {
      case ContactType.Person:
        return personFullName((contact as Person).personName);
      case ContactType.Company:
        return (contact as Company).shortName;
      default:
        return '';
    }
  }
}
