import { Workbook } from 'exceljs';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class ExportService {
  public async export(rows: Array<{ [key: string]: any }>) {
    const workBook = this.prepareWorkBook();

    const sheet = workBook.addWorksheet('Export');
    const columnNames = rows.reduce<string[]>((acc, row) => {
      return Array.from(new Set([...acc, ...Object.keys(row)]));
    }, []);
    sheet.columns = columnNames.map((name) => ({ header: name, key: name }));
    rows.forEach((row) => sheet.addRow(row));

    const date = new Date();
    const file = `export-${date.getTime()}.xlsx`;
    const type =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    saveAs(
      await workBook.xlsx
        .writeBuffer()
        .then((buffer) => new Blob([buffer], { type })),
      file,
    );
  }

  private prepareWorkBook() {
    const workbook = new Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
  }
}
