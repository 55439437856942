import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Person } from '~/contact/models';

@Component({
  selector: 'app-person-data-dialog-header',
  template: ` <div class="p-dialog-title">
    @if (person) {
      {{ 'contact.header.editPerson' | translate }}: {{ person | contactName }}
      <div class="text-xs text-500 mt-1">{{ person.id }}</div>
    } @else {
      {{ 'contact.header.addPerson' | translate }}
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonDataDialogHeaderComponent {
  protected person?: Person;

  constructor(config: DynamicDialogConfig) {
    this.person = config.data?.person;
  }
}
