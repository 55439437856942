import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  SubscriptionType,
  SubscriptionTypeStatus,
} from '~/subscription/models';
import { SubscriptionTypesService } from '~/subscription/services/data';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-subscription-type-multiselect',
  templateUrl: './subscription-type-multiselect.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubscriptionTypeMultiselectComponent),
      multi: true,
    },
  ],
})
export class SubscriptionTypeMultiselectComponent
  implements ControlValueAccessor
{
  @ViewChild(MultiSelect, { static: true })
  protected dropdown!: MultiSelect;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public showClear = false;

  @Input()
  public activeOnly = false;

  protected types$: Observable<SubscriptionType[]> = this.subTypesService
    .getItems$()
    .pipe(
      map((types) =>
        types.filter((type) =>
          this.activeOnly
            ? type.status === SubscriptionTypeStatus.Active
            : !this.isDisabled(type),
        ),
      ),
    );

  protected loading$ = this.subTypesService.getLoading$();

  protected disabled = false;

  constructor(
    private readonly subTypesService: SubscriptionTypesService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public writeValue(val: any): void {
    this.dropdown.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  protected isArchived(type: SubscriptionType): boolean {
    return type.status === SubscriptionTypeStatus.Archived;
  }

  protected isDisabled(type: SubscriptionType): boolean {
    return type.status === SubscriptionTypeStatus.Disabled;
  }
}
