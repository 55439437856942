<p-dropdown
  [options]="(types$ | async) || []"
  [disabled]="(loading$ | async) === true || disabled"
  [showClear]="showClear"
  [placeholder]="'subscription.placeholder.selectType' | translate"
  [filter]="(types$ | async)!.length > 5"
  id="subscriptionType"
  optionLabel="name"
  appendTo="body"
>
  <ng-template let-type pTemplate="item">
    {{ type.name }}
    @if (isArchived(type)) {
      <span class="text-500"
        >({{ "subscription.status." + type.status | translate }})</span
      >
    }
  </ng-template>
</p-dropdown>
