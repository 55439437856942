import { IHooked } from '~/hook/models';
import { ContactSubscriptionsComponent } from './contact-subscriptions';

export const contactSubscriptionsWidget: IHooked<ContactSubscriptionsComponent> =
  {
    hookName: 'contact-small-extensions',
    widgetName: 'subscription.header.contactSubscriptions',
    widgetId: 'contact-subscriptions-list',
    import: () =>
      import(
        'src/app/subscription/standalone/contact-widget/contact-subscriptions'
      ).then((m) => m.ContactSubscriptionsComponent),
  };
