import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivityResolutionEnum, ActivityStatus } from '~/activity/models';
import { User } from '~/user/models';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { ActivityFilter } from '~/activity/services/http';
import { apiEndOfTheDay, apiStartOfTheDay } from '~/@core/utils/date-time';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-activities-filter',
  templateUrl: './activities-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesFilterComponent implements OnInit, OnDestroy {
  @Output()
  public filter = new EventEmitter<ActivityFilter>();

  protected filterForm = new FormGroup<{ [key: string]: AbstractControl }>({
    type: new FormControl<string[] | null>(null),
    resolution: new FormControl<ActivityResolutionEnum | undefined>(undefined),
    status: new FormControl<ActivityStatus | undefined>(undefined),
    actor: new FormControl<User | undefined>(undefined),
    supervisor: new FormControl<User | undefined>(undefined),
    creator: new FormControl<User | undefined>(undefined),
    estimatedEndFrom: new FormControl<Date | undefined>(undefined),
    estimatedEndTo: new FormControl<Date | undefined>(undefined),
    estimatedStartFrom: new FormControl<Date | undefined>(undefined),
    estimatedStartTo: new FormControl<Date | undefined>(undefined),
    finishedFrom: new FormControl<Date | undefined>(undefined),
    finishedTo: new FormControl<Date | undefined>(undefined),
    createdAtFrom: new FormControl<Date | undefined>(undefined),
    createdAtTo: new FormControl<Date | undefined>(undefined),
    priority: new FormControl<string | undefined>(undefined),
  });

  protected expandFilter = false;

  private subs = new Subscription();

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  public ngOnInit() {
    this.subs.add(
      this.filterForm.valueChanges
        .pipe(
          map(() => this.mapFilter()),
          // TODO: Find the reason why initial filter fired twice. Probably cause of the selector components.
          distinctUntilChanged((current, previous) => {
            return JSON.stringify(current) === JSON.stringify(previous);
          }),
        )
        .subscribe((filter) => {
          this.filter.emit(filter);
        }),
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected toggleFilter() {
    this.expandFilter = !this.expandFilter;
    this.cdRef.markForCheck();
  }

  private mapFilter(): ActivityFilter {
    const estimatedEndFrom = this.filterForm.get('estimatedEndFrom')?.value;
    const estimatedEndTo = this.filterForm.get('estimatedEndTo')?.value;
    const estimatedStartFrom = this.filterForm.get('estimatedStartFrom')?.value;
    const estimatedStartTo = this.filterForm.get('estimatedStartTo')?.value;
    const finishedFrom = this.filterForm.get('finishedFrom')?.value;
    const finishedTo = this.filterForm.get('finishedTo')?.value;
    const createdAtFrom = this.filterForm.get('createdAtFrom')?.value;
    const createdAtTo = this.filterForm.get('createdAtTo')?.value;

    return {
      typeId: this.filterForm.get('type')?.value,
      statusId: this.filterForm.get('status')?.value?.id || undefined,
      actorId: this.filterForm.get('actor')?.value?.id || undefined,
      supervisorId: this.filterForm.get('supervisor')?.value?.id || undefined,
      creatorId: this.filterForm.get('creator')?.value?.id || undefined,
      startFrom: estimatedStartFrom
        ? apiStartOfTheDay(estimatedStartFrom)
        : undefined,
      startTo: estimatedStartTo ? apiEndOfTheDay(estimatedStartTo) : undefined,
      endFrom: estimatedEndFrom
        ? apiStartOfTheDay(estimatedEndFrom)
        : undefined,
      endTo: estimatedEndTo ? apiEndOfTheDay(estimatedEndTo) : undefined,
      finishedAtFrom: finishedFrom ? apiStartOfTheDay(finishedFrom) : undefined,
      finishedAtTo: finishedTo ? apiEndOfTheDay(finishedTo) : undefined,
      createdAtFrom: createdAtFrom
        ? apiStartOfTheDay(createdAtFrom)
        : undefined,
      createdAtTo: createdAtTo ? apiEndOfTheDay(createdAtTo) : undefined,
      priority: this.filterForm.get('priority')?.value || undefined,
      resolution: this.filterForm.get('resolution')?.value || undefined,
    };
  }
}
