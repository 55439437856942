import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { catchError, EMPTY, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

import { ActivityTypeHttpService } from '~/activity/services/http';
import {
  checkActivityTypes,
  fetchActivityTypes,
  fetchActivityTypesFail,
  fetchActivityTypesSuccess,
} from './activity-type.actions';
import {
  selectActivityTypesLoaded,
  selectActivityTypesLoading,
} from './activity-type.selectors';

@Injectable()
export class ActivityTypeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ActivityTypeHttpService,
    private readonly store: Store,
  ) {}

  public fetchIfEmptyOrTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkActivityTypes),
      withLatestFrom(
        this.store.select(selectActivityTypesLoaded),
        this.store.select(selectActivityTypesLoading),
        (_, loaded, loading) => ({
          loaded,
          loading,
        }),
      ),
      switchMap(({ loading, loaded }) => {
        if (loading) {
          return EMPTY;
        }
        if (!loaded || this.isLoadTimeExceed(loaded)) {
          return of(fetchActivityTypes());
        }
        return EMPTY;
      }),
    );
  });

  public fetchTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchActivityTypes),
      switchMap(() =>
        this.service.getAll().pipe(
          map((linkTypes) =>
            fetchActivityTypesSuccess({ items: linkTypes.items }),
          ),
          catchError((error) => of(fetchActivityTypesFail({ error }))),
        ),
      ),
    );
  });

  private isLoadTimeExceed = (lastLoadedTime: Date): boolean => {
    return new Date().getTime() - lastLoadedTime.getTime() > 30000;
  };
}
