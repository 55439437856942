import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICollection, IPagination } from '~/@core/models';
import { getAllItemsFactory } from '~/@core/utils';

import { ActivityStatus } from '../../models';
import { ActivityStatusData } from './activity-status.payloads';

@Injectable({
  providedIn: 'root',
})
export class ActivityStatusHttpService {
  private readonly getAllItems = getAllItemsFactory<ActivityStatus>(
    this.getList.bind(this),
  );

  constructor(private readonly http: HttpClient) {}

  public create(taskStatusData: ActivityStatusData) {
    return this.http.post<ActivityStatus>(
      '/api/activity-statuses',
      taskStatusData,
    );
  }

  public getList(pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<ActivityStatus>>(
      '/api/activity-statuses',
      {
        params: {
          ...paginationQuery,
        },
      },
    );
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(id: string, linkType: ActivityStatusData) {
    return this.http.put<ActivityStatus>(
      `/api/activity-statuses/${id}`,
      linkType,
    );
  }

  public delete(id: string) {
    return this.http.delete<void>(`/api/activity-statuses/${id}`);
  }
}
