import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  exhaustMap,
  filter,
  Observable,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContactPhone } from '../../models';
import { ContactPhoneHttpService } from '../../services/http';
import { IContactPhoneUpdate } from '~/contact/modules/phone/services/http/contact-phone.payloads';
import { AddPhoneDialogService } from '../../services/dialogs';

interface IPhonesListState {
  loading: boolean;
  phones: ContactPhone[];
  editItemIds: Set<string>;
  contactId?: string;
}

const initialState = (): IPhonesListState => ({
  loading: false,
  phones: [],
  editItemIds: new Set<string>(),
});

@Injectable()
export class PhonesListStore extends ComponentStore<IPhonesListState> {
  public readonly phones$: Observable<ContactPhone[]> = this.select(
    (state) => state.phones,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  public readonly isItemInEditMode$ = (id: string) =>
    this.select((state) => state.editItemIds.has(id));

  public readonly isItemInViewMode$ = (id: string) =>
    this.select((state) => !state.editItemIds.has(id));

  constructor(
    private readonly contactPhoneService: ContactPhoneHttpService,
    private readonly addPhoneDialogService: AddPhoneDialogService,
  ) {
    super(initialState());
  }

  public showCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(
        this.select((state) => state.contactId),
        this.select((state) => state.phones),
      ),
      filter(([_, contactId]) => !!contactId),
      exhaustMap(([_, contactId, phones]) =>
        this.addPhoneDialogService
          .open({
            contactId: contactId as string,
            phones,
          })
          .pipe(take(1)),
      ),
      tap((phone?: ContactPhone) => {
        if (phone) {
          this.addPhone(phone);
        }
      }),
    );
  });

  public setItemToEditMode(itemId: string) {
    this.setState(
      (state): IPhonesListState => ({
        ...state,
        editItemIds: new Set([...state.editItemIds, itemId]),
      }),
    );
  }

  public setItemToViewMode(itemId: string) {
    this.setState((state): IPhonesListState => {
      const items = new Set([...state.editItemIds]);
      items.delete(itemId);
      return {
        ...state,
        editItemIds: items,
      };
    });
  }

  public updateItem = this.effect<{
    number: string;
    payload: IContactPhoneUpdate;
  }>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([, contactId]) => !!contactId),
      switchMap(([{ number, payload }, contactId]) =>
        this.contactPhoneService
          .update(contactId as string, number, payload)
          .pipe(
            tapResponse((item) => {
              this.setPhone(item);
              this.setItemToViewMode(item.number);
            }, this.logError),
          ),
      ),
    );
  });

  public deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([, contactId]) => !!contactId),
      switchMap(([number, contactId]) =>
        this.contactPhoneService
          .delete(contactId as string, number)
          .pipe(tapResponse(() => this.removePhone(number), this.logError)),
      ),
    );
  });

  public readonly loadPhones = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap((contactId) => this.patchState({ contactId })),
      tap(() => this.setPhonesLoading(true)),
      switchMap((id) =>
        this.contactPhoneService.getAll(id).pipe(
          tapResponse(
            (phones) => this.setPhones(phones),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
    );
  });

  private setPhonesLoading(busy: boolean) {
    this.setState(
      (state): IPhonesListState => ({
        ...state,
        loading: busy,
      }),
    );
  }

  private setPhones(items: ContactPhone[]) {
    this.setState(
      (state): IPhonesListState => ({
        ...state,
        phones: items,
        loading: false,
      }),
    );
  }

  private addPhone(item: ContactPhone) {
    this.setState(
      (state): IPhonesListState => ({
        ...state,
        phones: [...state.phones, item],
      }),
    );
  }

  private setPhone(item: ContactPhone) {
    this.setState((state): IPhonesListState => {
      const phones = [...state.phones];
      const index = phones.findIndex((i) => i.number === item.number);
      phones[index] = item;
      return {
        ...state,
        phones,
      };
    });
  }

  private removePhone(number: string) {
    this.setState((state): IPhonesListState => {
      const phones = [...state.phones];
      const index = phones.findIndex((i) => i.number === number);
      phones.splice(index, 1);
      return {
        ...state,
        phones,
      };
    });
  }

  private logError(e: Error) {}
}
