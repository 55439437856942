import { Component } from '@angular/core';
import {
  ImportColumnConfiguration,
  ImportResult,
} from '~/@core/modules/importer/models';
import { PersonHttpService } from 'src/app/contact/services/http';
import { catchError, forkJoin, Observable, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactPhoneHttpService } from 'src/app/contact/modules/phone/services/http';
import { ContactEmailHttpService } from 'src/app/contact/modules/email/services/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-person-import',
  templateUrl: './person-import.component.html',
})
export class PersonImportComponent {
  public readonly columnConfigurations: ImportColumnConfiguration[] = [
    {
      key: 'identifier',
      name: 'contact.label.identifier',
      required: false,
      type: 'string',
    },
    {
      key: 'firstName',
      name: 'contact.label.firstName',
      required: false,
      type: 'string',
    },
    {
      key: 'middleName',
      name: 'contact.label.middleName',
      required: false,
      type: 'string',
    },
    {
      key: 'lastName',
      name: 'contact.label.lastName',
      required: false,
      type: 'string',
    },
    {
      key: 'description',
      name: 'contact.label.description',
      required: false,
      type: 'string',
    },
    {
      key: 'phones',
      name: 'contact.label.phones',
      required: false,
      type: 'string',
    },
    {
      key: 'emails',
      name: 'contact.label.emails',
      required: false,
      type: 'string',
    },
  ];

  constructor(
    private readonly personService: PersonHttpService,
    private readonly phoneService: ContactPhoneHttpService,
    private readonly emailService: ContactEmailHttpService,
    private readonly router: Router,
  ) {}

  public importFunction = (data: any): Observable<ImportResult> => {
    const errors: string[] = [];
    if (!data?.firstName && !data?.name) {
      errors.push('"firstName" or "name" is an required');
      return of({
        resultId: undefined,
        errors,
      });
    }

    return this.personService
      .create({
        identifier: data?.identifier,
        name: this.getName(data),
        dictionaryValues: [], // TODO: Implement dictionary values import
      })
      .pipe(
        switchMap((person) => {
          const phoneNumbers = data?.phones?.split(',');
          const emailAddresses = data?.emails?.split(',');

          if (!emailAddresses?.length && !phoneNumbers?.length) {
            return of({
              resultId: person.id,
              resultLink: this.createLink(person.id),
              errors,
            });
          }

          const phoneRequests =
            phoneNumbers?.map((number: string) =>
              this.phoneService
                .create(person.id, {
                  number,
                  note: 'imported',
                })
                .pipe(
                  catchError((err: HttpErrorResponse, caught) => {
                    errors.push(err.message);
                    return caught;
                  }),
                ),
            ) || [];

          const emailRequests =
            emailAddresses?.map((address: string) =>
              this.emailService
                .create(person.id, {
                  address,
                  note: 'imported',
                })
                .pipe(
                  catchError((err: HttpErrorResponse, caught) => {
                    errors.push(err.message);
                    return caught;
                  }),
                ),
            ) || [];

          return forkJoin([...phoneRequests, ...emailRequests]).pipe(
            switchMap(() =>
              of({
                resultId: person.id,
                resultLink: this.createLink(person.id),
                errors,
              }),
            ),
          );
        }),
        catchError((err: HttpErrorResponse) =>
          of({
            resultId: undefined,
            errors: [err.message],
          }),
        ),
      );
  };

  private getName(data: any) {
    if (data?.name) {
      const nameParts = (data.name as string).split(' ');
      const firstName = nameParts.shift() as string;

      if (nameParts.length === 1) {
        const lastName = nameParts[0];
        return {
          firstName,
          lastName,
        };
      }

      const lastName = nameParts.pop();
      const middleName = nameParts.join(' ');
      return {
        firstName,
        middleName,
        lastName,
      };
    }

    return {
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
    };
  }

  private createLink(id: string) {
    return (
      window.location.origin +
      this.router.serializeUrl(
        this.router.createUrlTree(['/contacts/persons', id]),
      )
    );
  }
}
