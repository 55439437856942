import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactPhoneHttpService } from '../../services/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IContactPhoneCreate } from '~/contact/modules/phone/services/http/contact-phone.payloads';

export interface IPhoneCreateState {
  contactId: string;
  busy: boolean;
}

const initialState = (contactId: string): IPhoneCreateState => ({
  contactId,
  busy: false,
});

@Injectable()
export class PersonEditStore extends ComponentStore<IPhoneCreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly contactPhoneService: ContactPhoneHttpService,
    readonly dialogConfig: DynamicDialogConfig,
  ) {
    super(initialState(dialogConfig.data?.contactId));
  }

  public readonly saveContactPhone = this.effect<IContactPhoneCreate>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.setBusy(true)),
        withLatestFrom(this.select((state) => state.contactId)),
        switchMap(([data, contactId]) => this.createPhone(contactId, data)),
      );
    },
  );

  private setBusy(busy: boolean) {
    this.setState(
      (state): IPhoneCreateState => ({
        ...state,
        busy: busy,
      }),
    );
  }

  private logError(e: Error) {}

  private createPhone(contactId: string, data: IContactPhoneCreate) {
    return this.contactPhoneService.create(contactId, data).pipe(
      tapResponse(
        (company) => {
          this.dialogRef.close(company);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }
}
