export enum ActivityResolutionEnum {
  ToDo = 'to_do',
  InProgress = 'in_progress',
  Finished = 'finished',
}

export type ActivityStatus = {
  id: string;
  name: string;
  resolution: ActivityResolutionEnum;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};
