import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

import { Contact } from '~/contact/models';
import { ISubscription } from '../../../models';
import {
  SubscriptionDataDialogComponent,
  SubscriptionDataDialogHeaderComponent,
} from '../../components/subscription-data-dialog';
import { contactToSlim } from '~/contact/utils';

@Injectable()
export class SubscriptionDialogService {
  constructor(private readonly dialogService: DialogService) {}

  public open(data?: {
    subscription?: ISubscription;
    contact?: Contact;
  }): Observable<ISubscription | undefined> {
    const ref = this.dialogService.open(SubscriptionDataDialogComponent, {
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data: {
        ...data,
        contact: data?.contact ? contactToSlim(data.contact) : undefined,
      },
      templates: {
        header: SubscriptionDataDialogHeaderComponent,
      },
    });
    return ref.onClose;
  }
}
