import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ICollection, IPagination } from '~/@core/models';
import {
  filterUndefinedParams,
  getAllItemsWithFilterFactory,
} from '~/@core/utils';

import { ContactType, SlimContact } from '../../models';

export interface ISlimContactFilter {
  search?: string;
  ident?: string;
  email?: string;
  phone?: string;
  type?: ContactType;
}

@Injectable({
  providedIn: 'root',
})
export class SlimContactHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ISlimContactFilter,
    SlimContact
  >(this.getList.bind(this));

  protected constructor(protected http: HttpClient) {}

  public getOne(id: string): Observable<SlimContact> {
    return this.http.get<SlimContact>(`/api/slim-contacts/${id}`);
  }

  public getLinked(id: string): Observable<ICollection<SlimContact>> {
    return this.http.get<ICollection<SlimContact>>(
      `/api/slim-contacts/${id}/linked-contacts`,
    );
  }

  public getList(
    filter?: ISlimContactFilter,
    pagination?: IPagination,
  ): Observable<ICollection<SlimContact>> {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<SlimContact>>('/api/slim-contacts', {
      params: filterUndefinedParams({
        ...filter,
        ...paginationQuery,
      }),
    });
  }

  public getAll(filter?: ISlimContactFilter) {
    return this.getAllItems(filter);
  }
}
