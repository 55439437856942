import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IDictionary } from '../../../models';
import { DictionaryHttpService } from '../../../http';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DictionariesStore } from '../dictionaries.store';

export interface IViewDictionaryState {
  deleteBusy: boolean;
}

const initialState = (): IViewDictionaryState => ({
  deleteBusy: false,
});

@Injectable()
export class ViewStore extends ComponentStore<IViewDictionaryState> {
  public dictionary$: Observable<IDictionary | undefined> =
    this.dictionariesStore.selectedDictionary$;

  public readonly isDeleteBusy$: Observable<boolean> = this.select(
    (state) => state.deleteBusy,
  );

  constructor(
    private readonly dictionaryService: DictionaryHttpService,
    private readonly dictionariesStore: DictionariesStore,
  ) {
    super(initialState());
  }

  public readonly deleteCurrentDictionary = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.setDeleteBusy(true)),
      withLatestFrom(this.dictionary$),
      filter(([, dictionary]) => !!dictionary),
      switchMap(([_, dictionary]) =>
        this.dictionaryService.delete((dictionary as IDictionary).id).pipe(
          tapResponse(
            () =>
              this.dictionariesStore.deleteFromList(
                (dictionary as IDictionary).id,
              ),
            (e: HttpErrorResponse) => this.logError(e),
          ),
        ),
      ),
      tap(() => this.setDeleteBusy(false)),
    );
  });

  public editDictionary(id: string) {
    this.dictionariesStore.editDictionary(id);
  }

  private setDeleteBusy(busy: boolean) {
    this.setState(
      (state): IViewDictionaryState => ({
        ...state,
        deleteBusy: busy,
      }),
    );
  }

  private logError(e: Error) {}
}
