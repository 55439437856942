import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { PersonViewStore } from './person-view.store';
import { PersonName } from '../../models';
import { personFullName } from '../../utils';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-view',
  templateUrl: './person-view.component.html',
  providers: [provideComponentStore(PersonViewStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonViewComponent implements OnDestroy {
  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  private subs = new Subscription();

  constructor(
    protected readonly cs: PersonViewStore,
    private readonly store: Store,
    readonly route: ActivatedRoute,
  ) {
    this.subs.add(
      route.params.subscribe((params) => cs.loadContact(params['id'])),
    );
  }

  public fullPersonName(person: PersonName) {
    return personFullName(person);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
