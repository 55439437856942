import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICollection, IPagination } from '../../../@core/models';
import { IUserFilter, User } from '../../models';
import {
  PasswordChangePayload,
  PatchUserPayload,
  UserCreatePayload,
  UserUpdatePayload,
} from './user.payloads';
import { Injectable } from '@angular/core';
import {
  filterUndefinedParams,
  getAllItemsWithFilterFactory,
} from '~/@core/utils';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    IUserFilter,
    User
  >(this.getList.bind(this));

  constructor(private http: HttpClient) {}

  public create(user: UserCreatePayload): Observable<User> {
    return this.http.post<User>('/api/users', user);
  }

  public getList(
    filter?: IUserFilter,
    pagination?: IPagination,
  ): Observable<ICollection<User>> {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<User>>('/api/users', {
      params: {
        ...filterUndefinedParams({
          ...filter,
          ...paginationQuery,
        }),
      },
    });
  }

  public getAll(filter?: IUserFilter) {
    return this.getAllItems(filter);
  }

  public getOne(id: string): Observable<User> {
    return this.http.get<User>(`/api/users/${id}`);
  }

  public update(id: string, user: UserUpdatePayload): Observable<User> {
    return this.http.put<User>(`/api/users/${id}`, user);
  }

  public patch(id: string, user: PatchUserPayload): Observable<User> {
    return this.http.patch<User>(`/api/users/${id}`, user);
  }

  public changePassword(data: PasswordChangePayload): Observable<User> {
    return this.http.post<User>(`/api/password-changes`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/users/${id}`);
  }
}
