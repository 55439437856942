import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { CoreModule } from './@core/core.module';
import { HOOK_PROVIDERS } from './hooks';

@NgModule({
  imports: [CoreModule.forRoot(), AppLayoutModule, AppRoutingModule],
  declarations: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ...HOOK_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
