import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { catchError, EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConfigValueHttpService } from '~/@core/services/http';

import {
  fetchContactConfigurationValues,
  fetchContactConfigurationValuesFail,
  fetchContactConfigurationValuesSuccess,
  refreshContactConfiguration,
} from './configuration.actions';
import { selectContactConfigurationLoading } from './configuration.selectors';
import { CONTACT_CONFIG_GROUP } from '~/contact/constants';

@Injectable()
export class ConfigurationEffects {
  public readonly checkRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshContactConfiguration),
      concatLatestFrom(() =>
        this.store.select(selectContactConfigurationLoading),
      ),
      mergeMap(([_, loading]) =>
        loading ? EMPTY : of(fetchContactConfigurationValues()),
      ),
    );
  });

  public readonly fetchAllValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchContactConfigurationValues),
      mergeMap(() =>
        this.configValuesService.getAll({ group: CONTACT_CONFIG_GROUP }).pipe(
          map((dictionaries) =>
            fetchContactConfigurationValuesSuccess({
              list: dictionaries.items,
            }),
          ),
          catchError((err) =>
            of(fetchContactConfigurationValuesFail({ error: err })),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly configValuesService: ConfigValueHttpService,
    private readonly store: Store,
  ) {}
}
