import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public open(): Observable<boolean | undefined> {
    const ref = this.dialogService.open(ConfirmDialogComponent, {
      header: this.translateService.instant('common.header.confirmAction'),
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-8 md:w-6 lg:w-3',
      closable: false,
    });
    return ref.onClose;
  }
}
