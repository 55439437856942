import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPeriod } from '~/subscription/models';

@Component({
  selector: 'app-period-info',
  templateUrl: './period-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodInfoComponent {
  @Input()
  public period!: IPeriod;
}
