import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
} from '@angular/forms';

@Component({
  template: '',
})
export abstract class AbstractValidationComponent
  implements OnInit, ControlValueAccessor
{
  public formControl!: AbstractControl;

  public formControlName!: string;

  protected control?: AbstractControl;

  protected abstract controlContainer: ControlContainer;

  public ngOnInit(): void {
    this.control = this.getControl();
  }

  public writeValue(value: any): void {}

  public registerOnChange(fn: any): void {
    fn = () => {};
  }

  public registerOnTouched(fn: any): void {
    fn = () => {};
  }

  public setDisabledState(isDisabled: boolean): void {}

  protected getControl(): AbstractControl | undefined {
    if (this.formControl && this.formControlName) {
      console.warn('Both formControl and formControlName are provided');
    }

    if (this.formControl) {
      return this.formControl;
    }

    if (this.controlContainer) {
      if (this.formControlName) {
        return (
          this.controlContainer.control?.get(this.formControlName) || undefined
        );
      } else {
        console.warn(
          'Missing FormControlName directive from host element of the component',
        );
      }
    }

    return this.controlContainer.control || undefined;
  }
}
