import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Person } from '~/contact/models';
import {
  PersonDataDialogComponent,
  PersonDataDialogHeaderComponent,
} from '../../components/person-data-dialog';

@Injectable()
export class PersonDialogService {
  constructor(private readonly dialogService: DialogService) {}

  public open(data?: { person?: Person }): Observable<Person | undefined> {
    const ref = this.dialogService.open(PersonDataDialogComponent, {
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data,
      templates: {
        header: PersonDataDialogHeaderComponent,
      },
    });
    return ref.onClose;
  }
}
