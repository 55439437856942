import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityComment } from '../../models';
import { HttpClient } from '@angular/common/http';
import { ICollection, IPagination } from '~/@core/models';
import { getAllItemsWithFilterFactory } from '~/@core/utils';
import {
  ActionFilter,
  ActionPayload,
} from '~/activity/services/http/activity-comment.payloads';

@Injectable({
  providedIn: 'root',
})
export class ActivityCommentHttpService {
  private readonly getAllItems = getAllItemsWithFilterFactory<
    ActionFilter,
    ActivityComment
  >(this.getList.bind(this));

  constructor(private readonly http: HttpClient) {}

  public getList(filter?: ActionFilter, pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<ActivityComment>>(
      '/api/activity-comments',
      {
        params: {
          ...filter,
          ...paginationQuery,
        },
      },
    );
  }

  public getAll(filter?: ActionFilter) {
    return this.getAllItems(filter);
  }

  public getOne(id: string): Observable<ActivityComment> {
    return this.http.get<ActivityComment>(`/api/activity-comments/${id}`);
  }

  public create(data: ActionPayload): Observable<ActivityComment> {
    return this.http.post<ActivityComment>('/api/activity-comments/', data);
  }

  public update(id: string, data: ActionPayload): Observable<ActivityComment> {
    return this.http.put<ActivityComment>(`/api/activity-comments/${id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/activity-comments/${id}`);
  }
}
