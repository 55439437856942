<div class="grid">
  <div class="col-12 lg:col-4">
    <app-dictionaries-list></app-dictionaries-list>
  </div>
  <div class="col-12 lg:col-8 flex flex-column gap-5">
    <app-dictionary-create *ngIf="cs.showCreate$ | async" />
    <app-dictionary-view *ngIf="cs.showDictionaryInfo$ | async" />
    <app-dictionary-update
      *ngIf="cs.showUpdate$ | async"
    ></app-dictionary-update>
    <app-dictionary-items-list *ngIf="cs.showItems$ | async" />
  </div>
</div>
