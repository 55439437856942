import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  constructor(private readonly ref: DynamicDialogRef) {}

  public confirm() {
    this.ref.close(true);
  }

  public decline() {
    this.ref.close(false);
  }
}
