import { Injectable } from '@angular/core';
import { DictionaryItemHttpService } from '../../http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  fetchAllDictionaryItemsSuccess,
  fetchAllDictionaryItems,
  fetchAllDictionaryItemsFail,
  refreshDictionaryItems,
} from './dictionary-items.actions';
import { map, mergeMap } from 'rxjs/operators';
import { catchError, EMPTY, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectItemsForDictionaryLoading } from './dictionary-items.selectors';

@Injectable()
export class DictionaryItemsEffects {
  /**
   * As we're not switching requests, we need to check if we're already loading
   */
  public readonly checkIfLoading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshDictionaryItems),
      mergeMap(({ dictionaryId }) =>
        of(dictionaryId).pipe(
          withLatestFrom(
            this.store.select(selectItemsForDictionaryLoading(dictionaryId)),
          ),
          map(([_, loading]) => ({
            dictionaryId,
            loading,
          })),
        ),
      ),
      mergeMap(({ dictionaryId, loading }) =>
        loading ? EMPTY : of(fetchAllDictionaryItems({ dictionaryId })),
      ),
    );
  });

  public readonly fetchAllDictionaryItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAllDictionaryItems),
      mergeMap(({ dictionaryId }) =>
        this.itemService.getAllForDictionary(dictionaryId).pipe(
          map((dictionaries) =>
            fetchAllDictionaryItemsSuccess({
              dictionaryId,
              list: dictionaries.items,
            }),
          ),
          catchError(() => of(fetchAllDictionaryItemsFail({ dictionaryId }))),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly itemService: DictionaryItemHttpService,
    private readonly store: Store,
  ) {}
}
