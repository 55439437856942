import {
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MenuService } from '../menu/app.menu.service';
import { AppSidebarComponent } from '../sidebar/app.sidebar.component';
import { LayoutService } from '../../service/app.layout.service';
import { ScrollContentService } from '~/layout/service/scroll-content.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
})
export class AppLayoutComponent implements OnDestroy {
  menuOutsideClickListener: any;

  menuScrollListener: any;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild('content', {
    read: ElementRef,
    static: true,
  })
  private contentWrapper!: ElementRef;

  private subs = new Subscription();

  constructor(
    private menuService: MenuService,
    private scrollService: ScrollContentService,
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router,
  ) {
    this.createOverlaySubscription();
    this.createScrollerSubscription();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
      });
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi',
        ),
        ' ',
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }

    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-slim': this.layoutService.config().menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config().menuMode === 'slim-plus',
      'layout-static': this.layoutService.config().menuMode === 'static',
      'layout-overlay': this.layoutService.config().menuMode === 'overlay',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config().menuMode === 'static',
      'p-input-filled': this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
      'layout-light':
        this.layoutService.config().layoutTheme === 'colorScheme' &&
        this.layoutService.config().colorScheme === 'light',
      'layout-dark':
        this.layoutService.config().layoutTheme === 'colorScheme' &&
        this.layoutService.config().colorScheme === 'dark',
      'layout-primary':
        this.layoutService.config().colorScheme !== 'dark' &&
        this.layoutService.config().layoutTheme === 'primaryColor',
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }

  private createOverlaySubscription() {
    const sub = this.layoutService.overlayOpen$.subscribe(() => {
      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen(
          'document',
          'click',
          (event) => {
            const isOutsideClicked = !(
              this.appSidebar.el.nativeElement.isSameNode(event.target) ||
              this.appSidebar.el.nativeElement.contains(event.target)
            );
            if (isOutsideClicked) {
              this.hideMenu();
            }
          },
        );
      }

      if (
        (this.layoutService.isSlim() || this.layoutService.isSlimPlus()) &&
        !this.menuScrollListener
      ) {
        this.menuScrollListener = this.renderer.listen(
          this.appSidebar.menuContainer.nativeElement,
          'scroll',
          (event) => {
            if (this.layoutService.isDesktop()) {
              this.hideMenu();
            }
          },
        );
      }

      if (this.layoutService.state.staticMenuMobileActive) {
        this.blockBodyScroll();
      }
    });

    this.subs.add(sub);
  }

  private createScrollerSubscription() {
    const sub = this.scrollService.scroll.subscribe(() => {
      this.contentWrapper.nativeElement.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.hideMenu();
    });
    this.subs.add(sub);
  }
}
