@if (subscription) {
  <div class="flex gap-2">
    <app-row-view-button [link]="['/subscriptions', subscription.id]" />
    <app-row-edit-button
      (edit)="$event.stopPropagation(); cs.showUpdateDialog(subscription)"
    />
    <app-row-delete-button
      (delete)="cs.deleteItem(subscription.id)"
      [disabled]="(canDelete$ | async) === false"
    />
  </div>
}
