import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DictionaryItemPayload, IDictionaryItem } from '../../../models';

@Component({
  selector: 'app-dictionary-item-update',
  templateUrl: './item-update.component.html',
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemUpdateComponent {
  @Input()
  public set item(value: IDictionaryItem) {
    this.form.patchValue({
      value: value.value,
    });
  }

  @Output() public updateItem: EventEmitter<DictionaryItemPayload> =
    new EventEmitter<DictionaryItemPayload>();

  @Output() public cancelUpdate: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  constructor() {
    this.form = new FormGroup<{
      value: FormControl;
    }>({
      value: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    });
  }

  public update() {
    this.updateItem.emit(this.form.value);
  }

  public cancel() {
    this.cancelUpdate.emit();
  }

  protected readonly onsubmit = onsubmit;
}
