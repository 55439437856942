import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FileSelectEvent,
  FileUploadHandlerEvent,
} from 'primeng/fileupload/fileupload.interface';

import { FileParserFactoryService } from '../../../services';
import { ImporterStore } from '~/@core/modules/importer/components/importer/importer.store';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileLoaderComponent implements OnDestroy {
  private static readonly previewRows = 5;

  public data?: unknown[];
  public mimeTypes = FileParserFactoryService.allowedMimeTypes.join(', ');

  private subs = new Subscription();

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  constructor(
    public readonly cs: ImporterStore,
    private readonly fileParserFactoryService: FileParserFactoryService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public parseFile(event: FileSelectEvent) {
    if (!event.files.length) {
      this.clear();
      // TODO: show error
    }

    const parser = this.fileParserFactoryService.createParser(event.files[0], {
      parseRows: FileLoaderComponent.previewRows,
    });
    const parseSub = parser.parse().subscribe((results) => {
      this.data = results;
      this.cdRef.markForCheck();
    });
    this.subs.add(parseSub);
  }

  public import(event: FileUploadHandlerEvent) {
    if (!event.files.length) {
      this.clear();
    }
    this.cs.setImportFile(event.files[0]);
  }

  public clear() {
    this.data = undefined;
    this.cdRef.markForCheck();
  }
}
