import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '~/shared.module';
import { SharedExtModule } from '~/shared-ext.module';

import { SubscriptionTypeDropdownComponent } from './components/subscription-type-dropdown';
import { DictionaryInfoRowComponent } from './components/dictionary-info-row';
import { SubscriptionExpandComponent } from './components/subscription-expand';
import {
  SubscriptionDataDialogComponent,
  SubscriptionDataDialogHeaderComponent,
} from './components/subscription-data-dialog';
import { SubscriptionDialogService } from './services/dialogs';
import { ChipModule } from 'primeng/chip';
import { ContactFeatureModule } from '~/contact/@feature';
import { SubscriptionTypeMultiselectComponent } from '~/subscription/@common/components/subscription-type-multiselect';
import { MultiSelectModule } from 'primeng/multiselect';
import { DictionaryFeatureModule } from '~/dictionary/@feature';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    SharedExtModule,
    ChipModule,
    ContactFeatureModule,
    MultiSelectModule,
    DictionaryFeatureModule,
  ],
  providers: [SubscriptionDialogService],
  declarations: [
    SubscriptionTypeDropdownComponent,
    DictionaryInfoRowComponent,
    SubscriptionExpandComponent,
    SubscriptionDataDialogComponent,
    SubscriptionDataDialogHeaderComponent,
    SubscriptionTypeMultiselectComponent,
  ],
  exports: [
    ContactFeatureModule,
    SubscriptionTypeDropdownComponent,
    DictionaryInfoRowComponent,
    SubscriptionExpandComponent,
    SubscriptionDataDialogComponent,
    SubscriptionDataDialogHeaderComponent,
    SubscriptionTypeMultiselectComponent,
  ],
})
export class SubscriptionCommonModule {}
