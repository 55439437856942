import {
  dictionaryItemsReducer,
  DictionaryItemsState,
} from './dictionary-items/dictionary-items.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface DictionaryState {
  items: DictionaryItemsState;
}

export const dictionaryReducer: ActionReducerMap<DictionaryState> = {
  items: dictionaryItemsReducer,
};

export const dictionaryFeatureKey = 'dictionary';
