import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactType, SlimContact } from '~/contact/models';

@Component({
  selector: 'app-contact-pick-modal',
  templateUrl: './contact-pick-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPickModalComponent {
  protected contacts: SlimContact[];

  constructor(
    private readonly ref: DynamicDialogRef,
    readonly config: DynamicDialogConfig,
  ) {
    this.contacts = config.data.contacts;
  }

  protected pickContact(contact: SlimContact) {
    this.ref.close(contact);
  }

  protected link(contact: SlimContact) {
    switch (contact?.type) {
      case ContactType.Person:
        return `/contacts/persons/${contact.id}`;
      case ContactType.Company:
        return `/contacts/companies/${contact.id}`;
      default:
        return '';
    }
  }
}
