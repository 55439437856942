import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { FileParserFactoryService } from '~/@core/modules/importer/services';
import { concatMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ImportFunction } from './importer.component';
import { ImportResult } from '~/@core/modules/importer/models';
import { ExportService } from '~/@core/modules/exporter/services';
import { map } from 'rxjs/operators';

export interface IImporterState {
  mapping?: Map<number, string>;
  data?: unknown[];
  result: ImportResult[];
  isParsing: boolean;
  isParsed: boolean;
  importFinished: boolean;
  isFirstRowHeader: boolean;
  step: number;
}

export interface IDataColumn {
  key: number;
  name: string;
}

const initialState = (): IImporterState => ({
  step: 0,
  isParsing: false,
  isParsed: false,
  isFirstRowHeader: false,
  importFinished: false,
  result: [],
});

@Injectable()
export class ImporterStore extends ComponentStore<IImporterState> {
  private static readonly PARSE_LIMIT = 1000;

  public readonly isParsed$ = this.select((state) => state.isParsed);
  public readonly data$ = this.select((state) =>
    state.isFirstRowHeader ? state.data?.slice(1) : state.data,
  );
  public readonly mapping$ = this.select((state) => state.mapping);
  public readonly isFirstRowHeader$ = this.select(
    (state) => state.isFirstRowHeader,
  );
  public readonly dataColumns$ = this.select((state): IDataColumn[] => {
    if (!state.data?.length) {
      return [];
    }

    const isFirstRowHeader = state.isFirstRowHeader;
    const firstRow = state.data[0] as Array<string>;
    return firstRow.map((value, index) => ({
      key: index,
      name: isFirstRowHeader ? value : `Column ${index + 1}`,
    }));
  });
  public readonly isParsing$ = this.select((state) => state.isParsing);
  public readonly step$ = this.select((state) => state.step);
  public readonly result$ = this.select((state) => state.result);
  public readonly finished$ = this.select((state) => state.importFinished);
  public importFunction?: ImportFunction;

  constructor(
    private readonly fileParserFactoryService: FileParserFactoryService,
    private readonly exporterService: ExportService,
  ) {
    super(initialState());
  }

  public setImportFile = this.effect<File>((file$) => {
    return file$.pipe(
      tap((file) => {
        this.patchState({
          isParsing: true,
          step: 1,
        });
      }),
      withLatestFrom(this.select((state) => state.isFirstRowHeader)),
      switchMap(([file, isFirstRowHeader]) => {
        const parser = this.fileParserFactoryService.createParser(file, {
          parseRows: ImporterStore.PARSE_LIMIT + (isFirstRowHeader ? 1 : 0),
        });
        return parser.parse();
      }),
      tapResponse(
        (data) => {
          this.patchState({
            data,
            isParsing: false,
            isParsed: true,
          });
        },
        (e) => {
          this.patchState({
            isParsing: false,
            step: 0,
          });
          console.error(e);
        },
      ),
    );
  });

  public rePickFile = this.updater<void>((state) => ({
    ...initialState(),
  }));

  public setImportMapping = this.updater<Map<number, string>>(
    (state, mapping): IImporterState => ({
      ...state,
      mapping,
      step: 2,
    }),
  );

  public rePickMapping = this.updater<void>(
    (state): IImporterState => ({
      ...state,
      mapping: undefined,
      step: 1,
    }),
  );

  public startImport = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.patchState({
          step: 3,
        });
      }),
      withLatestFrom(this.data$, this.mapping$),
      switchMap(([_, data, mapping]) => {
        const mappedData = (data as string[][]).map((row: string[]) => {
          const mappedRow: Record<string, string> = {};
          (mapping as Map<number, string>).forEach((value, key) => {
            mappedRow[value] = row[key];
          });
          return mappedRow;
        });
        return of(...mappedData);
      }),
      concatMap((row) => {
        if (!this.importFunction) {
          throw new Error('Import function is not defined');
        }
        return this.importFunction(row);
      }),
      tap((res) => this.addResult(res)),
      tap(() => this.patchState({ importFinished: true })),
    );
  });

  public exportResults = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(
        this.result$,
        this.data$,
        this.dataColumns$,
        this.isFirstRowHeader$,
      ),
      map(([_, result, data, columns, isFirstRowHeader]) => {
        if (!result.length) {
          return [];
        }

        if (!data || !data.length || !data[0] || !Array.isArray(data[0])) {
          return [];
        }

        const columnNames = columns.map((column) => column.name);
        return data.map((row, index) => {
          const rowData = (row as any[]).reduce(
            (acc, value, index) => {
              acc[columnNames[index]] = value;
              return acc;
            },
            {} as { [key: string]: any },
          );

          const resultRow = result[index];
          if (resultRow) {
            Object.assign(rowData, {
              'Result ID': resultRow.resultId,
              'Result link': resultRow.resultLink,
              'Result errors': resultRow.errors.join(', '),
            });
          }

          return rowData;
        });
      }),
      switchMap((rows) => this.exporterService.export(rows)),
    );
  });

  public isFirstRowHeader = this.updater<boolean>(
    (state, isFirstRowHeader): IImporterState => ({
      ...state,
      isFirstRowHeader,
    }),
  );

  public addResult = this.updater<ImportResult>(
    (state, result): IImporterState => ({
      ...state,
      result: [...state.result, result],
    }),
  );
}
