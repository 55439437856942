import { ParseConfiguration } from './parse-configuration';
import { Observable } from 'rxjs';

export abstract class AbstractFileParser {
  constructor(
    protected readonly file: File,
    protected readonly configuration: ParseConfiguration,
  ) {}
  public abstract parse(): Observable<unknown[]>;
}
