import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Activity } from '~/activity/models';
import { MenuItem } from 'primeng/api';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { Store } from '@ngrx/store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[app-activity-row]',
  templateUrl: './activity-row.component.html',
  styleUrl: './activity-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityRowComponent {
  @Input() public activity!: Activity;

  @Input() public expanded!: boolean;

  @Input() public showContact = true;

  @Input() public small = false;

  @Output() public toggleExpand = new EventEmitter<Activity>();

  @Output() public update = new EventEmitter<Activity>();

  @Output() public delete = new EventEmitter<Activity>();

  protected canDelete$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  @ViewChild('options', { read: ElementRef }) private options!: ElementRef;

  @ViewChild('date', { read: ElementRef }) private date!: ElementRef;

  @HostListener('mouseenter', ['$event'])
  protected onMouseEnter() {
    if (this.small) return;

    this.options.nativeElement.style.display = 'flex';
    this.date.nativeElement.style.display = 'none';
  }

  @HostListener('mouseleave', ['$event'])
  protected onMouseLeave() {
    if (this.small) return;

    this.options.nativeElement.style.display = 'none';
    this.date.nativeElement.style.display = 'flex';
  }

  protected menuItems: MenuItem[] = [
    {
      label: 'common.button.edit',
      icon: 'pi pi-pencil',
      command: () => this.activity && this.update.emit(this.activity),
    },
    {
      label: 'common.button.delete',
      icon: 'pi pi-trash',
      command: () => this.activity && this.delete.emit(this.activity),
    },
  ];

  protected disableExpand(activity: Activity) {
    return (
      !activity.lastComment &&
      !activity.description &&
      activity.additionalContacts?.length === 0
    );
  }

  constructor(private store: Store) {}
}
