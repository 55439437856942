import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompaniesListComponent } from './pages/companies-list';
import { CompanyViewComponent } from './pages/company-view';
import { PersonsListComponent } from './pages/persons-list';
import { PersonViewComponent } from './pages/person-view';
import { CompanyImportComponent } from './pages/company-import';
import { PersonImportComponent } from '~/contact/pages/person-import';
import { DeletedContactsComponent } from '~/contact/pages/deleted-contacts';
import { PhoneCallComponent } from '~/contact/pages/phone-call';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'deleted',
        component: DeletedContactsComponent,
      },
      {
        path: 'phone-call',
        component: PhoneCallComponent,
      },
      {
        path: 'companies',
        data: { breadcrumb: 'Companies' },
        children: [
          {
            path: '',
            component: CompaniesListComponent,
          },
          {
            path: 'import',
            component: CompanyImportComponent,
          },
          {
            path: ':id',
            data: { breadcrumb: 'Update' },
            component: CompanyViewComponent,
          },
        ],
      },
      {
        path: 'persons',
        data: { breadcrumb: 'Persons' },
        children: [
          {
            path: '',
            component: PersonsListComponent,
          },
          {
            path: 'import',
            component: PersonImportComponent,
          },
          {
            path: ':id',
            data: { breadcrumb: 'Update' },
            component: PersonViewComponent,
          },
        ],
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./modules/configuration').then((m) => m.ConfigurationModule),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ContactRoutingModule {}
