import { createSelector } from '@ngrx/store';
import { selectContactFeature } from '../feature.selector';
import { configValueAdapter } from './configuration.reducer';
import { ConfigurationValuesEnum } from '~/contact/models';

const { selectAll } = configValueAdapter.getSelectors();

const selectItemsSlice = createSelector(
  selectContactFeature,
  (state) => state.configuration,
);

export const selectContactConfigurationLoading = createSelector(
  selectItemsSlice,
  (state) => state?.loading || false,
);

export const selectContactContactConfigurationList = createSelector(
  selectItemsSlice,
  (state) => selectAll(state.entities),
);

export const selectContactContactConfigurationValue = (id: string) =>
  createSelector(
    selectItemsSlice,
    (state) => state.entities.entities[id] || null,
  );

export const selectContactContactConfigurationMapFor = (
  ids: ConfigurationValuesEnum[],
) =>
  createSelector(
    selectContactContactConfigurationList,
    (list) =>
      new Map(
        ids.map((id) => [id, list.find((item) => item.id === id)?.value]),
      ),
  );
