import { NgModule } from '@angular/core';

import { SharedExtModule } from '~/shared-ext.module';

import { ValidationErrorsComponent } from './validation-errors.component';
import { ValidationErrorComponent } from './validation-error.component';

@NgModule({
  declarations: [ValidationErrorsComponent, ValidationErrorComponent],
  imports: [SharedExtModule],
  exports: [ValidationErrorsComponent, ValidationErrorComponent],
})
export class ValidationErrorsModule {}
