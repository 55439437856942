import { createAction, props } from '@ngrx/store';
import { ConfigValue } from '~/@core/models';

export const refreshContactConfiguration = createAction(
  '[Contact configuration] Refresh request',
);
export const fetchContactConfigurationValues = createAction(
  '[Contact configuration] Fetch all values',
);
export const fetchContactConfigurationValuesSuccess = createAction(
  '[Contact configuration] Fetch all values success',
  props<{ list: ConfigValue[] }>(),
);
export const fetchContactConfigurationValuesFail = createAction(
  '[Contact configuration] Fetch all values fail',
  props<{ error: Error }>(),
);
