import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactAddressHttpService } from '../../services/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IContractAddressPayload } from '~/contact/modules/address/services/http/contact-address.payloads';
import { IContactAddress } from '~/contact/modules/address/models';

export interface IAddressCreateState {
  contactId: string;
  address?: IContactAddress;
  busy: boolean;
}

const initialState = (data: {
  contactId: string;
  address?: IContactAddress;
}): IAddressCreateState => ({
  ...data,
  busy: false,
});

@Injectable()
export class AddressEditStore extends ComponentStore<IAddressCreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
    private readonly httpService: ContactAddressHttpService,
  ) {
    super(initialState(dialogConfig.data));
  }

  public readonly saveAddress = this.effect<IContractAddressPayload>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.setBusy(true)),
        withLatestFrom(
          this.select((state) => state.contactId),
          this.select((state) => state.address),
        ),
        switchMap(([data, contactId, address]) =>
          !!address
            ? this.updateAddress(contactId, address.id, data)
            : this.createAddress(contactId, data),
        ),
      );
    },
  );

  private setBusy(busy: boolean) {
    this.setState(
      (state): IAddressCreateState => ({
        ...state,
        busy: busy,
      }),
    );
  }

  private logError(e: Error) {}

  private createAddress(contactId: string, data: IContractAddressPayload) {
    return this.httpService.create(contactId, data).pipe(
      tapResponse(
        (address) => {
          this.dialogRef.close(address);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }

  private updateAddress(
    contactId: string,
    id: string,
    data: IContractAddressPayload,
  ) {
    return this.httpService.update(contactId, id, data).pipe(
      tapResponse(
        (address) => {
          this.dialogRef.close(address);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }
}
