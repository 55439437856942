import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactPhone } from '../../models';
import {
  IContactPhoneCreate,
  IContactPhoneUpdate,
} from './contact-phone.payloads';

@Injectable({
  providedIn: 'root',
})
export class ContactPhoneHttpService {
  constructor(protected http: HttpClient) {}

  public getAll(contactId: string): Observable<ContactPhone[]> {
    return this.http.get<ContactPhone[]>(`/api/contacts/${contactId}/phones`);
  }

  public create(
    contactId: string,
    data: IContactPhoneCreate,
  ): Observable<ContactPhone> {
    return this.http.post<ContactPhone>(`/api/contacts/${contactId}/phones`, {
      ...data,
    });
  }

  public update(
    contactId: string,
    number: string,
    data: IContactPhoneUpdate,
  ): Observable<ContactPhone> {
    return this.http.put<ContactPhone>(
      `/api/contacts/${contactId}/phones/${number}`,
      {
        ...data,
      },
    );
  }

  public delete(contactId: string, number: string): Observable<void> {
    return this.http.delete<void>(
      `/api/contacts/${contactId}/phones/${number}`,
    );
  }
}
