import { NgModule } from '@angular/core';
import { EmptySpaceComponent } from './empty-space.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [EmptySpaceComponent],
  imports: [SharedExtModule],
  exports: [EmptySpaceComponent],
})
export class EmptySpaceModule {}
