@if (cs.loaded$ | async) {
  @if (cs.activity$ | async; as activity) {
    <div class="flex justify-content-between align-items-center gap-3 mb-3">
      <h1 class="text-3xl m-0">
        {{ activity.title }}
      </h1>
      <div class="flex align-items-end gap-2">
        <app-card-edit-button (edit)="cs.openActivityUpdateDialog()" />
        <app-card-delete-button
          (delete)="cs.deleteActivity()"
          [disabled]="(canDelete$ | async) === false"
        />
      </div>
    </div>

    <div class="grid">
      <div class="col-12 md:col-5 flex flex-column gap-4">
        <app-activity-info />
        <app-activity-parent />
        @if (activity.contact) {
          <app-contact-card [contactId]="activity.contact.id" />
        }
        @if (
          activity.additionalContacts && activity.additionalContacts.length > 0
        ) {
          <div>
            <div class="text-600 font-bold mb-2">
              {{ "activity.label.additionalContacts" | translate }}:
            </div>
            <div class="flex gap-4">
              @for (contact of activity.additionalContacts; track $index) {
                <app-contact-link [contact]="contact"></app-contact-link>
              }
            </div>
          </div>
        }
      </div>

      <div class="col-12 md:col-7">
        <div class="card">
          <div class="card-body activity-data-tabs">
            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <span>{{ "activity.menu.comments" | translate }}</span>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-activity-comments></app-activity-comments>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <span>{{ "activity.menu.subActivities" | translate }}</span>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-activity-children></app-activity-children>
                </ng-template>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </div>
    </div>
  }
}
