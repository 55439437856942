import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-row-cancel-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-times"
      class="p-button-warning h-2rem w-2rem"
      type="button"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowCancelButtonComponent {}
