import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { provideComponentStore } from '@ngrx/component-store';

import { PersonEditStore } from './phone-create.store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactPhone } from '~/contact/modules/phone/models';

@Component({
  selector: 'app-phone-create',
  templateUrl: './phone-create.component.html',
  providers: [provideComponentStore(PersonEditStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCreateComponent {
  protected form = new FormGroup({
    number: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\+\d{2} \(\d{3}\) \d{3}-\d{2}-\d{2}$/),
      this.phoneExistValidator.bind(this),
    ]),
    note: new FormControl('', [Validators.maxLength(100)]),
  });

  private phones?: ContactPhone[];

  constructor(
    public readonly cs: PersonEditStore,
    public readonly ref: DynamicDialogRef,
    readonly dialogConfig: DynamicDialogConfig,
  ) {
    this.phones = dialogConfig.data?.phones;
  }

  protected close() {
    this.ref.close();
  }

  protected save() {
    if (this.form.invalid) {
      return;
    }

    const number = this.form.value.number as string;
    this.cs.saveContactPhone({
      number: '+' + number.replace(/\D/g, ''),
      note: this.form.value.note || undefined,
    });
  }

  private phoneExistValidator(control: AbstractControl) {
    if (this.phones?.some((phone) => phone.number === control.value)) {
      return { phoneExist: true };
    }

    return null;
  }
}
