import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { selectItemsForDictionaryList } from '~/dictionary/store/dictionary-items/dictionary-items.selectors';
import { IDictionary, IDictionaryItem } from '~/dictionary/models';
import { refreshDictionaryItems } from '~/dictionary/store/dictionary-items/dictionary-items.actions';

@Component({
  selector: 'app-dictionary-item-select',
  templateUrl: './dictionary-item-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DictionaryItemSelectComponent),
      multi: true,
    },
  ],
})
export class DictionaryItemSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input()
  public dictionary!: IDictionary;

  @Input()
  public multiple = false;

  protected value?: string[];
  protected singleValue?: string;
  protected dictionaryItems$?: Observable<IDictionaryItem[]>;

  constructor(protected store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(
      refreshDictionaryItems({ dictionaryId: this.dictionary.id }),
    );
    this.dictionaryItems$ = this.store.select(
      selectItemsForDictionaryList(this.dictionary.id),
    );
  }

  protected setValue(value: string[]) {
    this.value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  protected setSingleValue(value: string) {
    this.singleValue = value;
    console.log(value);
    if (value === null || value === undefined) {
      this.onChange([]);
      this.onTouch([]);
      return;
    } else {
      this.onChange([value]);
      this.onTouch([value]);
    }
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public writeValue(value: any) {
    this.value = value;
    this.singleValue = value?.[0];
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
