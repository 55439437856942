import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Contact } from '../../models';
import { HOOKS } from '~/hook/tokens';
import { IHooked } from '~/hook/models';

@Component({
  selector: 'app-contact-widgets',
  templateUrl: './contact-widgets.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactWidgetsComponent implements OnInit {
  @Input()
  public hookName!: string;

  @Input()
  public contact?: Contact;

  public contactWidgets: IHooked[] = [];

  constructor(@Inject(HOOKS) private readonly hooks: IHooked[]) {}

  public ngOnInit(): void {
    this.contactWidgets = this.hooks.filter(
      (hook) => hook.hookName === this.hookName,
    );
  }
}
