import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PeriodHttpService } from '../../services/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ISubscription, SubscriptionDictionary } from '~/subscription/models';
import { SubscriptionDictionariesService } from '~/subscription/services/data';

@Component({
  selector: 'app-add-period-dialog',
  templateUrl: './add-period.component.html',
})
export class AddPeriodComponent implements OnInit, OnDestroy {
  protected form: FormGroup = new FormGroup({
    start: new FormControl<Date>(new Date(), [Validators.required]),
    end: new FormControl<Date>(new Date(), [Validators.required]),
    note: new FormControl<string>(''),
  });

  protected subscription: ISubscription;

  protected dictionaries: SubscriptionDictionary[] = [];

  private subs = new Subscription();

  constructor(
    private readonly periodService: PeriodHttpService,
    private readonly dictionariesService: SubscriptionDictionariesService,
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.subscription = this.config.data?.subscription;
  }

  public ngOnInit(): void {
    this.createDictionaryFieldsSub();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected submit() {
    if (this.form.invalid) return;
    const sub = this.periodService
      .create({
        subscriptionId: this.subscription.id,
        start: this.form.get('start')?.value as Date,
        end: this.form.get('end')?.value as Date,
        note: this.form.get('note')?.value as string,
        dictionaryValues: this.mapDictionaryValues(),
      })
      .subscribe((period) => {
        this.ref.close(period);
      });
    this.subs.add(sub);
  }

  protected dictionaryFormKey(dictionaryId: string) {
    return `dictionaryValues-${dictionaryId}`;
  }

  protected close() {
    this.ref.close();
  }

  private createDictionaryFieldsSub() {
    const dictionariesSub = this.dictionariesService
      .getItemsForType$(this.subscription.type.id)
      .subscribe((dictionaries) => {
        if (!dictionaries) {
          return;
        }

        for (let dictionary of dictionaries) {
          this.form.addControl(
            this.dictionaryFormKey(dictionary.id),
            new FormControl([]),
          );
        }
        this.dictionaries = dictionaries;
        this.cdRef.markForCheck();
      });
    this.subs.add(dictionariesSub);
  }

  private mapDictionaryValues() {
    return this.dictionaries
      .map((dictionary) => {
        const control = this.form.get(this.dictionaryFormKey(dictionary.id));
        if (
          !control?.value ||
          (Array.isArray(control?.value) && control?.value.length === 0)
        ) {
          return undefined;
        }

        return {
          dictionaryId: (dictionary as SubscriptionDictionary).id,
          valueIds: control?.value || [],
        };
      })
      .filter((i) => i !== undefined) as {
      dictionaryId: string;
      valueIds: string[];
    }[];
  }
}
