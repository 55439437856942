import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Company } from '~/contact/models';
import {
  CompanyDataDialogComponent,
  CompanyDataDialogHeaderComponent,
} from 'src/app/contact/components/company-data-dialog';

@Injectable()
export class CompanyDialogService {
  constructor(private readonly dialogService: DialogService) {}

  public open(data?: { company?: Company }): Observable<Company | undefined> {
    const ref = this.dialogService.open(CompanyDataDialogComponent, {
      resizable: false,
      draggable: false,
      styleClass: 'mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6',
      data,
      templates: {
        header: CompanyDataDialogHeaderComponent,
      },
    });
    return ref.onClose;
  }
}
