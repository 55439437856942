@if (subscription.note) {
  <div class="text-600 font-semibold mb-2">
    {{ "subscription.label.note" | translate }}
  </div>
  <div class="border-2 border-dashed surface-border p-2 mb-4">
    <div [innerHtml]="subscription.note"></div>
  </div>
}

@if (subscription.dictionaryValues.length > 0) {
  <div class="flex flex-column gap-2">
    @for (dValue of subscription.dictionaryValues; track $index) {
      <app-dictionary-info-row
        [dictionaryValue]="dValue"
      ></app-dictionary-info-row>
    }
  </div>
}
