import { ComponentStore } from '@ngrx/component-store';
import {
  exhaustMap,
  filter,
  Observable,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';

import { ContactEmail } from '../../models';
import {
  ContactEmailHttpService,
  ContactEmailUpdate,
} from '../../services/http';
import { AddEmailDialogService } from '../../services/dialogs';

interface IEmailsListState {
  loading: boolean;
  emails: ContactEmail[];
  editItemIds: Set<string>;
  contactId?: string;
}

const initialState = (): IEmailsListState => ({
  loading: false,
  emails: [],
  editItemIds: new Set<string>(),
});

@Injectable()
export class EmailsListStore extends ComponentStore<IEmailsListState> {
  public readonly items$: Observable<ContactEmail[]> = this.select(
    (state) => state.emails,
  );

  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.loading,
  );

  public readonly isItemInEditMode$ = (id: string) =>
    this.select((state) => state.editItemIds.has(id));

  public readonly isItemInViewMode$ = (id: string) =>
    this.select((state) => !state.editItemIds.has(id));

  constructor(
    private readonly contactEmailService: ContactEmailHttpService,
    private readonly addEmailDialogService: AddEmailDialogService,
  ) {
    super(initialState());
  }

  public readonly loadEmails = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      tap((contactId) => this.patchState({ contactId, loading: true })),
      switchMap((id) =>
        this.contactEmailService.getAll(id).pipe(
          tapResponse(
            (phones) => this.setEmails(phones),
            (e: HttpErrorResponse) => this.logError(e),
          ),
          tap(() => this.patchState({ loading: false })),
        ),
      ),
    );
  });

  public showCreateDialog = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(
        this.select((state) => state.contactId),
        this.select((state) => state.emails),
      ),
      filter(([_, contactId]) => !!contactId),
      exhaustMap(([_, contactId, emails]) =>
        this.addEmailDialogService
          .open({
            contactId: contactId as string,
            emails,
          })
          .pipe(take(1)),
      ),
      tap((result?: ContactEmail) => {
        if (result) {
          this.addEmail(result);
        }
      }),
    );
  });

  public setItemToEditMode(itemId: string) {
    this.setState(
      (state): IEmailsListState => ({
        ...state,
        editItemIds: new Set([...state.editItemIds, itemId]),
      }),
    );
  }

  public setItemToViewMode(itemId: string) {
    this.setState((state): IEmailsListState => {
      const items = new Set([...state.editItemIds]);
      items.delete(itemId);
      return {
        ...state,
        editItemIds: items,
      };
    });
  }

  public updateItem = this.effect<{
    number: string;
    payload: ContactEmailUpdate;
  }>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([, contactId]) => !!contactId),
      switchMap(([{ number, payload }, contactId]) =>
        this.contactEmailService
          .update(contactId as string, number, payload)
          .pipe(
            tapResponse((item) => {
              this.setEmail(item);
              this.setItemToViewMode(item.address);
            }, this.logError),
          ),
      ),
    );
  });

  public deleteItem = this.effect<string>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.contactId)),
      filter(([, contactId]) => !!contactId),
      switchMap(([number, contactId]) =>
        this.contactEmailService
          .delete(contactId as string, number)
          .pipe(tapResponse(() => this.removePhone(number), this.logError)),
      ),
    );
  });

  private setEmails(items: ContactEmail[]) {
    this.setState(
      (state): IEmailsListState => ({
        ...state,
        emails: items,
        loading: false,
      }),
    );
  }

  private addEmail(item: ContactEmail) {
    this.setState(
      (state): IEmailsListState => ({
        ...state,
        emails: [...state.emails, item],
      }),
    );
  }

  private setEmail(item: ContactEmail) {
    this.setState((state): IEmailsListState => {
      const phones = [...state.emails];
      const index = phones.findIndex((i) => i.address === item.address);
      phones[index] = item;
      return {
        ...state,
        emails: phones,
      };
    });
  }

  private removePhone(number: string) {
    this.setState((state): IEmailsListState => {
      const phones = [...state.emails];
      const index = phones.findIndex((i) => i.address === number);
      phones.splice(index, 1);
      return {
        ...state,
        emails: phones,
      };
    });
  }

  private logError(e: Error) {}
}
