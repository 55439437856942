import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { ActivityPriorityEnum } from '~/activity/models';

@Component({
  selector: 'app-activity-priority-dropdown',
  templateUrl: './activity-priority-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityPriorityDropdownComponent),
      multi: true,
    },
  ],
})
export class ActivityPriorityDropdownComponent implements ControlValueAccessor {
  public priorityOptions = [
    { label: 'activity.priority.lowest', value: ActivityPriorityEnum.LOWEST },
    { label: 'activity.priority.low', value: ActivityPriorityEnum.LOW },
    { label: 'activity.priority.medium', value: ActivityPriorityEnum.MEDIUM },
    { label: 'activity.priority.high', value: ActivityPriorityEnum.HIGH },
    { label: 'activity.priority.highest', value: ActivityPriorityEnum.HIGHEST },
  ];

  @ViewChild(Dropdown, { static: true })
  protected dropdown!: Dropdown;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public showClear = false;

  protected disabled = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  public writeValue(val: any): void {
    this.dropdown.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }
}
