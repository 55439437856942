import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SlimContact } from '~/contact/models';
import { provideComponentStore } from '@ngrx/component-store';
import { SubscriptionEditStore } from './subscription-edit.store';
import { of, Subscription } from 'rxjs';
import {
  ISubscription,
  SubscriptionDictionary,
  SubscriptionType,
} from '../../../models';
import { SubscriptionDictionariesService } from '~/subscription/services/data';
import { IPeriodPayload } from '~/subscription/services/http';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-data-dialog',
  templateUrl: './subscription-data-dialog.component.html',
  providers: [provideComponentStore(SubscriptionEditStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDataDialogComponent implements OnDestroy {
  public form: FormGroup = new FormGroup({
    note: new FormControl<string | null>(null),
    type: new FormControl<SubscriptionType | null>(null, [Validators.required]),
    owner: new FormControl<SlimContact | null>(null, [Validators.required]),
  });

  public contact?: SlimContact;
  public subscription?: ISubscription;
  protected dictionaries: SubscriptionDictionary[] = [];
  private subs = new Subscription();

  constructor(
    protected readonly cs: SubscriptionEditStore,
    private readonly cdRef: ChangeDetectorRef,
    private readonly config: DynamicDialogConfig,
    private readonly dictionariesService: SubscriptionDictionariesService,
  ) {
    this.subscription = this.config.data?.subscription;
    if (this.subscription) {
      this.setValuesFromSub(this.subscription);
    } else {
      this.addPeriodFields();
      this.createDictionaryFieldsSub();
    }

    this.contact = this.config.data?.contact;
    if (this.contact) {
      this.form.patchValue({ owner: this.contact });
      this.form.get('owner')?.disable();
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected submit(): void {
    if (this.form.invalid) return;

    const noteVal = this.form.get('note')?.value;

    this.cs.submitSubscription({
      subscriptionPayload: {
        note: noteVal || '',
        typeId: this.form.get('type')?.value?.id as string,
        ownerId: this.form.get('owner')?.value?.id as string,
      },
      periodPayload: this.getPeriodPayload(),
    });
  }

  protected getPeriodPayload():
    | Omit<IPeriodPayload, 'subscriptionId'>
    | undefined {
    if (this.subscription) {
      return undefined;
    }

    return {
      start: this.form.get('start')?.value as Date,
      end: this.form.get('end')?.value as Date,
      note: this.form.get('periodNote')?.value as string,
      dictionaryValues: this.mapDictionaryValues(),
    };
  }

  private setValuesFromSub(sub: ISubscription) {
    this.form.get('note')?.setValue(sub.note);
    this.form.get('type')?.setValue(sub.type);
    this.form.get('type')?.disable();
    this.form.get('owner')?.setValue(sub.owner || null);
    this.form.get('owner')?.disable();
  }

  private addPeriodFields() {
    this.form.addControl(
      'start',
      new FormControl<Date | null>(null, [Validators.required]),
    );
    this.form.addControl(
      'end',
      new FormControl<Date | null>(null, [Validators.required]),
    );
    this.form.addControl('periodNote', new FormControl<string | null>(''));
  }

  private createDictionaryFieldsSub() {
    const dictionariesSub = this.form
      .get('type')
      ?.valueChanges.pipe(
        switchMap((type) => {
          if (!type) {
            return of([]);
          }
          return this.dictionariesService.getItemsForType$(type.id);
        }),
      )
      .subscribe((dictionaries) => {
        console.log(dictionaries);

        const dictionariesToRemove = this.dictionaries.filter(
          (dictionary) => !dictionaries?.find((d) => d.id === dictionary.id),
        );
        const dictionariesToAdd = dictionaries?.filter(
          (dictionary) =>
            !this.dictionaries.find((d) => d.id === dictionary.id),
        );
        this.dictionaries = dictionaries;

        // Remove controls for dictionaries that are no longer available
        for (let dictionary of dictionariesToRemove) {
          this.form.removeControl(this.dictionaryFormKey(dictionary.id));
        }

        // Add controls for new dictionaries
        for (let dictionary of dictionariesToAdd) {
          this.form.addControl(
            this.dictionaryFormKey(dictionary.id),
            new FormControl([]),
          );
        }

        this.cdRef.markForCheck();
      });

    this.subs.add(dictionariesSub);
  }

  private mapDictionaryValues() {
    return this.dictionaries
      .map((dictionary) => {
        const control = this.form.get(this.dictionaryFormKey(dictionary.id));
        if (
          !control?.value ||
          (Array.isArray(control?.value) && control?.value.length === 0)
        ) {
          return undefined;
        }

        return {
          dictionaryId: dictionary.id,
          valueIds: control.value || [],
        };
      })
      .filter((i) => i !== undefined) as {
      dictionaryId: string;
      valueIds: string[];
    }[];
  }

  protected dictionaryFormKey(dictionaryId: string) {
    return `dictionaryValues-${dictionaryId}`;
  }
}
