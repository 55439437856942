import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-new-item-button',
  template: `
    <button
      pButton
      class="p-button-outlined"
      icon="pi pi-plus"
      [label]="label ? label : ('widgets.buttons.addNewItem' | translate)"
      [disabled]="disabled"
      (click)="!disabled && add.emit()"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewItemButtonComponent {
  @Input() public label?: string;

  @Input() public disabled = false;

  @Output() public add = new EventEmitter<void>();
}
