import { Company, Contact, ContactType, Person, SlimContact } from '../models';
import { personFullName } from './person-full-name';

export const contactToSlim = (contact: Contact): SlimContact => {
  switch (contact.type) {
    case ContactType.Person:
      return {
        id: contact.id,
        type: contact.type,
        name: personFullName((contact as Person).personName),
      };
    case ContactType.Company:
      return {
        id: contact.id,
        type: contact.type,
        name: (contact as Company).shortName,
      };
    default:
      throw new Error('Invalid contact type');
  }
};
