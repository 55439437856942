@if (cs.activity$ | async; as activity) {
  <p-table
    #dt
    [value]="(cs.loadedList$ | async)?.items || []"
    [totalRecords]="(cs.loadedList$ | async)?.total || 0"
    [filters]="{ parentId: { value: activity.id } }"
    [paginator]="(cs.showPaginator$ | async) || false"
    responsiveLayout="scroll"
    [filterDelay]="500"
    dataKey="id"
    [lazy]="true"
    (onLazyLoad)="load($event)"
    [rowsPerPageOptions]="[10]"
    [rows]="10"
  >
    <ng-template pTemplate="body" let-activity let-expanded="expanded">
      <tr
        app-activity-row
        [routerLink]="['/activities', activity.id]"
        [activity]="activity"
        [expanded]="expanded"
        [small]="true"
        (toggleExpand)="dt.toggleRow($event.id)"
        (update)="cs.openUpdateDialog($event)"
        (delete)="cs.deleteItem($event.id)"
        class="cursor-pointer"
      ></tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-activity>
      <tr>
        <td colspan="3" class="border-left-1 border-solid pl-6">
          <app-activity-row-expand
            [activity]="activity"
          ></app-activity-row-expand>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <app-empty-space
        [message]="'activity.text.noSubActivitiesYet' | translate"
      >
        <app-new-item-button
          (click)="cs.openCreateDialog()"
        ></app-new-item-button>
      </app-empty-space>
    </ng-template>
  </p-table>

  @if (cs.loadedList$ | async; as loadedList) {
    @if (loadedList.total > 0) {
      <div class="flex justify-content-end gap-2 mt-3">
        <a
          pButton
          class="p-button-outlined"
          [label]="'activity.button.subActivitiesList' | translate"
          [routerLink]="['/activities']"
          [queryParams]="{ parent: (cs.activity$ | async)?.id }"
        ></a>
        <app-new-item-button
          (click)="cs.openCreateDialog()"
        ></app-new-item-button>
      </div>
    }
  }
}
