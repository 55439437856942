import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollContentService {
  public scroll = new Subject<any>();

  public scrollToTop(): void {
    this.scroll.next(null);
  }
}
