import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ActivityPayload } from '~/activity/services/http/activity.payloads';
import { ActivityHttpService } from 'src/app/activity/services/http';
import { Activity } from '~/activity/models';
import { ErrorDialogService } from '~/@core/modules/error-dialog';

export interface ICreateState {
  activity?: Activity;
  parent?: Activity;
  busy: boolean;
}

const initialState = (
  activity?: Activity,
  parent?: Activity,
): ICreateState => ({
  activity: activity,
  parent: parent,
  busy: false,
});

@Injectable()
export class ActivityDataDialogStore extends ComponentStore<ICreateState> {
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  constructor(
    private readonly activityHttpService: ActivityHttpService,
    private readonly ref: DynamicDialogRef,
    private readonly errorDialogService: ErrorDialogService,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data?.activity, config.data?.parent));
  }

  public readonly submitTask = this.effect<ActivityPayload>((trigger$) => {
    return trigger$.pipe(
      tap(() => this.patchState({ busy: true })),
      withLatestFrom(this.state$),
      switchMap(([data, state]) => {
        if (state.activity) {
          const activity = state.activity;
          return this.activityHttpService
            .update(activity.id, { parentId: activity.parent?.id, ...data })
            .pipe(
              tap((dictionary) => this.ref.close(dictionary)),
              this.errorDialogService.handleHttpError(),
            );
        }

        const createData = state.parent
          ? {
              parentId: state.parent.id,
              ...data,
            }
          : data;
        return this.activityHttpService.create(createData).pipe(
          tap((dictionary) => this.ref.close(dictionary)),
          this.errorDialogService.handleHttpError(),
        );
      }),
      tap(() => this.patchState({ busy: false })),
    );
  });
}
