@if (cs.isBusy$ | async) {
  <app-loading-space></app-loading-space>
} @else {
  @if (cs.items$ | async; as emails) {
    @if (emails.length > 0) {
      <ul class="list-none p-0 m-0">
        @for (item of emails; track $index) {
          <li>
            <app-email-item
              *ngIf="cs.isItemInViewMode$(item.address) | async"
              [item]="item"
              [edit]="edit"
              (update)="cs.setItemToEditMode(item.address)"
              (delete)="cs.deleteItem(item.address)"
            ></app-email-item>
            <app-email-edit
              *ngIf="cs.isItemInEditMode$(item.address) | async"
              [item]="item"
              (save)="cs.updateItem({ number: item.address, payload: $event })"
              (cancel)="cs.setItemToViewMode(item.address)"
            ></app-email-edit>
          </li>
        }
      </ul>
      @if (edit) {
        <div class="flex justify-content-end mt-4">
          <app-new-item-button
            [label]="'contact.button.addEmail' | translate"
            (click)="cs.showCreateDialog()"
          />
        </div>
      }
    } @else {
      <app-empty-space>
        @if (edit) {
          <app-new-item-button
            [label]="'contact.button.addEmail' | translate"
            (click)="cs.showCreateDialog()"
          />
        }
      </app-empty-space>
    }
  }
}
