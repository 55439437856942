import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardEditButtonComponent } from './card-edit-button.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [CardEditButtonComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, SharedExtModule],
  exports: [CardEditButtonComponent],
})
export class CardEditButtonModule {}
