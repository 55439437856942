import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityViewStore } from '~/activity/pages/activity-view/activity-view.store';

@Component({
  selector: 'app-activity-comments',
  templateUrl: './activity-comments.component.html',
  styleUrls: ['./activity-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCommentsComponent {
  constructor(protected readonly cs: ActivityViewStore) {}

  protected toggleCommentActions(event: Event, opt: HTMLElement) {
    if (event.type === 'mouseenter') {
      opt.style.display = 'flex';
    } else {
      opt.style.display = 'none';
    }
  }
}
