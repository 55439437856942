import { Injectable, OnDestroy } from '@angular/core';
import {
  ISubscriptionTypeData,
  SubscriptionTypeHttpService,
} from '~/subscription/services/http';
import { SubscriptionType } from '~/subscription/models';
import {
  BehaviorSubject,
  filter,
  interval,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionTypesService implements OnDestroy {
  private items$: BehaviorSubject<Array<SubscriptionType>> =
    new BehaviorSubject(new Array<SubscriptionType>());
  private loading$ = new BehaviorSubject(false);
  private subs = new Subscription();

  constructor(private readonly http: SubscriptionTypeHttpService) {
    this.loadItems();
    this.createIntervalLoading();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public getItems$(forceLoading = false) {
    if (forceLoading) {
      this.loadItems();
    }
    return this.items$;
  }

  public getLoading$() {
    return this.loading$;
  }

  public createItem(data: ISubscriptionTypeData) {
    return this.http.create(data).pipe(
      withLatestFrom(this.items$),
      tap(([item, items]) => {
        this.items$.next([...items, item]);
      }),
      map(([item, items]) => item),
    );
  }

  public updateItem(id: string, data: ISubscriptionTypeData) {
    return this.http.update(id, data).pipe(
      withLatestFrom(this.items$),
      tap(([item, items]) => {
        const index = items.findIndex((i) => i.id === item.id);
        items[index] = item;
        this.items$.next([...items]);
      }),
      map(([item, items]) => item),
    );
  }

  public deleteItem(id: string) {
    return this.http.delete(id).pipe(
      withLatestFrom(this.items$),
      tap(([_, items]) => {
        const index = items.findIndex((i) => i.id === id);
        items.splice(index, 1);
        this.items$.next([...items]);
      }),
    );
  }

  private createIntervalLoading() {
    const intSub = interval(300000)
      .pipe(
        withLatestFrom(this.loading$),
        filter(([_, loading]) => !loading),
      )
      .subscribe(() => {
        if (this.items$.observed) {
          this.loadItems();
        }
      });
    this.subs.add(intSub);
  }

  private loadItems() {
    this.loading$.next(true);
    this.http.getAll().subscribe((res) => {
      this.items$.next(res.items);
      this.loading$.next(false);
    });
  }
}
