import { NgModule } from '@angular/core';
import { RowConfirmButtonComponent } from './row-confirm-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [RowConfirmButtonComponent],
  imports: [SharedExtModule],
  exports: [RowConfirmButtonComponent],
})
export class RowConfirmButtonModule {}
