import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ImportColumnConfiguration,
  ImportResult,
} from '~/@core/modules/importer/models';
import {
  catchError,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { isUUIDv4 } from '~/@core/utils';
import {
  ActivityHttpService,
  ActivityStatusHttpService,
  ActivityTypeHttpService,
} from '~/activity/services/http';
import { Activity, ActivityStatus, ActivityType } from '~/activity/models';
import { map } from 'rxjs/operators';
import { DateTimeService } from '~/@core/services/transformers/date-time.service';
import { ContactGetterService } from '~/contact/@feature/services/contact-getter.service';
import { UserHttpService } from '~/user/services/http';
import { User } from '~/user/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-import',
  templateUrl: './activities-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesImportComponent implements OnInit, OnDestroy {
  protected readonly columnConfigurations: ImportColumnConfiguration[] = [
    {
      key: 'title',
      name: 'activity.label.title',
      required: true,
      type: 'string',
    },
    {
      key: 'contactIdentifier',
      name: 'activity.label.contactIdentifier',
      required: false,
      type: 'string',
    },
    {
      key: 'parentId',
      name: 'activity.label.parent',
      required: false,
      type: 'string',
    },
    {
      key: 'activityType',
      name: 'activity.label.type',
      required: true,
      type: 'string',
    },
    {
      key: 'activityStatus',
      name: 'activity.label.status',
      required: true,
      type: 'string',
    },
    {
      key: 'priority',
      name: 'activity.label.priority',
      required: true,
      type: 'number',
    },
    {
      key: 'description',
      name: 'activity.label.description',
      required: false,
      type: 'string',
    },
    {
      key: 'actors',
      name: 'activity.label.actors',
      required: false,
      type: 'string',
    },
    {
      key: 'supervisors',
      name: 'activity.label.supervisors',
      required: false,
      type: 'string',
    },
    {
      key: 'start',
      name: 'activity.label.startAt',
      required: false,
      type: 'string',
    },
    {
      key: 'end',
      name: 'activity.label.endAt',
      required: false,
      type: 'string',
    },
  ];
  private types: ActivityType[] = [];
  private statuses: ActivityStatus[] = [];
  private users: User[] = [];
  private subs = new Subscription();

  constructor(
    private readonly contactGetterService: ContactGetterService,
    private readonly activityHttpService: ActivityHttpService,
    private readonly typeService: ActivityTypeHttpService,
    private readonly statusService: ActivityStatusHttpService,
    private readonly userService: UserHttpService,
    private readonly dateTimeService: DateTimeService,
    private readonly router: Router,
  ) {}

  public ngOnInit() {
    this.getTypes();
    this.getStatuses();
    this.getUsers();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public importFunction = (data: any): Observable<ImportResult> => {
    return this.contactGetterService
      .getOrCreateContact$(data.contactIdentifier)
      .pipe(
        switchMap((contact) => {
          if (!contact) {
            return throwError(() => new Error('No contact found'));
          }

          const type = this.getType(data.activityType);
          if (!type) {
            return throwError(() => new Error('No type found'));
          }

          const status = this.getStatus(data.activityStatus);
          if (!status) {
            return throwError(() => new Error('No status found'));
          }

          const actors =
            data.actors && data.actors !== ''
              ? data.actors
                  .split(',')
                  .map((actor: string) => this.getUser(actor))
                  .filter((actor: any) => !!actor)
                  .map((actor: any) => actor.id)
              : [];

          const supervisors =
            data.supervisors && data.supervisors !== ''
              ? data.supervisors
                  .split(',')
                  .map((supervisor: string) => this.getUser(supervisor))
                  .filter((supervisor: any) => !!supervisor)
                  .map((supervisor: any) => supervisor.id)
              : [];

          return this.activityHttpService.create({
            title: data.title,
            contactId: contact.id,
            parentId: data.parentId,
            typeId: type.id,
            statusId: status.id,
            priority: parseInt(data.priority),
            description: data.description,
            actors: actors,
            supervisors: supervisors,
            estimatedStartAt: data.start
              ? this.dateTimeService.toApiDateTime(new Date(data.start))
              : undefined,
            estimatedEndAt: data.end
              ? this.dateTimeService.toApiDateTime(new Date(data.end))
              : undefined,
          });
        }),
        map<Activity, ImportResult>((activity) => {
          return {
            resultId: activity.id,
            resultLink: this.createLink(activity.id),
            errors: [],
          };
        }),
        catchError((err: HttpErrorResponse) =>
          of({
            result: undefined,
            errors: [err.message],
          }),
        ),
      );
  };

  private getType(data: string) {
    if (isUUIDv4(data)) {
      return this.types.find((t) => t.id === data);
    }

    return this.types.find((t) => t.name.toLowerCase() === data.toLowerCase());
  }

  private getStatus(data: string) {
    if (isUUIDv4(data)) {
      return this.statuses.find((t) => t.id === data);
    }

    return this.statuses.find(
      (t) => t.name.toLowerCase() === data.toLowerCase(),
    );
  }

  private getUser(data: string) {
    if (isUUIDv4(data)) {
      return this.users.find((t) => t.id === data);
    }

    return this.users.find((t) => t.email.toLowerCase() === data.toLowerCase());
  }

  private getTypes() {
    const sub = this.typeService.getAll().subscribe((typesCollection) => {
      this.types = typesCollection.items;
    });
    this.subs.add(sub);
  }

  private getStatuses() {
    const sub = this.statusService.getAll().subscribe((collection) => {
      this.statuses = collection.items;
    });
    this.subs.add(sub);
  }

  private getUsers() {
    const sub = this.userService.getAll().subscribe((collection) => {
      this.users = collection.items;
    });
    this.subs.add(sub);
  }

  private createLink(id: string) {
    return (
      window.location.origin +
      this.router.serializeUrl(this.router.createUrlTree(['/activities', id]))
    );
  }
}
