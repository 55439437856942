import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent implements ControlValueAccessor {
  @Input()
  public height = '150px';

  @ViewChild(Editor, { static: true })
  protected editor!: Editor;

  get style(): { [key: string]: string } {
    return {
      height: this.height,
    };
  }

  public writeValue(val: any): void {
    this.editor.writeValue(val);
  }

  public registerOnChange(fn: any): void {
    this.editor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.editor.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    // TODO: Implement
  }
}
