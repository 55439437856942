import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Contact } from '~/contact/models';
import { Store } from '@ngrx/store';
import { refreshItems } from '~/contact/store/contact-dictionary/contact-dictionary.actions';
import { Subscription } from 'rxjs';
import { selectContactDictionaryId } from '~/contact/store/contact-dictionary/contact-dictionary-items.selectors';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInfoComponent implements OnInit {
  @Input()
  public contact!: Contact;

  private subs = new Subscription();

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(refreshItems());
  }

  protected getDictionary$(id: string) {
    return this.store.select(selectContactDictionaryId(id));
  }

  protected valueLabels(val: { label: string }[]): string[] {
    return val.map((i) => i.label);
  }
}
