import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';

import { ContactSearchStore } from './contact-search.store';
import { ContactNamePipe } from '~/contact/@common/pipes/contact-name.pipe';
import { ContactType } from '~/contact/models';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  providers: [
    provideComponentStore(ContactSearchStore),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactSearchComponent),
      multi: true,
    },
  ],
})
export class ContactSearchComponent implements ControlValueAccessor {
  @ViewChild(AutoComplete, { static: true })
  public ac!: AutoComplete;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public multiple: boolean = false;

  @Input()
  public set type(type: ContactType | undefined) {
    this.cs.setType(type);
  }

  constructor(
    public readonly cs: ContactSearchStore,
    public readonly namePipe: ContactNamePipe,
  ) {}

  public writeValue(val: any): void {
    if (Array.isArray(val)) {
      this.ac.writeValue(
        val.map((v) => ({ ...v, label: this.namePipe.transform(v) })),
      );
      return;
    }

    if (!val) {
      this.ac.writeValue(undefined);
      return;
    }

    this.ac.writeValue({
      ...val,
      label: this.namePipe.transform(val),
    });
  }

  public registerOnChange(fn: any): void {
    this.ac.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.ac.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.ac.setDisabledState(isDisabled);
  }

  public search(event: any) {
    this.cs.findSuggestions(event.query);
  }
}
