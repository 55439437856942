import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { IHooked } from '~/hook/models';
import { HOOKS } from '~/hook/tokens';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent implements OnInit {
  constructor(@Inject(HOOKS) private readonly hooks: IHooked[]) {}

  public readonly hookName = 'footer';

  public footerWidgets: IHooked[] = [];

  public ngOnInit(): void {
    this.footerWidgets = this.hooks.filter(
      (hook) => hook.hookName === this.hookName,
    );
  }
}
