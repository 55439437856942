<div class="card">
  <p-table
    #dt
    *ngIf="cs.loadedList$ | async as list"
    [value]="list.items"
    [totalRecords]="list.total"
    [lazy]="true"
    [paginator]="true"
    [rows]="25"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [currentPageReportTemplate]="'common.text.pagination' | translate"
    [rowsPerPageOptions]="[10, 25, 50]"
    [filterDelay]="500"
    dataKey="id"
    (onLazyLoad)="load($event)"
  >
    <ng-template pTemplate="caption">
      <app-table-header>
        <ng-container filter>
          <app-table-filter
            (filter)="dt.filterGlobal($event, 'equals')"
          ></app-table-filter>
        </ng-container>
        <ng-container actions>
          <div class="flex flex-wrap gap-2">
            <app-new-item-button
              (add)="cs.showCreateDialog()"
              [disabled]="(canManageUsers$ | async) === false"
            ></app-new-item-button>
          </div>
        </ng-container>
      </app-table-header>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th>
          {{ "user.label.email" | translate }}
        </th>
        <th>
          {{ "user.label.name" | translate }}
        </th>
        <th>
          {{ "user.label.createdAt" | translate }}
        </th>
        <th class="white-space-nowrap"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.email }}</td>
        <td>{{ user.name }}</td>
        <td>{{ user.createdAt | date }}</td>
        <td>
          <div class="flex gap-2 justify-content-end">
            <app-row-edit-button
              (edit)="cs.showUpdateDialog(user)"
              [disabled]="(canManageUsers$ | async) === false"
            ></app-row-edit-button>
            <app-user-password-button
              (changePassword)="cs.showPasswordDialog(user)"
              [disabled]="(canManageUsers$ | async) === false"
            ></app-user-password-button>
            <app-row-delete-button
              (delete)="cs.deleteItem(user.id)"
              [disabled]="(canManageUsers$ | async) === false"
            ></app-row-delete-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
