import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ActivityEditDialogStore } from './activity-comment-edit-dialog.store';

@Component({
  selector: 'app-action-edit-dialog',
  templateUrl: './activity-comment-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActivityEditDialogStore],
})
export class ActivityCommentEditDialogComponent implements OnDestroy, OnInit {
  public form = this.createForm();

  private subs = new Subscription();

  constructor(
    private readonly config: DynamicDialogConfig,
    protected readonly cs: ActivityEditDialogStore,
  ) {}

  public ngOnInit(): void {
    this.subscribeToFooterSave();
    this.subscribeForFormValid();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public submit() {
    if (this.form.invalid) return;
    this.cs.submit({
      text: this.form.get('comment')?.value as string,
    });
  }

  private subscribeToFooterSave() {
    const sub = this.config.data.footerSubmit.subscribe(() => {
      this.submit();
    });
    this.subs.add(sub);
  }

  private subscribeForFormValid() {
    const sub = this.form.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.config.data.formBlocked.next(false);
      } else {
        this.config.data.formBlocked.next(true);
      }
    });
    this.subs.add(sub);
  }

  private createForm() {
    const data = this.config.data?.comment;

    return new FormGroup({
      comment: new FormControl<string>(data?.text || '', [Validators.required]),
    });
  }
}
