import { Component, Inject, OnInit } from '@angular/core';
import {
  ADMIN_MENU_ITEM_TOKEN,
  MENU_ITEM_TOKEN,
} from '~/layout/menu-item.token';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(
    @Inject(MENU_ITEM_TOKEN) private readonly featureMenus: any[],
    @Inject(ADMIN_MENU_ITEM_TOKEN) private readonly adminItems: any[],
    private readonly trans: TranslateService,
  ) {}

  ngOnInit() {
    this.model = [
      {
        label: this.trans.instant('common.menu.homepage'),
        icon: 'pi pi-home',
        routerLink: ['/'],
      },
      ...this.featureMenus.flat(),
      {
        label: this.trans.instant('common.menu.administration'),
        icon: 'pi pi-cog',
        items: [...this.adminItems.flat()],
      },
    ];
  }
}
