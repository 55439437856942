import { HttpClient } from '@angular/common/http';
import { EMPTY, expand, Observable, reduce } from 'rxjs';
import { Injectable } from '@angular/core';

import { ICollection, IPagination } from '../../@core/models';
import {
  DictionaryItemFilter,
  DictionaryItemPayload,
  IDictionaryItem,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class DictionaryItemHttpService {
  constructor(private http: HttpClient) {}

  public create(
    dictionaryId: string,
    data: DictionaryItemPayload,
  ): Observable<IDictionaryItem> {
    return this.http.post<IDictionaryItem>(
      `/api/dictionaries/${dictionaryId}/items`,
      data,
    );
  }

  public getList(
    dictionaryId: string,
    filter?: DictionaryItemFilter,
    pagination?: IPagination,
  ): Observable<ICollection<IDictionaryItem>> {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };

    return this.http.get<ICollection<IDictionaryItem>>(
      `/api/dictionaries/${dictionaryId}/items`,
      {
        params: {
          ...filter,
          ...paginationQuery,
        },
      },
    );
  }

  public getAllForDictionary(
    dictionaryId: string,
    filter?: DictionaryItemFilter,
  ): Observable<ICollection<IDictionaryItem>> {
    const pagination = {
      page: 1,
      perPage: 50,
    };
    return this.getList(dictionaryId, filter, pagination).pipe(
      expand((response) => {
        if (response.total <= pagination.page * pagination.perPage) {
          return EMPTY;
        }
        pagination.page++;
        return this.getList(dictionaryId, filter, pagination);
      }),
      reduce(
        (acc, current) => ({
          items: acc.items.concat(current.items),
          total: current.total,
        }),
        {
          items: [],
          total: 0,
        } as ICollection<IDictionaryItem>,
      ),
    );
  }

  public update(
    dictionaryId: string,
    id: string,
    data: DictionaryItemPayload,
  ): Observable<IDictionaryItem> {
    return this.http.put<IDictionaryItem>(
      `/api/dictionaries/${dictionaryId}/items/${id}`,
      data,
    );
  }

  public delete(dictionaryId: string, id: string): Observable<void> {
    return this.http.delete<void>(
      `/api/dictionaries/${dictionaryId}/items/${id}`,
    );
  }
}
