import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { authFeatureKey, authReducer } from './store/auth.reducer';
import { AuthInterceptor } from './interceptors';
import { AuthService } from './http/auth.service';
import { metaReducers } from './store/auth.meta-reducers';
import { AuthenticatedGuard } from './guards';
import { AuthEffects } from './store/auth.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(authFeatureKey, authReducer, { metaReducers }),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    AuthService,
    AuthenticatedGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthRootModule {
  public constructor(@Optional() @SkipSelf() parentModule?: AuthRootModule) {
    if (parentModule) {
      throw new Error(
        'AuthRootModule is already loaded. Import it in the AppModule or CoreModule only',
      );
    }
  }
}
