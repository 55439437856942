import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { Observable, of, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { selectItemsForDictionaryList } from '~/dictionary/store/dictionary-items/dictionary-items.selectors';
import { refreshDictionaryItems } from '~/dictionary/store/dictionary-items/dictionary-items.actions';

import {
  CompanyHttpService,
  SlimContactHttpService,
} from '../../services/http';
import {
  Company,
  CompanyPayload,
  ConfigurationValuesEnum,
  ContactDictionary,
  ContactRestriction,
  ContactType,
} from '../../models';
import { Store } from '@ngrx/store';
import { selectContactDictionaryList } from '~/contact/store/contact-dictionary/contact-dictionary-items.selectors';
import { refreshItems } from '~/contact/store/contact-dictionary/contact-dictionary.actions';
import { selectContactContactConfigurationMapFor } from '~/contact/store/configuration/configuration.selectors';

export interface ICompanyDataDialogState {
  company?: Company;
  busy: boolean;
}

const initialState = (company?: Company): ICompanyDataDialogState => ({
  company,
  busy: false,
});

@Injectable()
export class CompanyDataDialogStore
  extends ComponentStore<ICompanyDataDialogState>
  implements OnStoreInit
{
  public readonly isBusy$: Observable<boolean> = this.select(
    (state) => state.busy,
  );

  public readonly dictionaries$: Observable<ContactDictionary[]> = this.store
    .select(selectContactDictionaryList)
    .pipe(
      map((dictionaries) =>
        dictionaries.filter((d) =>
          [ContactRestriction.Company, ContactRestriction.Any].includes(
            d.contactType,
          ),
        ),
      ),
      tap((dictionaries) =>
        dictionaries.forEach((d) =>
          this.store.dispatch(
            refreshDictionaryItems({ dictionaryId: d.dictionary.id }),
          ),
        ),
      ),
    );

  public identConfiguration$ = this.store.select(
    selectContactContactConfigurationMapFor([
      ConfigurationValuesEnum.companyIdentRequired,
      ConfigurationValuesEnum.companyIdentRegex,
      ConfigurationValuesEnum.companyIdentUnique,
    ]),
  );

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly companyService: CompanyHttpService,
    private readonly slimContactHttp: SlimContactHttpService,
    private readonly store: Store,
    config: DynamicDialogConfig,
  ) {
    super(initialState(config.data?.company));
  }

  public ngrxOnStoreInit() {
    this.store.dispatch(refreshItems());
  }

  public readonly submitCompanyData = this.effect<CompanyPayload>(
    (trigger$) => {
      return trigger$.pipe(
        tap(() => this.patchState({ busy: true })),
        withLatestFrom(this.select((state) => state.company)),
        switchMap(([data, company]) =>
          company
            ? this.updateCompany(company.id, data)
            : this.createCompany(data),
        ),
      );
    },
  );

  public dictionaryItems$ = (dictionaryId: string) =>
    this.store.select(selectItemsForDictionaryList(dictionaryId));

  public identIsUnique(ident: string) {
    return this.slimContactHttp
      .getList({ ident, type: ContactType.Company }, { page: 1, perPage: 1 })
      .pipe(
        withLatestFrom(this.select((state) => state.company)),
        switchMap(([companies, company]) => {
          return of(
            companies.items.length === 0 ||
              (!!company &&
                companies.total === 1 &&
                companies.items[0].id === company.id),
          );
        }),
      );
  }

  private logError(e: Error) {}

  private createCompany(data: CompanyPayload) {
    return this.companyService.create(data).pipe(
      tapResponse(
        (company) => {
          this.ref.close(company);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }

  private updateCompany(id: string, data: CompanyPayload) {
    return this.companyService.update(id, data).pipe(
      tapResponse(
        (company) => {
          this.ref.close(company);
        },
        (e: HttpErrorResponse) => this.logError(e),
      ),
    );
  }
}
