import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { ActivityStatus } from '~/activity/models';
import { Store } from '@ngrx/store';
import {
  selectActivityStatuses,
  selectActivityStatusesLoading,
} from '~/activity/@root/store/activity-status/activity-status.selectors';
import { checkActivityStatuses } from '~/activity/@root/store/activity-status/activity-status.actions';

@Component({
  selector: 'app-activity-status-dropdown',
  templateUrl: './activity-status-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityStatusDropdownComponent),
      multi: true,
    },
  ],
})
export class ActivityStatusDropdownComponent implements ControlValueAccessor {
  @ViewChild(Dropdown, { static: true })
  protected dropdown!: Dropdown;

  @Input()
  public appendTo?: string | HTMLElement;

  @Input()
  public showClear = false;

  @Input()
  public placeholder?: string;

  protected types$: Observable<ActivityStatus[]> = this.store.select(
    selectActivityStatuses,
  );

  protected loading$ = this.store.select(selectActivityStatusesLoading);

  protected disabled = false;

  constructor(
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.store.dispatch(checkActivityStatuses());
  }

  public writeValue(val: any): void {
    this.dropdown.writeValue({
      ...val,
    });
  }

  public registerOnChange(fn: any): void {
    this.dropdown.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.dropdown.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.dropdown.setDisabledState(isDisabled);
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }
}
