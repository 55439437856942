import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';

import { SharedExtModule } from '~/shared-ext.module';
import { SharedModule } from '~/shared.module';

import { UserRoutingModule } from './user-routing.module';
import {
  UserPasswordButtonComponent,
  UsersListComponent,
} from './pages/users-list';
import { UserFeatureModule } from './@feature';
import { UserRootModule } from './user-root.module';
import { UserEditComponent } from './components/user-edit';
import {
  UserDialogService,
  UserPasswordDialogService,
} from './services/dialogs';
import { UserPasswordComponent } from './components/user-password';

@NgModule({
  imports: [
    SharedExtModule,
    SharedModule,
    ProgressBarModule,
    FileUploadModule,
    UserRoutingModule,
    ReactiveFormsModule,
    MenuModule,
    MultiSelectModule,
  ],
  providers: [UserDialogService, UserPasswordDialogService],
  declarations: [
    UsersListComponent,
    UserPasswordButtonComponent,
    UserEditComponent,
    UserPasswordComponent,
  ],
})
export class UserModule {
  static forFeature(): ModuleWithProviders<UserFeatureModule> {
    return {
      ngModule: UserFeatureModule,
    };
  }

  static forRoot(): ModuleWithProviders<UserRootModule> {
    return {
      ngModule: UserRootModule,
    };
  }
}
