import { ICollection, IPagination } from '../../models';

export interface IListState<Entity, Filter = undefined> {
  loadedList: ICollection<Entity>;
  loading: boolean;
  filter: Filter;
  pagination: IPagination;
}

export const initialListState = <Entity, Filter>(
  initFilter: Filter,
): IListState<Entity, Filter> => ({
  loadedList: {
    items: [],
    total: 0,
    page: 0,
    perPage: 0,
  },
  loading: false,
  filter: initFilter,
  pagination: {
    page: 1,
    perPage: 10,
  },
});
