import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import {
  fetchLinkTypes,
  fetchLinkTypesFail,
  fetchLinkTypesSuccess,
} from './link-type.action';
import { LinkType } from '~/contact/models/link-type';

export const linkTypeEntityAdapter = createEntityAdapter<LinkType>();

export interface LinkTypeState extends EntityState<LinkType> {
  loading: boolean;
}

const createDictionaryInitState = (): LinkTypeState =>
  linkTypeEntityAdapter.getInitialState({
    loading: false,
  });

export const linkTypeReducer = createReducer<LinkTypeState>(
  createDictionaryInitState(),
  on(
    fetchLinkTypes,
    (state): LinkTypeState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    fetchLinkTypesSuccess,
    (state, { items }): LinkTypeState =>
      linkTypeEntityAdapter.setAll(items, {
        ...state,
        loading: false,
      }),
  ),
  on(
    fetchLinkTypesFail,
    (state): LinkTypeState => ({
      ...state,
      loading: false,
    }),
  ),
);
