import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ICollection, IPagination } from '~/@core/models';
import { filterUndefinedParams } from '~/@core/utils';

import { SlimContact } from '../../models';
import { ISlimContactFilter } from '~/contact/services/http/slim-contact.http-service';

@Injectable({
  providedIn: 'root',
})
export class DeletedContactHttpService {
  protected constructor(protected http: HttpClient) {}

  public getList(
    filter?: ISlimContactFilter,
    pagination?: IPagination,
  ): Observable<ICollection<SlimContact>> {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<SlimContact>>('/api/deleted-contacts', {
      params: filterUndefinedParams({
        ...filter,
        ...paginationQuery,
      }),
    });
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/deleted-contacts/${id}`);
  }

  public restore(id: string): Observable<SlimContact> {
    return this.http.post<SlimContact>(`/api/restored-contacts/`, {
      id,
    });
  }
}
