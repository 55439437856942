import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, IAuthState } from './auth.reducer';

export const selectAuthFeature =
  createFeatureSelector<IAuthState>(authFeatureKey);

export const selectAuthToken = createSelector(
  selectAuthFeature,
  (state) => state.authToken,
);

export const selectIsLoggedIn = createSelector(
  selectAuthFeature,
  (state) => !!state.authToken,
);

export const selectTokenPayload = createSelector(selectAuthToken, (token) => {
  if (!token) {
    return null;
  }

  const [, payload] = token.split('.');
  return JSON.parse(atob(payload));
});

export const selectUserName = createSelector(selectTokenPayload, (payload) => {
  if (!payload) {
    return null;
  }

  return payload.username;
});

export const selectUserId = createSelector(selectTokenPayload, (payload) => {
  if (!payload) {
    return null;
  }

  return payload.sub;
});

export const selectUserHasRole = (roles: string | string[]) =>
  createSelector(selectTokenPayload, (payload) => {
    if (!payload) {
      return false;
    }

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    return roles.some((role) => payload.roles.includes(role));
  });
