import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { ADMIN_MENU_ITEM_TOKEN } from '~/layout/menu-item.token';

import { dictionaryFeatureKey, dictionaryReducer } from './store/reducer';
import { dictionaryEffects } from './store/effects';
import { DictionaryItemHttpService } from './http';

const menuItemsFactory = (trans: TranslateService) => [
  {
    label: trans.instant('dictionary.menu.dictionaries'),
    icon: 'pi pi-database',
    routerLink: ['/dictionaries'],
  },
];

@NgModule({
  providers: [
    DictionaryItemHttpService,
    {
      provide: ADMIN_MENU_ITEM_TOKEN,
      deps: [TranslateService],
      multi: true,
      useFactory: menuItemsFactory,
    },
  ],
  imports: [
    StoreModule.forFeature(dictionaryFeatureKey, dictionaryReducer),
    EffectsModule.forFeature(dictionaryEffects),
  ],
})
export class DictionaryRootModule {
  public constructor(
    @Optional() @SkipSelf() parentModule?: DictionaryRootModule,
  ) {
    if (parentModule) {
      throw new Error(
        'DictionaryRootModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
