import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { IListState, initialListState } from '~/@core/store/states';
import { IDictionary, IDictionaryFilter } from '../../models';
import { DictionaryHttpService } from '../../http';
import { ICollection } from '~/@core/models';

export enum DictionariesViewStates {
  List = 'list',
  Create = 'create',
  View = 'view',
  Edit = 'edit',
}

export interface IDictionariesState
  extends IListState<IDictionary, IDictionaryFilter> {
  viewState: DictionariesViewStates;
  dictionaryId: string | null;
}

const initialState = (): IDictionariesState => ({
  ...initialListState<IDictionary, IDictionaryFilter>({
    search: '',
  }),
  viewState: DictionariesViewStates.List,
  dictionaryId: null,
});

@Injectable()
export class DictionariesStore
  extends ComponentStore<IDictionariesState>
  implements OnStoreInit
{
  public readonly dictionaryId$: Observable<string | null> = this.select(
    (state) => state.dictionaryId,
  );

  public readonly selectedDictionary$: Observable<IDictionary | undefined> =
    this.select((state) =>
      state.dictionaryId !== null
        ? state.loadedList.items.find((item) => item.id === state.dictionaryId)
        : undefined,
    );
  public readonly listLoading$: Observable<string | null> = this.select(
    (state) => state.dictionaryId,
  );

  public readonly filteredDictionaries$: Observable<IDictionary[]> =
    this.select((state) =>
      state.filter.search
        ? state.loadedList.items.filter((item) =>
            item.name.toLowerCase().includes(state.filter.search.toLowerCase()),
          )
        : state.loadedList.items,
    );

  public readonly showCreate$: Observable<boolean> = this.select(
    (state) => state.viewState === DictionariesViewStates.Create,
  );

  public readonly showDictionaryInfo$: Observable<boolean> = this.select(
    (state) => state.viewState === DictionariesViewStates.View,
  );

  public readonly showUpdate$: Observable<boolean> = this.select(
    (state) => state.viewState === DictionariesViewStates.Edit,
  );

  public readonly showItems$: Observable<boolean> = this.select(
    (state) =>
      state.viewState === DictionariesViewStates.Edit ||
      state.viewState === DictionariesViewStates.View,
  );

  constructor(private readonly dictionaryService: DictionaryHttpService) {
    super(initialState());
  }

  public ngrxOnStoreInit() {
    this.setState((state) => ({ ...state, loading: true }));
    this.dictionaryService.getAll().subscribe((list) => this.setList(list));
  }

  public addDictionary(dictionary: IDictionary) {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        loadedList: {
          ...state.loadedList,
          items: [dictionary, ...state.loadedList.items],
        },
        dictionaryId: dictionary.id,
        viewState: DictionariesViewStates.View,
      }),
    );
  }

  public setDictionary(dictionary: IDictionary) {
    this.setState((state): IDictionariesState => {
      const index = state.loadedList.items.findIndex(
        (item) => item.id === dictionary.id,
      );

      if (index === -1) {
        return state;
      }

      state.loadedList.items[index] = dictionary;
      return {
        ...state,
        loadedList: {
          ...state.loadedList,
          items: [...state.loadedList.items],
        },
        dictionaryId: dictionary.id,
        viewState: DictionariesViewStates.View,
      };
    });
  }

  public deleteFromList(dictionaryId: string) {
    this.setState((state): IDictionariesState => {
      return {
        ...state,
        loadedList: {
          ...state.loadedList,
          items: state.loadedList.items.filter(
            (item) => item.id !== dictionaryId,
          ),
        },
        dictionaryId:
          dictionaryId === state.dictionaryId ? null : state.dictionaryId,
        viewState:
          dictionaryId === state.dictionaryId
            ? DictionariesViewStates.List
            : state.viewState,
      };
    });
  }

  public viewDictionary(dictionaryId: string) {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        viewState: DictionariesViewStates.View,
        dictionaryId,
      }),
    );
  }

  public editDictionary(dictionaryId: string) {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        viewState: DictionariesViewStates.Edit,
        dictionaryId,
      }),
    );
  }

  public createDictionary() {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        viewState: DictionariesViewStates.Create,
        dictionaryId: null,
      }),
    );
  }

  public viewListOnly() {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        viewState: DictionariesViewStates.List,
        dictionaryId: null,
      }),
    );
  }

  public filterList(filter: IDictionaryFilter) {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        filter,
      }),
    );
  }

  private setList(list: ICollection<IDictionary>) {
    this.setState(
      (state): IDictionariesState => ({
        ...state,
        loadedList: list,
        loading: false,
      }),
    );
  }
}
