import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
  fetchLinkTypes,
  fetchLinkTypesFail,
  fetchLinkTypesSuccess,
} from '~/contact/store/link-type/link-type.action';
import { map, switchMap } from 'rxjs/operators';
import { catchError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LinkTypeHttpService } from '~/contact/services/http';

@Injectable()
export class LinkTypeEffects implements OnInitEffects {
  public fetchLinkTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLinkTypes),
      switchMap(() =>
        this.service.getAll().pipe(
          map((linkTypes) => fetchLinkTypesSuccess({ items: linkTypes.items })),
          catchError((error) => of(fetchLinkTypesFail({ error }))),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly service: LinkTypeHttpService,
  ) {}

  public ngrxOnInitEffects() {
    return fetchLinkTypes();
  }
}
