@if (cs.loaded$ | async) {
  @if (slim) {
    @if (cs.contact$ | async; as contact) {
      <div class="flex justify-content-between align-items-center pb-2">
        <h4 class="card-title text-xl m-0">
          {{ cs.contact$ | async | contactName }}
        </h4>
        <a
          pButton
          pRipple
          icon="pi pi-link"
          class="p-button-text p-button-rounded p-button-primary"
          [routerLink]="cs.link$ | async"
        ></a>
      </div>
    }
  } @else {
    @if (cs.contact$ | async; as contact) {
      <div class="card">
        <div
          class="card-header flex justify-content-between align-items-center pb-2 border-bottom-1 border-gray-300"
        >
          <h2 class="card-title text-2xl m-0">
            {{ contact | contactName }}
          </h2>
          <a
            pButton
            pRipple
            icon="pi pi-link"
            class="p-button-text p-button-rounded p-button-primary"
            [routerLink]="cs.link$ | async"
          ></a>
        </div>
        <div class="card-body">
          <p-tabView>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex gap-1">
                  <i class="pi pi-phone"></i>
                  <span>{{ "contact.label.phones" | translate }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <app-phones-list
                  [contactId]="contact?.id"
                  [allowEdit]="false"
                ></app-phones-list>
              </ng-template>
            </p-tabPanel>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex gap-1">
                  <i class="pi pi-envelope"></i>
                  <span>{{ "contact.label.emails" | translate }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <app-emails-list
                  [contactId]="contact?.id"
                  [edit]="false"
                ></app-emails-list>
              </ng-template>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    }
  }
}
