import { IHooked } from '~/hook/models';
import { ContactLinksListComponent } from './contact-links-list';

export const contactLinkWidget: IHooked<ContactLinksListComponent> = {
  hookName: 'contact-small-extensions',
  widgetName: 'contact.header.contactLinks',
  widgetId: 'contact-links-list',
  import: () =>
    import('~/contact/modules/link/standalone/contact-links-list').then(
      (m) => m.ContactLinksListComponent,
    ),
};
