import { IHooked } from '~/hook/models';
import { ContactActivitiesComponent } from './contact-activities';

export const contactTaskWidget: IHooked<ContactActivitiesComponent> = {
  hookName: 'contact-large-extensions',
  widgetName: 'activity.header.contactActivities',
  widgetId: 'contact-tasks-list',
  import: () =>
    import(
      'src/app/activity/standalone/contact-widget/contact-activities'
    ).then((m) => m.ContactActivitiesComponent),
};
