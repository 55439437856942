import { NgModule } from '@angular/core';
import { RowEditButtonComponent } from './row-edit-button.component';
import { SharedExtModule } from '~/shared-ext.module';

@NgModule({
  declarations: [RowEditButtonComponent],
  imports: [SharedExtModule],
  exports: [RowEditButtonComponent],
})
export class RowEditButtonModule {}
