<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="submitForm()"
  class="flex flex-column gap-3 md:flex-row md:align-items-center p-2 border-bottom-1 surface-border"
>
  <span class="flex-grow-1"
    ><a class="text-700 font-semibold" href="mailto:{{ item?.address }}">{{
      item?.address
    }}</a></span
  >
  <input
    pInputText
    type="text"
    formControlName="note"
    [placeholder]="'contact.label.note' | translate"
    class="w-3"
  />
  <div class="flex-grow-0 flex align-items-end gap-2">
    <app-row-confirm-button type="submit"></app-row-confirm-button>
    <app-row-cancel-button (click)="cancel.emit()"></app-row-cancel-button>
  </div>
</form>
