@if ((cs.activity$ | async)?.parent; as activity) {
  <div class="card">
    <div class="card-header">
      <div class="flex justify-content-between align-items-center gap-3">
        <div class="card-title">
          <span class="text-sm"
            >{{ "activity.label.parent" | translate }}:</span
          >
          <h3 class="text-2xl mt-1 mb-2">{{ activity.title }}</h3>
        </div>
        <div class="flex align-items-end gap-2">
          <a
            pButton
            pRipple
            icon="pi pi-link"
            class="p-button-text p-button-rounded p-button-primary"
            [routerLink]="['/activities', activity.id]"
          ></a>
        </div>
      </div>
      <div class="flex align-items-center gap-2">
        <app-activity-type-badge
          [type]="activity.type"
        ></app-activity-type-badge>
        <p-badge [value]="activity.status.name"></p-badge>
      </div>
    </div>
  </div>
}
