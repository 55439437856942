import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICollection, IPagination } from '~/@core/models';

import { SubscriptionType } from '../../models';
import { getAllItemsFactory } from '~/@core/utils';

export type ISubscriptionTypeData = Omit<SubscriptionType, 'id'>;

@Injectable({
  providedIn: 'root',
})
export class SubscriptionTypeHttpService {
  private readonly getAllItems = getAllItemsFactory<SubscriptionType>(
    this.getList.bind(this),
  );

  constructor(private readonly http: HttpClient) {}

  public create(subscriptionTypeData: ISubscriptionTypeData) {
    return this.http.post<SubscriptionType>(
      '/api/subscription-types',
      subscriptionTypeData,
    );
  }

  public getList(pagination?: IPagination) {
    const paginationQuery = pagination || {
      page: 1,
      perPage: 10,
    };
    return this.http.get<ICollection<SubscriptionType>>(
      '/api/subscription-types',
      {
        params: {
          ...paginationQuery,
        },
      },
    );
  }

  public getAll() {
    return this.getAllItems();
  }

  public update(id: string, linkType: ISubscriptionTypeData) {
    return this.http.put<SubscriptionType>(
      `/api/subscription-types/${id}`,
      linkType,
    );
  }

  public delete(id: string) {
    return this.http.delete<void>(`/api/subscription-types/${id}`);
  }
}
