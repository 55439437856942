<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid formgrid p-fluid mt-4">
    <div class="field col-6">
      <label for="start" class="text-900 font-semibold">{{
        "subscription.label.start" | translate
      }}</label>
      <p-calendar
        id="start"
        formControlName="start"
        styleClass="w-full"
        appendTo="body"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="field col-6">
      <label for="end" class="text-900 font-semibold">{{
        "subscription.label.end" | translate
      }}</label>
      <p-calendar
        id="end"
        formControlName="end"
        styleClass="w-full"
        appendTo="body"
        dateFormat="yy-mm-dd"
      ></p-calendar>
    </div>
    <div class="field col-12">
      <label for="note" class="text-900 font-semibold">{{
        "subscription.label.note" | translate
      }}</label>
      <app-editor
        id="note"
        formControlName="note"
        [style]="{ height: '150px' }"
      ></app-editor>
    </div>
    <div *ngFor="let dictionary of dictionaries" class="col-12 field">
      <label class="text-900 font-semibold">{{ dictionary.name }}</label>
      <app-dictionary-item-select
        [dictionary]="dictionary.dictionary"
        [multiple]="dictionary.multiple"
        [formControlName]="dictionaryFormKey(dictionary.id)"
      ></app-dictionary-item-select>
    </div>
  </div>
  <div class="flex justify-content-end gap-2">
    <app-cancel-button (click)="close()"></app-cancel-button>
    <app-save-button [disabled]="form.invalid"></app-save-button>
  </div>
</form>
