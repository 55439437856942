import { createAction, props } from '@ngrx/store';
import { IDictionaryItem } from '../../models';

export const addDictionaryItem = createAction(
  '[Dictionaries Items] Add item',
  props<{ dictionaryId: string; item: IDictionaryItem }>(),
);
export const setDictionaryItem = createAction(
  '[Dictionaries Items] Set item',
  props<{ dictionaryId: string; item: IDictionaryItem }>(),
);
export const removeDictionaryItem = createAction(
  '[Dictionaries Items] Remove item',
  props<{ dictionaryId: string; itemId: string }>(),
);
export const refreshDictionaryItems = createAction(
  '[Dictionaries Items] Fetch all if not loading',
  props<{ dictionaryId: string }>(),
);
export const fetchAllDictionaryItems = createAction(
  '[Dictionaries Items] Fetch all',
  props<{ dictionaryId: string }>(),
);
export const fetchAllDictionaryItemsSuccess = createAction(
  '[Dictionaries Items] Fetch all success',
  props<{ dictionaryId: string; list: IDictionaryItem[] }>(),
);
export const fetchAllDictionaryItemsFail = createAction(
  '[Dictionaries Items] Fetch all fail',
  props<{ dictionaryId: string }>(),
);
