import {
  ContactDictionaryItemsState,
  contactDictionaryReducer,
} from './contact-dictionary/contact-dictionary.reducer';
import { ActionReducerMap } from '@ngrx/store';
import {
  configurationReducer,
  ContactConfigurationState,
} from './configuration/configuration.reducer';
import { linkTypeReducer, LinkTypeState } from './link-type/link-type.reducer';

export interface ContactState {
  contactDictionaries: ContactDictionaryItemsState;
  configuration: ContactConfigurationState;
  linkType: LinkTypeState;
}

export const contactReducer: ActionReducerMap<ContactState> = {
  contactDictionaries: contactDictionaryReducer,
  configuration: configurationReducer,
  linkType: linkTypeReducer,
};

export const contactFeatureKey = 'contact';
