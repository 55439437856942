<p-dropdown
  [options]="(cs.users$ | async) || []"
  [placeholder]="placeholder"
  [showClear]="showClear"
  optionLabel="name"
  class="w-100"
>
  <ng-template let-user pTemplate="item">
    <div class="flex justify-content-between w-full">
      <div [ngClass]="{ 'text-500': user.status === 'inactive' }">
        {{ user.name }}
      </div>
      <div class="text-500">{{ user.email }}</div>
    </div>
  </ng-template>
</p-dropdown>
