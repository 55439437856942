@if (cs.loaded$ | async) {
  <div class="grid">
    @if (cs.contact$ | async; as contact) {
      <div class="col-12 md:col-6">
        <div class="flex justify-content-between align-items-start gap-3 mb-4">
          <h1 class="m-0">
            @if (contact.identifier) {
              <div class="text-xl text-700">
                {{ contact.identifier }}
              </div>
            }
            <div class="text-3xl">{{ contact | contactName }}</div>
            @if (contact.longName && contact.longName !== contact.shortName) {
              <div class="text-sm text-500">
                {{ contact.longName }}
              </div>
            }
          </h1>
          <div class="flex align-items-end gap-2">
            <app-card-edit-button (edit)="cs.showUpdateDialog()" />
            <app-card-delete-button
              (delete)="cs.deleteContact()"
              [disabled]="(canDelete$ | async) === false"
            />
          </div>
        </div>

        @if (contact.note) {
          <div class="card">
            <div class="card-body">
              <div [innerHTML]="contact.note"></div>
            </div>
          </div>
        }

        <div class="card mt-4">
          <div class="card-body">
            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="flex gap-1">
                    <i class="pi pi-phone"></i>
                    <span>{{ "contact.label.phones" | translate }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-phones-list [contactId]="contact?.id"></app-phones-list>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="flex gap-1">
                    <i class="pi pi-envelope"></i>
                    <span>{{ "contact.label.emails" | translate }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-emails-list [contactId]="contact?.id"></app-emails-list>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="flex gap-1">
                    <i class="pi pi-info-circle"></i>
                    <span>{{ "contact.label.info" | translate }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-contact-info [contact]="contact">
                    <app-info-row
                      [label]="'contact.label.shortName' | translate"
                      [value]="contact.shortName"
                    ></app-info-row>
                    <app-info-row
                      [label]="'contact.label.longName' | translate"
                      [value]="contact.longName"
                    ></app-info-row>
                  </app-contact-info>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="flex gap-1">
                    <i class="pi pi-map-marker"></i>
                    <span>{{ "contact.label.addresses" | translate }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <app-address-list
                    [contactId]="contact?.id"
                  ></app-address-list>
                </ng-template>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card">
          <div class="card-body">
            <app-contact-widgets
              [contact]="contact"
              hookName="contact-small-extensions"
            ></app-contact-widgets>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <app-contact-widgets
              [contact]="contact"
              hookName="contact-large-extensions"
            ></app-contact-widgets>
          </div>
        </div>
      </div>
    }
  </div>
}
