import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  protected message?: string;
  protected sessionId?: string;
  protected error?: string;

  constructor(
    private readonly ref: DynamicDialogRef,
    readonly config: DynamicDialogConfig,
  ) {
    this.message = config.data.message;
    this.sessionId = config.data.sessionId;
    this.error = config.data.error;
  }

  public close() {
    this.ref.close();
  }
}
