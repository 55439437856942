import { NgModule } from '@angular/core';

import { SharedModule } from '~/shared.module';
import { UserDropdownComponent } from '~/user/@feature/components/user-dropdown/user-dropdown.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UsersGroupComponent } from '~/user/@feature/components/users-group/users-group.component';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserAvatarComponent } from '~/user/@feature/components/user-avatar';
import { SharedExtModule } from '~/shared-ext.module';
import { MultipleUserDropdownComponent } from '~/user/@feature/components/multiple-user-dropdown';

@NgModule({
  imports: [
    SharedModule,
    SharedExtModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    RouterModule,
    MultiSelectModule,
  ],
  declarations: [
    UserAvatarComponent,
    UserDropdownComponent,
    UsersGroupComponent,
    MultipleUserDropdownComponent,
  ],
  exports: [
    UserAvatarComponent,
    UserDropdownComponent,
    UsersGroupComponent,
    MultipleUserDropdownComponent,
  ],
})
export class UserFeatureModule {}
