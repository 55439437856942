import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { SubscriptionsStore } from './subscriptions.store';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { distinctUntilChanged, of, Subscription } from 'rxjs';
import { ISubscriptionFilter } from '~/subscription/services/http';
import { apiEndOfTheDay, apiStartOfTheDay } from '~/@core/utils/date-time';
import {
  ISubscription,
  SubscriptionDictionary,
  SubscriptionType,
} from '~/subscription/models';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  providers: [provideComponentStore(SubscriptionsStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsComponent implements OnDestroy {
  @ViewChild('dt')
  protected dt!: Table;

  protected filterForm = new FormGroup<{ [key: string]: AbstractControl }>({
    type: new FormControl<SubscriptionType[] | undefined>([]),
    startFrom: new FormControl<Date | undefined>(undefined),
    startTo: new FormControl<Date | undefined>(undefined),
    endFrom: new FormControl<Date | undefined>(undefined),
    endTo: new FormControl<Date | undefined>(undefined),
  });

  protected dictionaries: SubscriptionDictionary[] = [];

  private subs = new Subscription();

  constructor(
    protected readonly cs: SubscriptionsStore,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.createFilterSub();
    this.createDictionaryFieldsSub();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: this.mapFilter(event?.globalFilter as string),
    });
  }

  protected toggleOptions(event: Event, opt: HTMLElement, date: HTMLElement) {
    if (event.type === 'mouseenter') {
      opt.style.display = 'flex';
      date.style.display = 'none';
    } else {
      opt.style.display = 'none';
      date.style.display = 'flex';
    }
  }

  protected dictionaryFormKey(dictionaryId: string) {
    return `dictionaryValues-${dictionaryId}`;
  }

  protected noExpandData(row: ISubscription) {
    return row.dictionaryValues.length === 0 && !row.note;
  }

  private createFilterSub() {
    const sub = this.filterForm.valueChanges
      .pipe(
        distinctUntilChanged((cur, prev) => {
          return JSON.stringify(cur) === JSON.stringify(prev);
        }),
      )
      .subscribe((value) => {
        this.dt.filter(value, 'filter', 'equals');
      });
    this.subs.add(sub);
  }

  private createDictionaryFieldsSub() {
    const dictionariesSub = this.filterForm
      .get('type')
      ?.valueChanges.pipe(
        switchMap((types) => {
          if (!types) return of([]);
          return this.cs.dictionaries$(
            types.map((t: SubscriptionType) => t.id),
          );
        }),
        distinctUntilChanged(),
      )
      .subscribe((dictionaries) => {
        const controls = Object.keys(this.filterForm.controls).filter((key) =>
          key.startsWith('dictionaryValues-'),
        );
        controls.forEach((key) => {
          this.filterForm.removeControl(key, { emitEvent: false });
        });

        dictionaries.forEach((dictionary) => {
          this.filterForm.addControl(
            this.dictionaryFormKey(dictionary.id),
            new FormControl<string[]>([]),
          );
        });
        this.dictionaries = dictionaries;
        this.filterForm.updateValueAndValidity();
        this.cdRef.markForCheck();
      });
    this.subs.add(dictionariesSub);
  }

  private mapFilter(global?: string): ISubscriptionFilter {
    const startFrom = this.filterForm.get('startFrom')?.value;
    const startTo = this.filterForm.get('startTo')?.value;
    const endFrom = this.filterForm.get('endFrom')?.value;
    const endTo = this.filterForm.get('endTo')?.value;

    const dictionaryValues = this.dictionaries
      .map((dictionary) => {
        return {
          dictionaryId: dictionary.id,
          valueId: this.filterForm.get(this.dictionaryFormKey(dictionary.id))
            ?.value[0],
        };
      })
      .filter((value) => value.valueId);

    return {
      search: global || undefined,
      typeIds:
        this.filterForm
          .get('type')
          ?.value?.map((t: SubscriptionType) => t.id) || undefined,
      startFrom: startFrom ? apiStartOfTheDay(startFrom) : undefined,
      startTo: startTo ? apiEndOfTheDay(startTo) : undefined,
      endFrom: endFrom ? apiStartOfTheDay(endFrom) : undefined,
      endTo: endTo ? apiEndOfTheDay(endTo) : undefined,
      dictionaries: dictionaryValues,
    };
  }
}
