import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { ActivitiesStore } from '~/activity/pages/activities/activities.store';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ActivityFilter } from '~/activity/services/http';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tasks',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  providers: [provideComponentStore(ActivitiesStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesComponent implements OnDestroy {
  @ViewChild('dt', { static: true })
  protected dt!: Table;

  private subs = new Subscription();

  protected commonTableFilter$: Observable<{ [key: string]: FilterMetadata }> =
    this.route.queryParams.pipe(
      map((params): { [key: string]: FilterMetadata } => {
        const res: { [key: string]: FilterMetadata } = {};

        const parentId = params['parent'];
        if (parentId) {
          res['parentId'] = { value: parentId } as FilterMetadata;
        }

        const contactId = params['contact'];
        if (contactId) {
          res['contactId'] = { value: contactId } as FilterMetadata;
        }

        return res;
      }),
    );

  protected contactId$ = this.route.queryParams.pipe(
    map((params) => params['contact']),
  );

  constructor(
    protected readonly cs: ActivitiesStore,
    readonly route: ActivatedRoute,
  ) {
    this.subs.add(
      this.route.queryParams.subscribe((params) => {
        params['parent'] && this.cs.loadParent(params['parent']);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    const filter = event.filters?.['filter']
      ? ((event.filters?.['filter'] as FilterMetadata).value as ActivityFilter)
      : {};
    const commonFilters = this.getCommonFilters(event);

    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        search: event?.globalFilter
          ? (event?.globalFilter as string)
          : undefined,
        ...filter,
        ...commonFilters,
      },
    });
  }

  protected updateFilter(filter: ActivityFilter) {
    this.dt.filter(filter, 'filter', 'equals');
  }

  private getCommonFilters(event: TableLazyLoadEvent) {
    const res: { parentId?: string; contactId?: string } = {};

    const parentFilter = event.filters?.['parentId'];
    if (parentFilter) {
      res.parentId = (parentFilter as FilterMetadata).value as string;
    }

    const contactFilter = event.filters?.['contactId'];
    if (contactFilter) {
      res.contactId = (contactFilter as FilterMetadata).value as string;
    }

    return res;
  }
}
