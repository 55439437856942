import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-card-edit-button',
  template: `
    <button
      pButton
      pRipple
      icon="pi pi-pencil"
      class="p-button-text p-button-rounded p-button-success"
      (click)="!disabled && edit.emit()"
      [disabled]="disabled"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEditButtonComponent {
  @Input() public disabled = false;

  @Output() public edit = new EventEmitter<void>();
}
