import { Component, ViewChild } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { PersonsListStore } from './persons-list.store';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { ContactFilter } from '~/contact/models';

@Component({
  selector: 'app-persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.scss'],
  providers: [provideComponentStore(PersonsListStore)],
})
export class PersonsListComponent {
  @ViewChild('dt', { static: false })
  protected dt!: Table;

  constructor(public readonly cs: PersonsListStore) {}

  public load(event: TableLazyLoadEvent) {
    const perPage = event.rows as number;
    const filter = event.filters?.['filter']
      ? ((event.filters?.['filter'] as FilterMetadata).value as ContactFilter)
      : {};

    this.cs.load({
      pagination: {
        page: (event.first as number) / perPage + 1,
        perPage: event.rows as number,
      },
      filter: {
        search: event?.globalFilter
          ? (event?.globalFilter as string)
          : undefined,
        ...filter,
      },
    });
  }

  protected updateFilter(filter: ContactFilter) {
    this.dt.filter(filter, 'filter', 'equals');
  }
}
