<div class="card grid">
  <div class="col-12 xl:col-3">
    <h1 class="text-700 text-3xl mb-2 pb-2 border-bottom-1 border-gray-400">
      {{ "contact.header.companies" | translate }}
    </h1>
    <h3 class="text-700 text-xl mb-2">
      {{ "common.header.filter" | translate }}
    </h3>
    <div class="border-dashed border-1 border-gray-400 p-3">
      <app-companies-filter (filter)="updateFilter($event)" />
    </div>
  </div>
  <div class="col-12 xl:col-9">
    <p-table
      #dt
      *ngIf="cs.loadedList$ | async as companiesList"
      [value]="companiesList.items"
      [totalRecords]="companiesList.total"
      [lazy]="true"
      [paginator]="true"
      [rows]="10"
      [filterDelay]="500"
      [showCurrentPageReport]="true"
      responsiveLayout="scroll"
      [currentPageReportTemplate]="'common.text.pagination' | translate"
      [rowsPerPageOptions]="[10, 25, 50]"
      (onLazyLoad)="load($event)"
    >
      <ng-template pTemplate="caption">
        <app-table-header>
          <ng-container filter>
            <app-table-filter
              (filter)="dt.filterGlobal($event, 'equals')"
            ></app-table-filter>
          </ng-container>
          <ng-container actions>
            <div class="flex gap-2">
              <app-new-item-button
                (click)="showCreateDialog()"
              ></app-new-item-button>
              <app-import-button [routerLink]="['import']"></app-import-button>
            </div>
          </ng-container>
        </app-table-header>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="white-space-nowrap">
            {{ "contact.label.identifier" | translate }}
          </th>
          <th class="white-space-nowrap">
            {{ "contact.label.companyName" | translate }}
          </th>
          <th class="white-space-nowrap"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-contact>
        <tr
          [routerLink]="['/contacts/companies', contact.id]"
          class="cursor-pointer hover:bg-black-alpha-10"
        >
          <td>{{ contact?.identifier }}</td>
          <td>{{ contact.name }}</td>
          <td class="flex justify-content-end gap-1">
            <app-row-view-button [link]="['/contacts/companies', contact.id]" />
            <app-row-delete-button
              (delete)="cs.deleteItem(contact.id)"
              [disabled]="(canDelete$ | async) === false"
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
