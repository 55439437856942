import { Component, OnDestroy, OnInit } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { ItemsListStore } from './items-list.store';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserHasRole } from '~/auth/store/auth.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dictionary-items-list',
  templateUrl: './items-list.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(ItemsListStore)],
})
export class ItemsListComponent implements OnInit, OnDestroy {
  protected uniqueValueValidator$: AsyncValidatorFn = (
    control: AbstractControl,
  ) => {
    return this.cs.items$.pipe(
      take(1),
      map((items) => {
        if (items?.some((item) => item.value === control.value)) {
          return { uniqueValue: true };
        }

        return null;
      }),
    );
  };

  protected form = new FormGroup(
    {
      value: new FormControl<string>(
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
        [this.uniqueValueValidator$],
      ),
    },
    {
      updateOn: 'change',
    },
  );

  protected canManageItems$ = this.store.select(
    selectUserHasRole(['admin', 'manager']),
  );

  private subs = new Subscription();

  constructor(
    protected cs: ItemsListStore,
    protected store: Store,
  ) {}

  public ngOnInit() {
    this.subs.add(
      this.cs.items$.subscribe((items) => {
        if (items) {
          this.form.reset();
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public search(event: Event) {
    const term = (event.target as HTMLInputElement).value;
    this.cs.filterList(term);
  }

  public createItem() {
    if (this.form.invalid) {
      return;
    }

    this.cs.createItem({
      value: this.form.value.value as string,
    });
  }
}
